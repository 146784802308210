import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";

import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";

import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

import Autocomplete from "@material-ui/lab/Autocomplete";

import NumberField from "../../../../components/NumberField";
import { fn } from "../../../../lib/fn";
import { getCurrencySymbol } from "../../../../lib/currency";
import useStyles from "./style-edit";

function EditSeparator({ item, onSave, onCancel }) {
  const [txtSeparator, setTxtSeparator] = useState((item && item.txt) || "");

  const dispatch = useDispatch();
  const classes = useStyles();

  const onSaveClicked = () => {
    onSave({
      ...item,
      txt: txtSeparator,
    });
  };

  return (
    <>
      <TableCell width="5%">
        <DragIndicatorIcon />
      </TableCell>
      <TableCell width="5%" align="right">
        <IconButton color="primary" onClick={onSaveClicked}>
          <CheckIcon fontSize="small" />
        </IconButton>
        <IconButton color="secondary" onClick={onCancel}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </TableCell>
      <TableCell width="85%" colSpan={5}>
        <TextField
          placeholder="Separator Text"
          value={txtSeparator}
          onChange={(e) => {
            setTxtSeparator(e.target.value);
          }}
          rows={2}
          multiline
          fullWidth
          required
        />
      </TableCell>
    </>
  );
}

export default EditSeparator;
