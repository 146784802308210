import React, { useEffect } from "react";
import update from "immutability-helper";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import useStyles from "./styles";

function UserSelector({
  required,
  label,
  placeholder,
  disabled,
  multiple,
  value,
  onValueChanged,
}) {
  const classes = useStyles();
  const loading = useSelector((state) => state.users.list.loading);
  const lastFetch = useSelector((state) => state.users.list.lastFetch);
  const users = useSelector((state) => state.users.list.users);

  const dispatch = useDispatch();

  useEffect(() => {
    if (Date.now() - lastFetch > 5 * 60 * 1000) dispatch({ type: "GET_USERS" });
  }, []);

  return (
    <Autocomplete
      multiple={multiple}
      value={value}
      disabled={disabled}
      classes={classes}
      loading={loading}
      options={users}
      onChange={(_, value) => onValueChanged(value)}
      getOptionSelected={(option, val) => option.id === val.id}
      getOptionLabel={(option) => `${option.name} (${option.email})`}
      renderOption={(option) => `${option.name} (${option.email})`}
      renderInput={(params) => (
        <TextField
          required={required}
          label={label}
          placeholder={placeholder}
          {...update(params, {
            InputLabelProps: {
              shrink: { $set: true },
            },
          })}
        />
      )}
    />
  );
}

export default UserSelector;
