import React from "react";
import { useSelector } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";

import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

import { getCurrencySymbol } from "../../../lib/currency";
import QuoteMeta from "./QuoteMeta";
import QuoteHeader from "./QuoteHeader";
import useStyles from "./style-content";

function QuoteView({ id }) {
  const classes = useStyles();
  const sellingCurrency = useSelector(
    (state) => state.settings.currency.selling
  );
  const taxRate = useSelector((state) => state.settings.currency.taxRate);
  const items = useSelector((state) => state.quotes.editor.items);
  const subtotal = useSelector((state) => state.quotes.editor.summary.subtotal);
  const discount = useSelector((state) => state.quotes.editor.details.discount);

  const discountAmount =
    Number.parseFloat(discount) !== Number.NaN
      ? Number.parseFloat(discount)
      : 0;
  const afterDiscount = subtotal - discountAmount;
  const tax = afterDiscount * (taxRate / 100);
  const total = afterDiscount + tax;

  const currency = sellingCurrency; //getCurrencySymbol(sellingCurrency);

  return (
    <OverlayScrollbarsComponent className={classes.main}>
      <Paper className={classes.paper}>
        <QuoteHeader id={id} />
        <TableContainer>
          <Table className={classes.table} aria-label="spanning table">
            <TableHead>
              <TableRow>
                <TableCell width="55%">Products</TableCell>
                <TableCell width="15%" align="right">
                  Qty.
                </TableCell>
                <TableCell width="15%" align="right">
                  Unit
                </TableCell>
                <TableCell width="15%" align="right">
                  Sum
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) =>
                item.sku ? (
                  <TableRow key={item.ref}>
                    <TableCell width="55%">
                      <Typography variant="overline" display="block">
                        {item.sku}
                      </Typography>
                      <Typography variant="body1" display="block">
                        {item.name}
                      </Typography>
                    </TableCell>
                    <TableCell width="15%" align="right">
                      {item.qty.toLocaleString()}
                    </TableCell>
                    <TableCell width="15%" align="right">
                      {currency} {item.unit.toLocaleString()}
                    </TableCell>
                    <TableCell width="10%" align="right">
                      {currency} {(item.qty * item.unit).toLocaleString()}
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow key={item.ref}>
                    <TableCell
                      width="95%"
                      colSpan={4}
                      className={classes.separator}
                    >
                      <em>{item.txt}</em>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell colSpan={1} rowSpan={4} />
                <TableCell colSpan={2}>Subtotal</TableCell>
                <TableCell align="right">
                  {currency} {subtotal.toLocaleString()}
                </TableCell>
              </TableRow>
              {discountAmount !== 0 && (
                <TableRow>
                  <TableCell colSpan={2}>Discount</TableCell>
                  <TableCell align="right">
                    {currency} {discountAmount.toLocaleString()}
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell>Tax</TableCell>
                <TableCell align="right">{taxRate}%</TableCell>
                <TableCell align="right">
                  {currency} {tax.toLocaleString()}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>Total</TableCell>
                <TableCell align="right">
                  {currency} {total.toLocaleString()}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Paper className={classes.paper}>
        <QuoteMeta id={id} />
      </Paper>
      <div className={classes.spacer} />
    </OverlayScrollbarsComponent>
  );
}

export default QuoteView;
