import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  appBar: {
    borderTopLeftRadius: theme.spacing(),
    borderTopRightRadius: theme.spacing(),
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  toolbarTitle: {
    flex: "1",
  },
  loading: {
    overflow: "hidden",
    width: 24,
    height: 24,
  },
  shareDialog: {
    zIndex: "99999 !important",
    "&:first-child": {
      paddingTop: 0,
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));
