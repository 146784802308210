const update = require("immutability-helper");

const initialState = {
  loading: true,
  error: null,
  activeContact: null,
  changed: false,
  saving: false,

  email: null,
  phone: null,
  name: null,
  companyName: null,
  companyAddress: null,
};

function contacts(state = initialState, action) {
  switch (action.type) {
    case "NEW_CONTACT":
    case "UPDATE_CONTACT": {
      return update(state, {
        saving: {
          $set: true,
        },
        error: {
          $set: null,
        },
      });
    }
    case "NEW_CONTACT_SUCCESS":
    case "UPDATE_CONTACT_SUCCESS": {
      return update(state, {
        saving: {
          $set: false,
        },
        changed: {
          $set: false,
        },
      });
    }
    case "DELETE_CONTACT_ERROR":
    case "UPDATE_CONTACT_ERROR": {
      return update(state, {
        saving: {
          $set: false,
        },
        error: {
          $set: action.payload,
        },
      });
    }
    case "GET_CONTACT": {
      const { id } = action.payload;
      return update(state, {
        activeContact: {
          $set: id,
        },
        loading: {
          $set: true,
        },
        changed: {
          $set: false,
        },
      });
    }
    case "SET_CONTACT_DETAIL": {
      const { field, value, id } = action.payload;
      if (state.activeContact !== id) return state;

      return update(state, {
        changed: {
          $set: true,
        },
        error: {
          $set: null,
        },
        [field]: {
          $set: value,
        },
      });
    }
    case "GET_CONTACT_SUCCESS": {
      const {
        id,
        email,
        phone,
        name,
        company_name,
        company_address,
      } = action.payload;
      if (state.activeContact !== id) return state;

      return update(state, {
        loading: {
          $set: false,
        },
        email: {
          $set: email,
        },
        phone: {
          $set: phone,
        },
        name: {
          $set: name,
        },
        companyName: {
          $set: company_name,
        },
        companyAddress: {
          $set: company_address,
        },
      });
    }
    default:
      return state;
  }
}

export default contacts;
