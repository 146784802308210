import { put, takeEvery } from "redux-saga/effects";
import { fn } from "../../../lib/fn";

function* getContacts() {
  const contacts = yield fn("get-contacts");
  yield put({ type: "GET_CONTACTS_SUCCESS", payload: contacts });
}

function* contactListSaga() {
  yield takeEvery("GET_CONTACTS", getContacts);
}

export { contactListSaga };
