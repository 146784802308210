export default function getTitle(type) {
  switch (type) {
    case "message":
      return "Message Template";
    case "delivery":
      return "Delivery Template";
    case "payment":
      return "Payment Template";
  }
}
