const update = require("immutability-helper");

const initialState = {
  lastFetch: 0,
  loading: true,
  error: null,
  users: [],
  searchKeyword: "",
  searchResult: [],
};

function users(state = initialState, action) {
  switch (action.type) {
    case "SEARCH_USERS": {
      const { keyword } = action.payload;
      return update(state, {
        searchKeyword: {
          $set: keyword,
        },
        searchResult: {
          $set: state.users.filter((user) => {
            const keywordLowered = keyword.toLowerCase();
            const emailLowered = user.email.toLowerCase();
            const nameLowered = user.name.toLowerCase();
            return (
              emailLowered.includes(keywordLowered) ||
              nameLowered.includes(keywordLowered)
            );
          }),
        },
      });
    }
    case "GET_USERS":
      return update(state, {
        users: {
          $set: [],
        },
        loading: {
          $set: true,
        },
        error: {
          $set: null,
        },
      });
    case "GET_USERS_SUCCESS":
      return update(state, {
        lastFetch: {
          $set: Date.now(),
        },
        loading: {
          $set: false,
        },
        users: {
          $set: action.payload,
        },
      });
    default:
      return state;
  }
}

export default users;
