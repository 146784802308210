import { push } from "connected-react-router";
import { put, delay, takeEvery } from "redux-saga/effects";
import { fn } from "../../lib/fn";

function* handleResetPassword({ payload }) {
  try {
    const { auth, password } = payload;
    const { success } = yield fn("reset-password", { password }, { auth });
    if (!success)
      throw new Error(
        "Unknown error occured when resetting password. Please try again later."
      );
    yield put({
      type: "RESET_PASSWORD_SUCCESS",
      payload: "Success! Your password has been reset!",
    });
    yield delay(2500);
    yield put(push("/"));
    yield put({
      type: "CLEAR_RESET_PASSWORD",
    });
  } catch (e) {
    yield put({
      type: "RESET_PASSWORD_FAILED",
      payload:
        e.message ||
        "Unknown error occured when resetting password. Please try again later.",
    });
  }
}

function* handleRequestResetPassword({ payload }) {
  try {
    const { email } = payload;
    const { success } = yield fn("request-reset-password", { email });
    if (!success)
      throw new Error(
        "Unknown error occured when resetting password. Please try again later."
      );
    yield put({
      type: "REQUEST_RESET_PASSWORD_SUCCESS",
      payload: `An email has been sent to ${email}.`,
    });
  } catch (e) {
    yield put({
      type: "REQUEST_RESET_PASSWORD_FAILED",
      payload:
        e.message ||
        "Unknown error occured when resetting password. Please try again later.",
    });
  }
}

function* passwordSagas() {
  yield takeEvery("RESET_PASSWORD", handleResetPassword);
  yield takeEvery("REQUEST_RESET_PASSWORD", handleRequestResetPassword);
}

export { passwordSagas };
