import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import useStyle from "./styles";

const menus = [
  { id: "", title: "Quotes", adminOnly: false },
  { id: "products", title: "Products", adminOnly: false },
  { id: "contacts", title: "Contacts", adminOnly: false },
  { id: "users", title: "Users", adminOnly: true },
  { id: "settings", title: "Settings", adminOnly: false },
];

const menuIndexes = {
  p: 1,
  c: 2,
  u: 3,
  s: 4,
};

function Header() {
  const classes = useStyle();
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const companyName = useSelector((state) => state.auth.companyName);
  const level = useSelector((state) => state.auth.level);

  const availableMenus = useMemo(
    () => menus.filter((menu) => level === "ADM" || !menu.adminOnly),
    [level]
  );
  const menuIndex = useMemo(() => {
    for (let i = 0; i < availableMenus.length; i += 1) {
      const { id } = availableMenus[i];
      if (!id.length) continue;
      if (id[0] === pathname[1]) return i;
    }
    return 0;
  }, [availableMenus, pathname]);

  return (
    <div className={classes.container}>
      <AppBar
        component="div"
        className={`${classes.secondaryBar} ${classes.header}`}
        position="static"
        elevation={0}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h6">
                {companyName}
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip title="Log Out">
                <IconButton
                  color="inherit"
                  onClick={() => {
                    dispatch({ type: "LOGOUT" });
                  }}
                >
                  <ExitToAppIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component="div"
        className={classes.header}
        position="sticky"
        elevation={0}
      >
        <Tabs value={menuIndex} textColor="inherit">
          {availableMenus.map((menu) => (
            <Tab
              key={menu.id}
              textColor="inherit"
              label={menu.title}
              href={`/${menu.id}`}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/${menu.id}`);
              }}
            />
          ))}
        </Tabs>
      </AppBar>
    </div>
  );
}

export default Header;
