import React, { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import useStyle from "./style";

function SessionDialog() {
  const open = useSelector((state) => state.auth.sessionExpired);
  const email = useSelector((state) => state.auth.userEmail);
  const flashMessage = useSelector((state) => state.auth.flashMessage);
  const loading = useSelector((state) => state.auth.loading);
  const error = useSelector((state) => state.auth.error);
  const txtPassword = useRef(null);
  const classes = useStyle();
  const dispatch = useDispatch();

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      classes={classes}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Login</DialogTitle>
      <form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          return false;
        }}
      >
        <DialogContent className={classes.content}>
          {error && (
            <Alert severity="error" className={classes.alert}>
              {error}
            </Alert>
          )}
          {flashMessage && (
            <Alert severity="success" className={classes.alert}>
              We've extended your session, {flashMessage}!
            </Alert>
          )}
          {!error && !flashMessage && (
            <DialogContentText>
              Your session has expired. To continue, please enter your password.
            </DialogContentText>
          )}
          <TextField
            disabled
            variant="outlined"
            margin="normal"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            value={email}
          />
          <TextField
            inputRef={txtPassword}
            disabled={loading}
            autoFocus
            margin="normal"
            variant="outlined"
            id="name"
            label="Password"
            type="password"
            autoComplete="current-password"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            onClick={() => dispatch({ type: "LOGOUT" })}
          >
            Logout
          </Button>
          <Button
            type="submit"
            disabled={loading}
            onClick={() =>
              dispatch({
                type: "LOGIN",
                payload: {
                  email,
                  password: txtPassword.current.value,
                },
              })
            }
            color="primary"
          >
            Continue
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default SessionDialog;
