import React, { useState } from "react";
import { GooSpinner } from "react-spinners-kit";
import { useDispatch, useSelector } from "react-redux";
import { Beforeunload } from "react-beforeunload";
import { Prompt } from "react-router";
import Alert from "@material-ui/lab/Alert";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";

import SaveIcon from "@material-ui/icons/Save";
import PanoramaIcon from "@material-ui/icons/Panorama";

import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

import useInputRedux from "../../../../hooks/useInputRedux";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import ContentLoading from "../../../../components/ContentLoading";
import FileInput from "../../../../components/FileInput";
import useStyles from "./style";

function CompanyProfile() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(false);
  const [nameError, setNameError] = useState(null);

  const error = useSelector((state) => state.settings.company.error);
  const loading = useSelector((state) => state.settings.company.loading);
  const changed = useSelector((state) => state.settings.company.changed);
  const saving = useSelector((state) => state.settings.company.saving);

  const type = useSelector((state) => state.settings.company.type);
  const activeUntil = useSelector(
    (state) => state.settings.company.activeUntil
  );

  const logo = useSelector((state) => state.settings.company.logo);
  const [name, setName] = useInputRedux(
    ["settings", "company", "name"],
    (value) => ({
      type: "SET_SETTING_COMPANY_DETAIL",
      payload: { field: "name", value },
    })
  );
  const [phone, setPhone] = useInputRedux(
    ["settings", "company", "phone"],
    (value) => ({
      type: "SET_SETTING_COMPANY_DETAIL",
      payload: { field: "phone", value },
    })
  );
  const [address, setAddress] = useInputRedux(
    ["settings", "company", "address"],
    (value) => ({
      type: "SET_SETTING_COMPANY_DETAIL",
      payload: { field: "address", value },
    })
  );

  const validateAndSubmit = () => {
    let error = false;
    if (name.trim().length === 0) {
      setNameError("Name is required.");
      error = true;
    }
    if (error) return;
    dispatch({
      type: "UPDATE_SETTING_COMPANY",
    });
  };

  if (loading) return <ContentLoading />;

  return (
    <>
      <OverlayScrollbarsComponent className={classes.main}>
        <Paper className={classes.paper}>
          <AppBar
            component="div"
            className={classes.appBar}
            color="paper"
            position="static"
            elevation={0}
          >
            <Toolbar className={classes.toolbar}>
              <Typography
                variant="h5"
                id="tableTitle"
                component="div"
                className={classes.toolbarTitle}
              >
                {changed && (
                  <Badge badgeContent={"Unsaved"} color="error">
                    Company Profile
                  </Badge>
                )}
                {!changed && "Company Profile"}
              </Typography>
              {saving && (
                <div className={classes.loading}>
                  <GooSpinner size={24} color="#000" />
                </div>
              )}
              {!saving && (
                <IconButton
                  color="inherit"
                  disabled={!changed}
                  onClick={validateAndSubmit}
                >
                  <SaveIcon />
                </IconButton>
              )}
            </Toolbar>
          </AppBar>
          <Divider />
          <Grid container className={classes.content} spacing={2}>
            {error && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            <Grid item xs={12} sm={7} lg={8} container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  autoFocus
                  disabled={saving}
                  placeholder="Name"
                  label="Company Name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameError(null);
                  }}
                  error={!!nameError}
                  helperText={nameError}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled={saving}
                  placeholder="+1 123 123 1234"
                  label="Office Phone Number"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  fullWidth
                  rows={3}
                  rowsMax={3}
                  disabled={saving}
                  placeholder={"123 Some Example Rd\nCity Name, State\n12345"}
                  label="Company Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              spacing={1}
              xs={12}
              sm={5}
              lg={4}
              direction="column"
            >
              <Grid item>
                <Avatar variant="square" className={classes.picture} src={logo}>
                  <PanoramaIcon />
                </Avatar>
              </Grid>
              <Grid item>
                <FileInput
                  buttonText="Upload Picture"
                  buttonProps={{
                    fullWidth: true,
                    size: "small",
                    variant: "outlined",
                  }}
                  onSelected={(file) => {
                    dispatch({
                      type: "UPLOAD_SETTING_COMPANY_PICTURE",
                      payload: { image: file },
                    });
                  }}
                />
              </Grid>
              {logo && (
                <Grid item>
                  <Button
                    fullWidth
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={() => setDeleting(true)}
                  >
                    Remove Picture
                  </Button>
                </Grid>
              )}
              <Divider />
              <Grid item>
                <Chip
                  size="small"
                  label={type}
                  color={type === "PREMIUM" ? "primary" : "secondary"}
                />{" "}
                active until <strong>{activeUntil}</strong>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <div className={classes.spacer} />
        {changed && (
          <>
            <Beforeunload
              onBeforeunload={() =>
                "You have unsaved changes. Are you sure? Unsaved changes will lost."
              }
            />
            <Prompt message="You have unsaved changes. Are you sure? Unsaved changes will lost." />
          </>
        )}
      </OverlayScrollbarsComponent>
      <ConfirmDialog
        show={deleting}
        onClose={() => setDeleting(false)}
        onConfirm={() => {
          setDeleting(false);
          dispatch({
            type: "REMOVE_SETTING_COMPANY_PICTURE",
          });
        }}
        title="Deleting ..."
        message="Are you sure you want to remove your company logo? This action cannot be undone."
      />
    </>
  );
}

export default CompanyProfile;
