import Promise from "bluebird";
import { push } from "connected-react-router";
import { takeEvery, put, select } from "redux-saga/effects";
import { fn } from "../../../lib/fn";

function* handleGetCurrency() {
  const data = yield fn("get-currency");
  if (!data) {
    yield put(push("/settings"));
    return;
  }
  yield put({
    type: "GET_SETTING_CURRENCY_SUCCESS",
    payload: data,
  });
}

function* handleUpdateCurrency() {
  try {
    // give time to the debounced redux updater
    yield Promise.delay(1500);
    const editor = yield select((state) => state.settings.currency);
    const { success } = yield fn("update-currency", {
      inventory: editor.inventory,
      selling: editor.selling,
      rate: editor.rate,
      tax_rate: editor.taxRate,
    });

    if (!success) {
      throw new Error("Fail to update the database. Please try again later.");
    }
    yield put({
      type: "UPDATE_SETTING_CURRENCY_SUCCESS",
    });
  } catch (e) {
    yield put({
      type: "UPDATE_SETTING_CURRENCY_ERROR",
      payload: e.message || "Unknown Error",
    });
  }
}

function* currencySettingSaga() {
  yield takeEvery("GET_SETTING_CURRENCY", handleGetCurrency);
  yield takeEvery("UPDATE_SETTING_CURRENCY", handleUpdateCurrency);
}

export { currencySettingSaga };
