import { getImageURL } from "../../../lib/image";
const update = require("immutability-helper");

const initialState = {
  error: null,
  loading: false,
  saving: false,
  activeProduct: null,
  changed: false,

  sku: null,
  name: null,
  unit: null,
  externalName: null,
  costPrice: null,
  sellingPrice: null,
  image: null,
};

function products(state = initialState, action) {
  switch (action.type) {
    case "GET_PRODUCT": {
      const { sku } = action.payload;
      return update(state, {
        error: {
          $set: null,
        },
        loading: {
          $set: true,
        },
        changed: {
          $set: false,
        },
        activeProduct: {
          $set: sku,
        },
      });
    }
    case "SET_PRODUCT_DETAIL": {
      const { field, value, sku } = action.payload;
      if (state.activeProduct !== sku) return state;

      return update(state, {
        changed: {
          $set: true,
        },
        error: {
          $set: null,
        },
        [field]: {
          $set: value,
        },
      });
    }
    case "REMOVE_PRODUCT_PICTURE_SUCCESS": {
      const { sku } = action.payload;
      if (state.activeProduct !== sku) return state;
      return update(state, {
        image: {
          $set: null,
        },
      });
    }
    case "UPLOAD_PRODUCT_PICTURE_SUCCESS": {
      const { sku, image } = action.payload;
      if (state.activeProduct !== sku) return state;
      return update(state, {
        image: {
          $set: getImageURL(image),
        },
      });
    }
    case "GET_PRODUCT_SUCCESS": {
      const {
        sku,
        name,
        unit,
        external_name,
        cost_price,
        selling_price,
        image,
      } = action.payload;
      if (state.activeProduct !== sku) return state;
      return update(state, {
        loading: {
          $set: false,
        },
        sku: {
          $set: (sku !== "new" && sku) || "",
        },
        name: {
          $set: name,
        },
        unit: {
          $set: unit,
        },
        externalName: {
          $set: external_name,
        },
        costPrice: {
          $set: cost_price !== null ? `${cost_price}` : null,
        },
        sellingPrice: {
          $set: selling_price !== null ? `${selling_price}` : null,
        },
        image: {
          $set: image && getImageURL(image),
        },
      });
    }
    case "NEW_PRODUCT":
    case "UPDATE_PRODUCT": {
      return update(state, {
        error: {
          $set: null,
        },
        saving: {
          $set: true,
        },
      });
    }
    case "NEW_PRODUCT_SUCCESS":
    case "UPDATE_PRODUCT_SUCCESS": {
      return update(state, {
        changed: {
          $set: false,
        },
        saving: {
          $set: false,
        },
      });
    }
    case "NEW_PRODUCT_ERROR":
    case "UPDATE_PRODUCT_ERROR":
    case "DELETE_PRODUCT_ERROR":
    case "REMOVE_PRODUCT_PICTURE_ERROR":
    case "UPLOAD_PRODUCT_PICTURE_ERROR": {
      return update(state, {
        saving: {
          $set: false,
        },
        error: {
          $set: action.payload,
        },
      });
    }
    default:
      return state;
  }
}

export default products;
