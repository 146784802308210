import Promise from "bluebird";
import { push } from "connected-react-router";
import { takeEvery, take, put, select } from "redux-saga/effects";
import { fn, fnDownloadURL } from "../../../lib/fn";

function* handleGetQuoteACL({ payload }) {
  const { id } = payload;
  const data = yield fn("get-quote-acl", { id });
  yield put({
    type: "GET_QUOTE_ACL_SUCCESS",
    payload: { data, id },
  });
}

function* handleGetQuoteAudit({ payload }) {
  const { id } = payload;
  const data = yield fn("get-quote-audit", { id });
  yield put({
    type: "GET_QUOTE_AUDIT_SUCCESS",
    payload: { data, id },
  });
}

function* handleGetQuote({ payload }) {
  const { id } = payload;
  try {
    const data = yield fn("get-quote", { id });
    yield put({
      type: "GET_QUOTE_SUCCESS",
      payload: data,
    });
  } catch (e) {
    console.error(e);
    yield put({
      type: "FATAL_ERROR",
      payload: {
        message:
          (e.message &&
            `${e.message}. If this error persists, please try again later or contact support.`) ||
          "Unexpected error occured. If this error persists, please try again later or contact support.",
      },
    });
  }
}

function* handleSendQuote() {
  try {
    const id = yield select((state) => state.quotes.editor.activeQuote);
    const changed = yield select((state) => state.quotes.editor.changed);
    const recipients = yield select((state) => state.quotes.editor.recipients);
    const steps = [];

    if (changed) {
      steps.push({
        status: "WAITING",
        textProgress: "Saving quote ...",
        textWaiting: "Save the quote",
        textDone: "Changes are saved!",
      });
    }
    steps.push({
      status: "WAITING",
      textProgress: "Finalising the quotation ...",
      textWaiting: "Finalise the quotation",
      textDone: "Quotation is finalised!",
    });

    for (let recipient of recipients) {
      steps.push({
        status: "WAITING",
        textProgress: `Sending the quotation to ${recipient.email} ...`,
        textWaiting: `Send the quotation to ${recipient.email}`,
        textDone: `The quotation is sent to ${recipient.email}!`,
      });
    }
    yield put({ type: "UPDATE_SENDING_STEPS", payload: steps });

    let currentStep = 0;
    if (changed) {
      yield put({
        type: "UPDATE_SENDING_STEP_PROGRESS",
        payload: { idx: currentStep, status: "ACTIVE" },
      });

      yield put({ type: "UPDATE_QUOTE" });
      const { type } = yield take([
        "UPDATE_QUOTE_SUCCESS",
        "UPDATE_QUOTE_ERROR",
      ]);

      if (type === "UPDATE_QUOTE_ERROR") {
        yield put({ type: "CANCEL_SEND" });
        return;
      }
      yield put({
        type: "UPDATE_SENDING_STEP_PROGRESS",
        payload: { idx: currentStep, status: "DONE" },
      });
      currentStep += 1;
    }

    yield put({
      type: "UPDATE_SENDING_STEP_PROGRESS",
      payload: { idx: currentStep, status: "ACTIVE" },
    });

    const { success, actor } = yield fn("finalise-quote", { id });
    if (!success)
      throw new Error("Failed to finalise quote. Please try again later.");

    yield put({
      type: "UPDATE_SENDING_STEP_PROGRESS",
      payload: { idx: currentStep, status: "DONE" },
    });
    currentStep += 1;
    for (let recipient of recipients) {
      yield put({
        type: "UPDATE_SENDING_STEP_PROGRESS",
        payload: { idx: currentStep, status: "ACTIVE" },
      });
      const { success } = yield fn("send-quote-to", {
        id,
        email: recipient.email,
      });
      if (!success)
        throw new Error(`Error when sending quote to ${recipient.email}`);
      yield put({
        type: "UPDATE_SENDING_STEP_PROGRESS",
        payload: { idx: currentStep, status: "DONE" },
      });
      currentStep += 1;
      yield put({
        type: "SEND_QUOTE_SUCCESS",
      });
    }
  } catch (e) {
    console.error(e);
    yield put({
      type: "SEND_QUOTE_ERROR",
      payload: e.message || "Unknown Error",
    });
  }
}

function* handleShareQuote({ payload }) {
  const { quoteId, user } = payload;
  try {
    const { success } = yield fn("share-quote", { id: quoteId, user_id: user });
    if (!success)
      throw new Error("Failed to update database. Please try again later.");
    yield put({
      type: "GET_QUOTE_ACL",
      payload: { id: quoteId },
    });
    yield put({
      type: "SHARE_QUOTE_SUCCESS",
    });
  } catch (e) {
    console.error(e);
    yield put({
      type: "SHARE_QUOTE_ERROR",
      payload: e.message || "Unknown Error",
    });
  }
}

function* handleDownloadQuote({ payload }) {
  const changed = yield select((state) => state.quotes.editor.changed);

  if (changed) {
    yield put({ type: "UPDATE_QUOTE" });
    const { type } = yield take(["UPDATE_QUOTE_SUCCESS", "UPDATE_QUOTE_ERROR"]);

    if (type === "UPDATE_QUOTE_ERROR") return;
  }

  const { id } = payload;
  window.location = fnDownloadURL("download-quote-excel", { id });
}

function* handleDeleteQuote({ payload }) {
  const { id } = payload;
  yield put({
    type: "SHOW_LOADING",
    payload: { message: `Deleting ${id} ...` },
  });

  try {
    const { success } = yield fn("delete-quote", { id });
    if (!success) {
      throw new Error("Fail to update the database. Please try again later.");
    }
    yield put({
      type: "DELETE_QUOTE_SUCCESS",
      payload: { id },
    });
    yield put(push("/"));
  } catch (e) {
    yield put({
      type: "DELETE_QUOTE_ERROR",
      payload: e.message || "Unknown Error",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* handleNewQuote() {
  yield put({
    type: "SHOW_LOADING",
    payload: { message: "Creating a new quote ..." },
  });
  const { success, id } = yield fn("new-quote");
  if (!success) {
    yield put({
      type: "NEW_QUOTE_ERROR",
      payload: "Error when creating new quote. Please try again.",
    });
    yield put({
      type: "HIDE_LOADING",
    });
    return;
  }
  yield put({
    type: "LOAD_QUOTES",
    payload: { direction: "before" },
  });
  yield take("LOAD_QUOTES_SUCCESS");
  yield put(push(`/quote/${id}`));
  yield put({
    type: "HIDE_LOADING",
  });
}

function* handleCopyQuote({ payload }) {
  const { id, revise } = payload;
  const changed = yield select((state) => state.quotes.editor.changed);

  if (changed) {
    yield put({ type: "UPDATE_QUOTE" });
    const { type } = yield take(["UPDATE_QUOTE_SUCCESS", "UPDATE_QUOTE_ERROR"]);

    if (type === "UPDATE_QUOTE_ERROR") return;
  }

  yield put({
    type: "SHOW_LOADING",
    payload: { message: `Copying ${id} ...` },
  });
  const { success, id: newQuote } = yield fn("copy-quote", { id, revise });
  if (!success) {
    yield put({
      type: "COPY_QUOTE_ERROR",
      payload: "Error when creating new quote. Please try again.",
    });
    yield put({
      type: "HIDE_LOADING",
    });
    return;
  }
  yield put({
    type: "LOAD_QUOTES",
    payload: { direction: "before" },
  });
  yield take("LOAD_QUOTES_SUCCESS");
  yield put(push(`/quote/${newQuote}`));
  yield put({
    type: "HIDE_LOADING",
  });
}

function* handleUpdateQuote() {
  try {
    // give time to the debounced redux updater
    yield Promise.delay(1500);

    const editor = yield select((state) => state.quotes.editor);
    const { success, actor, title } = yield fn("update-quote", {
      id: editor.activeQuote,
      recipients: editor.recipients,
      items: editor.items.map((item) => ({
        sku: item.sku,
        name: item.name,
        unitUnit: item.unitUnit,
        txt: item.txt,
        qty: item.qty,
        unit: item.unit,
        cost: item.cost,
      })),
      title: (editor.details && editor.details.title) || "",
      discount: (editor.details && editor.details.discount) || "0.00",
      message: (editor.details && editor.details.message) || "",
      payment_details: (editor.details && editor.details.paymentDetails) || "",
      delivery_details:
        (editor.details && editor.details.deliveryDetails) || "",
    });
    if (success) {
      yield put({
        type: "UPDATE_QUOTE_SUCCESS",
        payload: { id: editor.activeQuote, actor, title },
      });
    } else {
      yield put({
        type: "UPDATE_QUOTE_ERROR",
        payload: "Fail to update the database. Please try again later.",
      });
    }
  } catch (e) {
    yield put({
      type: "UPDATE_QUOTE_ERROR",
      payload: e.message || "Unknown Error",
    });
  }
}

function* quoteEditorSaga() {
  yield takeEvery("GET_QUOTE", handleGetQuote);
  yield takeEvery("GET_QUOTE_ACL", handleGetQuoteACL);
  yield takeEvery("GET_QUOTE_AUDIT", handleGetQuoteAudit);
  yield takeEvery("SEND_QUOTE", handleSendQuote);
  yield takeEvery("COPY_QUOTE", handleCopyQuote);
  yield takeEvery("SHARE_QUOTE", handleShareQuote);
  yield takeEvery("DOWNLOAD_QUOTE", handleDownloadQuote);
  yield takeEvery("DELETE_QUOTE", handleDeleteQuote);
  yield takeEvery("UPDATE_QUOTE", handleUpdateQuote);
  yield takeEvery("NEW_QUOTE", handleNewQuote);
}

export { quoteEditorSaga };
