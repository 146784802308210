import React, { useRef, useState } from "react";
import { debounce } from "underscore";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";

import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";

import useStyles from "./style";

function QuoteToolbar({ onNewClicked, onImportClicked, onSearch }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = useRef(
    debounce(function _handleSearch(value) {
      if (!onSearch) return;
      onSearch(value);
    }, 500)
  );
  const handleClickNew = (e) => {
    if (!onImportClicked) return onNewClicked();
    setAnchorEl(e.currentTarget);
  };
  const handleCloseMenu = () => setAnchorEl(null);

  return (
    <AppBar position="relative" color="primary" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              handleSearch.current(e.target.value);
            }}
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
          />
        </div>
        <div className={classes.grow} />
        <IconButton color="inherit" onClick={handleClickNew}>
          <AddIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          className={classes.toolBarMenu}
          onClose={handleCloseMenu}
        >
          <MenuItem
            onClick={() => {
              onNewClicked();
              handleCloseMenu();
            }}
          >
            Input manually
          </MenuItem>
          <MenuItem
            onClick={() => {
              onImportClicked();
              handleCloseMenu();
            }}
          >
            Import from an Excel file
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

export default QuoteToolbar;
