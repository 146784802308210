import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import {
  ConnectedRouter,
  connectRouter,
  routerMiddleware,
} from "connected-react-router";
import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import { all, put } from "redux-saga/effects";
import "overlayscrollbars/css/OverlayScrollbars.css";

import App from "./App";
import authReducer from "./store/auth/reducers";
import passwordReducer from "./store/password/reducers";
import onboardingReducer from "./store/onboarding/reducers";
import dialogReducer from "./store/dialog/reducers";
import quotesListReducer from "./store/quotes/list/reducers";
import quotesEditorReducer from "./store/quotes/editor/reducers";
import productsEditorReducer from "./store/products/editor/reducers";
import productsImportReducer from "./store/products/import/reducers";
import contactsListReducer from "./store/contacts/list/reducers";
import contactsEditorReducer from "./store/contacts/editor/reducers";
import contactsImportReducer from "./store/contacts/import/reducers";
import usersListReducer from "./store/users/list/reducers";
import usersEditorReducer from "./store/users/editor/reducers";
import skusReducer from "./store/skus/reducers";
import profileSettingReducer from "./store/settings/profile/reducers";
import companySettingReducer from "./store/settings/company/reducers";
import currencySettingReducer from "./store/settings/currency/reducers";
import templateSettingReducer from "./store/settings/template/reducers";
import viewerReducer from "./store/viewer/reducers";

import { loginSaga, sessionSaga } from "./store/auth/sagas";
import { passwordSagas } from "./store/password/sagas";
import { onboardingSagas } from "./store/onboarding/sagas";
import { quoteListSaga } from "./store/quotes/list/sagas";
import { quoteEditorSaga } from "./store/quotes/editor/sagas";
import { productsEditorSaga } from "./store/products/editor/sagas";
import { productsImporterSaga } from "./store/products/import/sagas";
import { contactListSaga } from "./store/contacts/list/sagas";
import { contactEditorSaga } from "./store/contacts/editor/sagas";
import contactImporterSaga from "./store/contacts/import/sagas";
import { userListSaga } from "./store/users/list/sagas";
import { usersEditorSaga } from "./store/users/editor/sagas";
import { skuSagas } from "./store/skus/sagas";
import { profileSettingSaga } from "./store/settings/profile/sagas";
import { companySettingSaga } from "./store/settings/company/sagas";
import { currencySettingSaga } from "./store/settings/currency/sagas";
import { templateSettingSaga } from "./store/settings/template/sagas";
import { viewerSagas } from "./store/viewer/sagas";

const history = createBrowserHistory();

function* rootSaga() {
  try {
    yield all([
      loginSaga(),
      passwordSagas(),
      onboardingSagas(),
      sessionSaga(),
      quoteListSaga(),
      quoteEditorSaga(),
      productsEditorSaga(),
      productsImporterSaga(),
      skuSagas(),
      contactListSaga(),
      contactEditorSaga(),
      contactImporterSaga(),
      userListSaga(),
      usersEditorSaga(),
      profileSettingSaga(),
      companySettingSaga(),
      currencySettingSaga(),
      templateSettingSaga(),
      viewerSagas(),
    ]);
  } catch (e) {
    console.error(e);
    yield put({
      type: "FATAL_ERROR",
      payload: {
        message:
          "Unexpected error occured. If this error persists, please try again later or contact support.",
      },
    });
  }
}

const sagaMiddleware = createSagaMiddleware();
const reducers = combineReducers({
  auth: authReducer,
  dialog: dialogReducer,
  quotes: combineReducers({
    list: quotesListReducer,
    editor: quotesEditorReducer,
  }),
  products: combineReducers({
    editor: productsEditorReducer,
    import: productsImportReducer,
  }),
  skus: skusReducer,
  contacts: combineReducers({
    list: contactsListReducer,
    editor: contactsEditorReducer,
    import: contactsImportReducer,
  }),
  users: combineReducers({
    list: usersListReducer,
    editor: usersEditorReducer,
  }),
  settings: combineReducers({
    profile: profileSettingReducer,
    company: companySettingReducer,
    currency: currencySettingReducer,
    template: templateSettingReducer,
  }),
  password: passwordReducer,
  onboarding: onboardingReducer,
  viewer: viewerReducer,
  router: connectRouter(history),
});

const middleware = [applyMiddleware(routerMiddleware(history), sagaMiddleware)];
if (window.__REDUX_DEVTOOLS_EXTENSION__)
  middleware.push(window.__REDUX_DEVTOOLS_EXTENSION__());

const store = createStore(reducers, compose(...middleware));
sagaMiddleware.run(rootSaga);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
