import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import useStyle from "./style-modal";

const variables = [
  { variable: "%NAME%", description: "Customer's Name" },
  { variable: "%COMPANY%", description: "Customer's Company Name" },
  { variable: "%ADDRESS%", description: "Customer's Address" },
  { variable: "%MY_NAME%", description: "Sender's Name" },
  { variable: "%MY_EMAIL%", description: "Sender's Email Address" },
  { variable: "%MY_COMPANY%", description: "Your company's name" },
  {
    variable: "%MY_COMPANY_PHONE%",
    description: "Your company's phone number",
  },
  { variable: "%MY_COMPANY_ADDRESS%", description: "Your company's address" },
];

function HelpModal({ show, onClose, onInsert, editorRef }) {
  const classes = useStyle();

  return (
    <Dialog
      onClose={onClose}
      fullWidth
      scroll="body"
      maxWidth="sm"
      classes={classes}
      open={show}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Variable</TableCell>
            <TableCell colSpan={2}>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {variables.map(({ variable, description }) => (
            <TableRow key={variable}>
              <TableCell>
                <pre>{variable}</pre>
              </TableCell>
              <TableCell>{description}</TableCell>
              <TableCell align="right">
                <Button color="primary" onClick={() => onInsert(variable)}>
                  INSERT
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default HelpModal;
