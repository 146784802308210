import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  main: {
    flexGrow: 1,
    width: "100%",
    padding: theme.spacing(4),
    background: "#eaeff1",
  },
  spacer: {
    width: "100%",
    height: theme.spacing(1),
  },
  paper: {
    marginBottom: theme.spacing(4),
  },
  tableActions: {
    verticalAlign: "top",
  },
  number: {
    "& input": {
      fontSize: "0.8em",
      textAlign: "right",
    },
  },
}));
