import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

function Copyright() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      gutterBottom
    >
      {"Copyright © "}
      <Link color="inherit" href={process.env.REACT_APP_WHITELABEL_URL}>
        {process.env.REACT_APP_WHITELABEL_NAME}
      </Link>{" "}
      {new Date().getFullYear()}
      <br />
      <br />
    </Typography>
  );
}

export default Copyright;
