import React, { useState } from "react";
import { GooSpinner } from "react-spinners-kit";
import { useDispatch, useSelector } from "react-redux";
import { Beforeunload } from "react-beforeunload";
import { Prompt } from "react-router";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import SaveIcon from "@material-ui/icons/Save";

import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

import useInputRedux from "../../../../hooks/useInputRedux";
import ContentLoading from "../../../../components/ContentLoading";
import CurrencySelector from "../../../../components/CurrencySelector";
import NumberField from "../../../../components/NumberField";
import useStyles from "./style";

function Currency() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [taxError, setTaxError] = useState(null);

  const error = useSelector((state) => state.settings.currency.error);
  const loading = useSelector((state) => state.settings.currency.loading);
  const changed = useSelector((state) => state.settings.currency.changed);
  const saving = useSelector((state) => state.settings.currency.saving);

  const [inventory, setInventory] = useInputRedux(
    ["settings", "currency", "inventory"],
    (value) => ({
      type: "SET_SETTING_CURRENCY_DETAIL",
      payload: { field: "inventory", value },
    })
  );
  const [selling, setSelling] = useInputRedux(
    ["settings", "currency", "selling"],
    (value) => ({
      type: "SET_SETTING_CURRENCY_DETAIL",
      payload: { field: "selling", value },
    })
  );
  const [rate, setRate] = useInputRedux(
    ["settings", "currency", "rate"],
    (value) => ({
      type: "SET_SETTING_CURRENCY_DETAIL",
      payload: { field: "rate", value },
    })
  );
  const [taxRate, setTaxRate] = useInputRedux(
    ["settings", "currency", "taxRate"],
    (value) => ({
      type: "SET_SETTING_CURRENCY_DETAIL",
      payload: { field: "taxRate", value },
    })
  );

  const validateAndSubmit = () => {
    // let error = false;
    // if (name.trim().length === 0) {
    //   setNameError("Name is required.");
    //   error = true;
    // }
    // if (error) return;
    dispatch({
      type: "UPDATE_SETTING_CURRENCY",
    });
  };

  if (loading) return <ContentLoading />;

  return (
    <OverlayScrollbarsComponent className={classes.main}>
      <Paper className={classes.paper}>
        <AppBar
          component="div"
          className={classes.appBar}
          color="paper"
          position="static"
          elevation={0}
        >
          <Toolbar className={classes.toolbar}>
            <Typography
              variant="h5"
              id="tableTitle"
              component="div"
              className={classes.toolbarTitle}
            >
              {changed && (
                <Badge badgeContent={"Unsaved"} color="error">
                  Currency and Foreign Exchange
                </Badge>
              )}
              {!changed && "Currency and Foreign Exchange"}
            </Typography>
            {saving && (
              <div className={classes.loading}>
                <GooSpinner size={24} color="#000" />
              </div>
            )}
            {!saving && (
              <IconButton
                color="inherit"
                disabled={!changed}
                onClick={validateAndSubmit}
              >
                <SaveIcon />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
        <Divider />
        <Grid container className={classes.content} spacing={2}>
          {error && (
            <Grid item xs={12}>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <CurrencySelector
                label="Inventory"
                disabled={saving}
                value={inventory}
                onChange={setInventory}
              />
            </Grid>
            <Grid item xs={12}>
              <CurrencySelector
                label="Selling"
                disabled={saving}
                value={selling}
                onChange={setSelling}
              />
            </Grid>
            <Grid item xs={12}>
              <NumberField
                fullWidth
                placeholder={"1,234.56"}
                label={`Conversion Rate (${inventory} to ${selling})`}
                disabled={saving || inventory === selling}
                value={inventory === selling ? "1" : rate}
                onChange={(e) => {
                  if (inventory === selling) return;
                  if (rate !== e.target.value) setRate(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <NumberField
                fullWidth
                suffix="%"
                placeholder={"10.00%"}
                label="Tax rate in percent"
                disabled={saving}
                value={taxRate}
                onChange={(e) => {
                  if (taxRate !== e.target.value) setTaxRate(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <div className={classes.spacer} />
      {changed && (
        <>
          <Beforeunload
            onBeforeunload={() =>
              "You have unsaved changes. Are you sure? Unsaved changes will lost."
            }
          />
          <Prompt message="You have unsaved changes. Are you sure? Unsaved changes will lost." />
        </>
      )}
    </OverlayScrollbarsComponent>
  );
}

export default Currency;
