import React from "react";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";

import PanoramaIcon from "@material-ui/icons/Panorama";

import FileInput from "../../../components/FileInput";

import useStyles from "./styles";

function Step2({ picture, onUpload }) {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12}>
        Upload your profile picture so others can recognise you!{" "}
        <em>This step is optional.</em>
      </Grid>
      <Grid
        container
        item
        xs={12}
        direction="column"
        spacing={2}
        alignContent="center"
      >
        <Grid item>
          <Avatar className={classes.picture} src={picture}>
            <PanoramaIcon />
          </Avatar>
        </Grid>
        <Grid item>
          <FileInput
            buttonText="Upload Picture"
            buttonProps={{
              fullWidth: true,
              size: "small",
              variant: "outlined",
            }}
            onSelected={onUpload}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Step2;
