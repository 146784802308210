import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";

import QuoteSkeleton from "./QuoteSkeleton";
import QuoteToolbar from "./QuoteToolbar";
import QuoteListItem from "./QuoteListItem";
import useStyles from "./style";

function QuoteList({ id }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const quotes = useSelector((state) => state.quotes.list.items);
  const details = useSelector((state) => state.quotes.list.details);
  const loadingNewer = useSelector((state) => state.quotes.list.loadingNewer);
  const loadingOlder = useSelector((state) => state.quotes.list.loadingOlder);
  const hasOlder = useSelector((state) => state.quotes.list.hasOlder);

  const searchLoading = useSelector((state) => state.quotes.list.searchLoading);
  const searchKeyword = useSelector((state) => state.quotes.list.searchKeyword);
  const searchResult = useSelector((state) => state.quotes.list.searchResult);

  useEffect(() => {
    dispatch({
      type: "LOAD_QUOTES",
      payload: {
        direction: quotes.length === 0 ? "after" : "before",
      },
    });
  }, []);

  const items = searchKeyword.length ? searchResult : quotes;

  return (
    <div className={classes.root}>
      <QuoteToolbar />
      {!loadingNewer &&
        !loadingOlder &&
        searchKeyword.length === 0 &&
        quotes.length === 0 && (
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            justify="center"
            className={classes.empty}
          >
            <Grid item>
              To create a new quote, <br />
              click the + icon below
            </Grid>
          </Grid>
        )}
      {!searchLoading && searchKeyword.length > 0 && searchResult.length === 0 && (
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justify="center"
          className={classes.empty}
        >
          <Grid item>No quote matching your search criteria found.</Grid>
        </Grid>
      )}
      <OverlayScrollbarsComponent
        className={classes.scroll}
        options={{ scrollbars: { autoHide: "scroll" } }}
      >
        <List component="nav" className={classes.list}>
          {searchKeyword.length !== 0 && searchLoading && <QuoteSkeleton />}
          {loadingNewer && <QuoteSkeleton n={1} />}
          {(!searchLoading || searchKeyword.length === 0) &&
            items.map((quote) => (
              <QuoteListItem
                key={quote}
                quoteId={quote}
                selected={id === quote}
                quoteTitle={details[quote].title}
                quoteStatus={details[quote].status}
                lastModified={details[quote].lastModified}
                lastModifiedBy={details[quote].lastModifiedBy}
              />
            ))}
          {loadingOlder && <QuoteSkeleton />}
          {!searchKeyword.length && !loadingOlder && hasOlder && (
            <QuoteSkeleton
              onVisible={() => {
                dispatch({
                  type: "LOAD_QUOTES",
                  payload: {
                    direction: "after",
                  },
                });
              }}
            />
          )}
        </List>
      </OverlayScrollbarsComponent>
    </div>
  );
}

export default QuoteList;
