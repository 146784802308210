const keywords = [
  { keyword: "%COMPANY%", bold: false, color: "green" },
  { keyword: "%NAME%", bold: true, color: "green" },
  { keyword: "%ADDRESS%", bold: false, color: "green" },
  { keyword: "%MY_COMPANY%", bold: true, color: "blue" },
  { keyword: "%MY_COMPANY_ADDRESS%", bold: false, color: "blue" },
  { keyword: "%MY_COMPANY_PHONE%", bold: false, color: "blue" },
  { keyword: "%MY_NAME%", bold: true, color: "blue" },
  { keyword: "%MY_EMAIL%", bold: true, color: "blue" },
].map((keyword) => ({
  ...keyword,
  re: new RegExp(keyword.keyword, "g"),
}));

export default function highlight(text) {
  let tmp = text;
  for (let keyword of keywords) {
    let to = keyword.keyword;
    if (keyword.bold) to = `<b>${to}</b>`;
    if (keyword.color) to = `<font color="${keyword.color}">${to}</font>`;
    tmp = tmp.replace(keyword.re, to);
  }
  return tmp;
}
