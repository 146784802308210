import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Beforeunload } from "react-beforeunload";
import { Prompt } from "react-router";

import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import { getCurrencySymbol } from "../../../lib/currency";

import useInputRedux from "../../../hooks/useInputRedux";

import NumberField from "../../../components/NumberField";
import TabPanel from "../../../components/TabPanel";
import QuoteRecipient from "./QuoteMeta/QuoteRecipient";
import QuotePayments from "./QuoteMeta/QuotePayments";
import QuoteAudit from "./QuoteMeta/QuoteAudit";

import QuoteItem from "./QuoteItem";
import QuoteMeta from "./QuoteMeta";
import SendDialog from "./SendDialog";

import reorder from "./reorder";
import useStyles from "./style-content";

function QuoteEditor({ id }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [tabValue, setTabValue] = useState(0);

  const sellingCurrency = useSelector(
    (state) => state.settings.currency.selling
  );
  const taxRate = useSelector((state) => state.settings.currency.taxRate);
  const error = useSelector((state) => state.quotes.editor.error);
  const changed = useSelector((state) => state.quotes.editor.changed);
  const items = useSelector((state) => state.quotes.editor.items);
  const subtotal = useSelector((state) => state.quotes.editor.summary.subtotal);
  const [discount, setDiscount] = useInputRedux(
    ["quotes", "editor", "details", "discount"],
    (value) => ({
      type: "SET_QUOTE_DETAIL",
      payload: { field: "discount", value, id },
    })
  );

  const discountAmount =
    Number.parseFloat(discount) !== Number.NaN
      ? Number.parseFloat(discount)
      : 0;
  const afterDiscount = subtotal - discountAmount;
  const tax = Math.max(afterDiscount * (taxRate / 100), 0);
  const total = Math.max(afterDiscount + tax, 0);

  const currency = sellingCurrency; //getCurrencySymbol(sellingCurrency);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    dispatch({
      type: "SET_QUOTE_ITEM_ORDER",
      payload: reorder(items, result.source.index, result.destination.index),
    });
  };

  const onSaveClicked = () =>
    dispatch({
      type: "UPDATE_QUOTE",
    });

  const onDownloadClicked = () =>
    dispatch({
      type: "DOWNLOAD_QUOTE",
      payload: { id },
    });

  const onDeleteClicked = () =>
    dispatch({
      type: "DELETE_QUOTE",
      payload: { id },
    });

  const onCopyClicked = () =>
    dispatch({
      type: "COPY_QUOTE",
      payload: { id },
    });

  const onSendClicked = () =>
    dispatch({
      type: "SHOW_SEND_QUOTE",
    });

  return (
    <OverlayScrollbarsComponent className={classes.main}>
      {error && (
        <Alert severity="error" className={classes.paper}>
          {error}
        </Alert>
      )}
      <Paper className={classes.paper}>
        <QuoteMeta
          id={id}
          tabValue={tabValue}
          setTabValue={setTabValue}
          onSaveClicked={onSaveClicked}
          onDownloadClicked={onDownloadClicked}
          onDeleteClicked={onDeleteClicked}
          onCopyClicked={onCopyClicked}
          onSendClicked={onSendClicked}
        />
        <TabPanel value={tabValue} index={0}>
          <QuoteRecipient id={id} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <TableContainer>
            <Table className={classes.table} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCell width="55%" colSpan={3}>
                    Products
                  </TableCell>
                  <TableCell width="15%" align="right">
                    Qty.
                  </TableCell>
                  <TableCell width="15%" align="right">
                    Unit
                  </TableCell>
                  <TableCell width="15%" align="right">
                    Sum
                  </TableCell>
                </TableRow>
              </TableHead>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <TableBody
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {items.map((item, i) => (
                        <QuoteItem key={item.ref} item={item} index={i} />
                      ))}
                      {provided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
              <TableBody>
                <TableRow>
                  <TableCell
                    colSpan={3}
                    rowSpan={4}
                    className={classes.tableActions}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          variant="outlined"
                          onClick={() =>
                            dispatch({
                              type: "ADD_QUOTE_ITEM",
                              payload: { type: "item" },
                            })
                          }
                        >
                          Add Product
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          variant="outlined"
                          onClick={() =>
                            dispatch({
                              type: "ADD_QUOTE_ITEM",
                              payload: { type: "separator" },
                            })
                          }
                        >
                          Add Separator
                        </Button>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell colSpan={2}>Subtotal</TableCell>
                  <TableCell align="right">
                    {currency} {subtotal.toLocaleString()}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Discount</TableCell>
                  <TableCell colSpan={2} align="right">
                    <NumberField
                      value={discount}
                      error={!discount || discount.length === 0}
                      onChange={(e) => {
                        setDiscount(e.target.value);
                      }}
                      className={classes.number}
                      fullWidth
                      placeholder="Discount Amount"
                      currency={`${currency} `}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tax</TableCell>
                  <TableCell align="right">{taxRate}%</TableCell>
                  <TableCell align="right">
                    {currency} {tax.toLocaleString()}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>Total</TableCell>
                  <TableCell align="right">
                    {currency} {total.toLocaleString()}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <QuotePayments id={id} />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <QuoteAudit id={id} />
        </TabPanel>
      </Paper>
      {/* <Paper className={classes.paper}>
        
      </Paper> */}
      <div className={classes.spacer} />
      {changed && (
        <>
          <Beforeunload
            onBeforeunload={() =>
              "You have unsaved changes. Are you sure? Unsaved changes will lost."
            }
          />
          <Prompt message="You have unsaved changes. Are you sure? Unsaved changes will lost." />
        </>
      )}
      <SendDialog id={id} />
    </OverlayScrollbarsComponent>
  );
}

export default QuoteEditor;
