import { getImageURL } from "../../../lib/image";
const update = require("immutability-helper");

const initialState = {
  error: null,
  loading: false,
  saving: false,
  changed: false,

  password: null,
  confirmPassword: null,
  picture: null,
  email: null,
  name: null,
};

function profile(state = initialState, action) {
  switch (action.type) {
    case "GET_SETTING_PROFILE": {
      return update(state, {
        error: {
          $set: null,
        },
        loading: {
          $set: true,
        },
        changed: {
          $set: false,
        },
      });
    }
    case "SET_SETTING_PROFILE_DETAIL": {
      const { field, value } = action.payload;
      return update(state, {
        changed: {
          $set: true,
        },
        error: {
          $set: null,
        },
        [field]: {
          $set: value,
        },
      });
    }
    case "REMOVE_SETTING_PROFILE_PICTURE_SUCCESS": {
      return update(state, {
        picture: {
          $set: null,
        },
      });
    }
    case "UPLOAD_SETTING_PROFILE_PICTURE": {
      return update(state, {
        error: {
          $set: null,
        },
      });
    }
    case "UPLOAD_SETTING_PROFILE_PICTURE_SUCCESS": {
      const { image } = action.payload;
      return update(state, {
        picture: {
          $set: getImageURL(image),
        },
      });
    }
    case "GET_SETTING_PROFILE_SUCCESS": {
      const { picture, email, name } = action.payload;
      return update(state, {
        loading: {
          $set: false,
        },
        email: {
          $set: email,
        },
        name: {
          $set: name,
        },
        password: {
          $set: null,
        },
        confirmPassword: {
          $set: null,
        },
        picture: {
          $set: picture && getImageURL(picture),
        },
      });
    }
    case "UPDATE_SETTING_PROFILE": {
      return update(state, {
        error: {
          $set: null,
        },
        saving: {
          $set: true,
        },
      });
    }
    case "UPDATE_SETTING_PROFILE_SUCCESS": {
      return update(state, {
        changed: {
          $set: false,
        },
        saving: {
          $set: false,
        },
      });
    }
    case "UPDATE_SETTING_PROFILE_ERROR":
    case "REMOVE_SETTING_PROFILE_PICTURE_ERROR":
    case "UPLOAD_SETTING_PROFILE_PICTURE_ERROR": {
      return update(state, {
        saving: {
          $set: false,
        },
        error: {
          $set: action.payload,
        },
      });
    }
    default:
      return state;
  }
}

export default profile;
