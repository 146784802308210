import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import ProductList from "./ProductList";
import ProductEditor from "./ProductEditor";
import ProductImporter from "./ProductImporter";

import { LayoutSwitcher } from "../../components/Layout";

function Products() {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) dispatch({ type: "GET_PRODUCT", payload: { sku: id } });
  }, [id]);

  useEffect(() => {
    dispatch({ type: "GET_SETTING_CURRENCY" });
  }, []);

  return (
    <>
      <LayoutSwitcher
        sidebar={<ProductList id={id} />}
        main={<ProductEditor id={id} />}
        isActive={!!id}
      />
      <ProductImporter />
    </>
  );
}

export default Products;
