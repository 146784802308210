import { select, put } from "redux-saga/effects";
import { fn } from "../fn";

function createListLoaderSaga(name, cursorField, actionType) {
  return function* _loaderSaga({ payload }) {
    const direction = (payload && payload.direction) || "after";
    let offset = null;

    if (direction === "before") {
      const items = yield select((state) => state[name].list.items);
      const details = yield select((state) => state[name].list.details);
      offset = items.length
        ? details[items[0]][cursorField]
        : yield select((state) => state[name].list.cursor);
    } else {
      offset = yield select((state) => state[name].list.cursor);
    }
    const data = yield fn(`get-${name}`, { direction, offset });
    yield put({
      type: `${actionType}_SUCCESS`,
      payload: { data, direction },
    });
  };
}

export default createListLoaderSaga;
