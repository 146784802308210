import { put, takeEvery } from "redux-saga/effects";
import { fn, fnDownloadWithKey } from "../../lib/fn";

function* handleGetQuote({ payload }) {
  try {
    const { viewKey } = payload;
    const quote = yield fn("get-quote-for-viewer", null, { key: viewKey });
    yield put({ type: "GET_QUOTE_FOR_VIEWER_SUCCESS", payload: quote });
  } catch (e) {
    console.error(e);
    yield put({
      type: "FATAL_ERROR",
      payload: {
        message:
          (e.message &&
            `${e.message}. If this error persists, please try again later or contact support.`) ||
          "Unexpected error occured. If this error persists, please try again later or contact support.",
      },
    });
  }
}

function* handleDownloadQuote({ payload }) {
  const { viewKey } = payload;
  window.location = fnDownloadWithKey("download-quote-from-viewer", viewKey);
}

function* viewerSagas() {
  yield takeEvery("GET_QUOTE_FOR_VIEWER", handleGetQuote);
  yield takeEvery("DOWNLOAD_QUOTE_FROM_VIEWER", handleDownloadQuote);
}

export { viewerSagas };
