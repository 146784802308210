import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

function Step1({
  name,
  setName,
  email,
  setEmail,
  password,
  setPassword,
  passwordConfirm,
  setPasswordConfirm,
  passwordError,
  emailError,
  nameError,
}) {
  return (
    <>
      <Grid item xs={12}>
        To activate your account and start managing quotations, please provide
        your details.
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={name}
          onChange={(e) => setName(e.target.value)}
          margin="normal"
          required
          fullWidth
          id="name"
          label="Full Name"
          name="name"
          autoComplete="name"
          autoFocus
          error={!!nameError}
          helperText={nameError}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          error={!!emailError}
          helperText={emailError}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          margin="normal"
          required
          fullWidth
          name="password"
          label="New Password"
          type="password"
          id="password"
          autoComplete="new-password"
          error={!!passwordError}
          helperText={passwordError}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={passwordConfirm}
          onChange={(e) => setPasswordConfirm(e.target.value)}
          margin="normal"
          required
          fullWidth
          name="passwordConfirm"
          label="Confirm Password"
          type="password"
          id="passwordConfirm"
          autoComplete="new-password"
          error={!!passwordError}
          helperText={passwordError}
        />
      </Grid>
    </>
  );
}

export default Step1;
