import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import ContactList from "./ContactList";
import ContactEditor from "./ContactEditor";
import ContactImporter from "./ContactImporter";

import { LayoutSwitcher } from "../../components/Layout";

function Contacts() {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) dispatch({ type: "GET_CONTACT", payload: { id } });
  }, [id]);
  return (
    <>
      <LayoutSwitcher
        sidebar={<ContactList id={id} />}
        main={<ContactEditor id={id} />}
        isActive={!!id}
      />
      <ContactImporter />
    </>
  );
}

export default Contacts;
