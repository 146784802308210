import React from "react";
import { WhisperSpinner } from "react-spinners-kit";

import useStyles from "./styles";

function ContentLoading() {
  const classes = useStyles();

  return (
    <div className={classes.loading}>
      <WhisperSpinner size={75} frontColor="#232f3e" backColor="#232f3e" />
    </div>
  );
}

export default ContentLoading;
