import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  loading: {
    flexGrow: 1,
    background: "#eaeff1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
