import Promise from "bluebird";
import { push } from "connected-react-router";
import { eventChannel } from "redux-saga";
import { takeEvery, take, put, select } from "redux-saga/effects";
import { fn, fnUpload } from "../../../lib/fn";

function* handleGetProduct({ payload }) {
  const { sku } = payload;
  if (sku === "new") {
    yield put({
      type: "GET_PRODUCT_SUCCESS",
      payload: {
        sku: "new",
        name: "",
        unit: "",
        external_name: null,
        cost_price: null,
        selling_price: null,
        image: null,
      },
    });
    return;
  }

  const data = yield fn("get-product", { sku });
  if (!data) {
    yield put(push("/products"));
    return;
  }

  yield put({
    type: "GET_PRODUCT_SUCCESS",
    payload: data,
  });
}

function* handleNewProduct() {
  try {
    // give time to the debounced redux updater
    yield Promise.delay(1500);
    const editor = yield select((state) => state.products.editor);
    const { success } = yield fn("new-product", {
      sku: editor.sku,
      name: editor.name,
      unit: editor.unit,
      external_name: editor.externalName || null,
      cost_price:
        editor.costPrice !== null && editor.costPrice.length
          ? parseFloat(editor.costPrice)
          : null,
      selling_price:
        editor.sellingPrice !== null && editor.sellingPrice.length
          ? parseFloat(editor.sellingPrice)
          : null,
    });

    if (!success) {
      yield put({
        type: "NEW_PRODUCT_ERROR",
        payload: "Fail to update the database. Please try again later.",
      });
      return;
    }
    yield put({
      type: "GET_SKUS",
    });
    yield put({
      type: "NEW_PRODUCT_SUCCESS",
      payload: { sku: "new" },
    });
    yield put(push(`/product/${editor.sku}`));
  } catch (e) {
    console.log(e.response);
    yield put({
      type: "NEW_PRODUCT_ERROR",
      payload: e.message || "Unknown Error",
    });
  }
}

function* handleUpdateProduct() {
  try {
    // give time to the debounced redux updater
    yield Promise.delay(1500);
    const editor = yield select((state) => state.products.editor);
    const { success } = yield fn("update-product", {
      sku: editor.activeProduct,
      name: editor.name,
      unit: editor.unit,
      external_name: editor.externalName || null,
      cost_price:
        editor.costPrice !== null && editor.costPrice.length
          ? parseFloat(editor.costPrice)
          : null,
      selling_price:
        editor.sellingPrice !== null && editor.sellingPrice.length
          ? parseFloat(editor.sellingPrice)
          : null,
    });

    if (!success) {
      yield put({
        type: "UPDATE_PRODUCT_ERROR",
        payload: "Fail to update the database. Please try again later.",
      });
      return;
    }
    yield put({
      type: "GET_SKUS",
    });
    yield put({
      type: "UPDATE_PRODUCT_SUCCESS",
      payload: { sku: editor.activeProduct },
    });
  } catch (e) {
    yield put({
      type: "UPDATE_PRODUCT_ERROR",
      payload: e.message || "Unknown Error",
    });
  }
}

function* handleUploadProductPicture({ payload }) {
  const { sku } = payload;
  yield put({
    type: "SHOW_LOADING",
    payload: { message: "Uploading ..." },
  });
  const chan = eventChannel((emit) => {
    fnUpload(
      "upload-product-image",
      payload,
      (progress) => emit({ type: "PROGRESS", progress }),
      (data) => emit({ type: "DONE", data }),
      (error) => emit({ type: "ERROR", error })
    );
    return () => {};
  });

  try {
    while (true) {
      const event = yield take(chan);
      if (event.type === "PROGRESS") {
        yield put({
          type: "SHOW_LOADING",
          payload: {
            message:
              event.progress < 100
                ? `(${event.progress.toFixed(2)}%) Uploading ...`
                : "Processing ...",
          },
        });
      } else if (event.type === "DONE") {
        const { id } = event.data;
        yield put({
          type: "UPLOAD_PRODUCT_PICTURE_SUCCESS",
          payload: { sku, image: id },
        });
        return;
      } else {
        throw event.error;
      }
    }
  } catch (e) {
    yield put({
      type: "UPLOAD_PRODUCT_PICTURE_ERROR",
      payload: e.message || "Unknown Error",
    });
  } finally {
    chan.close();
    yield put({ type: "HIDE_LOADING" });
  }
}

function* handleRemoveProductPicture({ payload }) {
  const { sku } = payload;
  yield put({
    type: "SHOW_LOADING",
    payload: { message: "Removing product image ..." },
  });

  try {
    const { success } = yield fn("remove-product-picture", { sku });
    if (!success) {
      yield put({
        type: "REMOVE_PRODUCT_PICTURE_ERROR",
        payload: "Fail to update the database. Please try again later.",
      });
      return;
    }
    yield put({
      type: "REMOVE_PRODUCT_PICTURE_SUCCESS",
      payload: { sku },
    });
  } catch (e) {
    yield put({
      type: "REMOVE_PRODUCT_PICTURE_ERROR",
      payload: e.message || "Unknown Error",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}
function* handleDeleteProduct({ payload }) {
  const { sku, name } = payload;
  yield put({
    type: "SHOW_LOADING",
    payload: { message: `Deleting ${name} ...` },
  });

  try {
    const { success } = yield fn("delete-product", { sku });
    if (!success) {
      yield put({
        type: "DELETE_PRODUCT_ERROR",
        payload: "Fail to update the database. Please try again later.",
      });
      return;
    }
    yield put({
      type: "GET_SKUS",
    });
    yield put(push("/products"));
  } catch (e) {
    yield put({
      type: "DELETE_PRODUCT_ERROR",
      payload: e.message || "Unknown Error",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* productsEditorSaga() {
  yield takeEvery("GET_PRODUCT", handleGetProduct);
  yield takeEvery("UPDATE_PRODUCT", handleUpdateProduct);
  yield takeEvery("NEW_PRODUCT", handleNewProduct);
  yield takeEvery("DELETE_PRODUCT", handleDeleteProduct);
  yield takeEvery("UPLOAD_PRODUCT_PICTURE", handleUploadProductPicture);
  yield takeEvery("REMOVE_PRODUCT_PICTURE", handleRemoveProductPicture);
}

export { productsEditorSaga };
