import React, { useState } from "react";
import { useSelector } from "react-redux";
import { GooSpinner } from "react-spinners-kit";

import Badge from "@material-ui/core/Badge";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import SaveIcon from "@material-ui/icons/Save";
import SendIcon from "@material-ui/icons/Send";

import TabPanel from "../../../../components/TabPanel";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import QuoteRecipient from "./QuoteRecipient";
import QuotePayments from "./QuotePayments";
import QuoteAudit from "./QuoteAudit";
import useStyles from "./styles";

function QuoteMeta({
  id,
  tabValue,
  setTabValue,
  onSendClicked,
  onSaveClicked,
  onDownloadClicked,
  onCopyClicked,
  onDeleteClicked,
}) {
  const classes = useStyles();
  const changed = useSelector((state) => state.quotes.editor.changed);
  const saving = useSelector((state) => state.quotes.editor.saving);
  const [deleting, setDeleting] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <AppBar
        component="div"
        className={classes.appBar}
        color="primary"
        position="static"
        elevation={0}
      >
        <Toolbar className={classes.toolbar}>
          <Typography
            variant="h5"
            id="tableTitle"
            component="div"
            className={classes.toolbarTitle}
          >
            {changed ? (
              <Badge badgeContent={"Unsaved"} color="error">
                #{id}
              </Badge>
            ) : (
              `#${id}`
            )}
          </Typography>
          {saving && (
            <div className={classes.loading}>
              <GooSpinner size={24} />
            </div>
          )}
          {!saving && (
            <IconButton
              color="inherit"
              disabled={!changed}
              onClick={onSaveClicked}
            >
              <SaveIcon />
            </IconButton>
          )}
          <IconButton color="inherit" disabled={saving} onClick={onSendClicked}>
            <SendIcon />
          </IconButton>
          <IconButton color="inherit" disabled={saving} onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={!!anchorEl}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                onDownloadClicked();
                handleClose();
              }}
            >
              Download as Excel
            </MenuItem>
            <MenuItem
              onClick={() => {
                onCopyClicked();
                handleClose();
              }}
            >
              Make a copy
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                setDeleting(true);
                handleClose();
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </Toolbar>
        <Tabs value={tabValue} onChange={(_, i) => setTabValue(i)}>
          <Tab label="Recipient" />
          <Tab label="Products" />
          <Tab label="Payments &amp; Delivery" />
          <Tab label="Audit" />
        </Tabs>
      </AppBar>
      <ConfirmDialog
        show={deleting}
        onClose={() => setDeleting(null)}
        onConfirm={() => {
          setDeleting(null);
          onDeleteClicked();
        }}
        title={`Deleting ...`}
        message={
          <>
            Are you sure you want to delete quote <strong>{id}</strong>? This
            action cannot be undone.
          </>
        }
      />
    </>
  );
}

export default QuoteMeta;
