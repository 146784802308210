import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VariableSizeList } from "react-window";
import classnames from "classnames";

import ListSubheader from "@material-ui/core/ListSubheader";

import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

import Autocomplete from "@material-ui/lab/Autocomplete";

import NumberField from "../../../../components/NumberField";
import { fn } from "../../../../lib/fn";
import { getCurrencySymbol } from "../../../../lib/currency";
import useStyles from "./style-edit";

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const itemSize = 72;

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

function EditView({ item, onSave, onCancel }) {
  const inventoryCurrency = useSelector(
    (state) => state.settings.currency.inventory
  );
  const sellingCurrency = useSelector(
    (state) => state.settings.currency.selling
  );
  const conversionRate = useSelector((state) => state.settings.currency.rate);
  const skuLoading = useSelector((state) => state.skus.loading);
  const lastFetch = useSelector((state) => state.skus.lastFetch);
  const skus = useSelector((state) => state.skus.skus);
  const [loading, setLoading] = useState(false);

  const [skuError, setSKUError] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [unitError, setUnitError] = useState(null);
  const [qtyError, setQtyError] = useState(null);

  const [itemCost, setItemCost] = useState((item && item.cost) || null);

  const [txtSKU, setTxtSKU] = useState((item && item.sku) || "");
  const [txtName, setTxtName] = useState((item && item.name) || "");
  const [txtQty, setTxtQty] = useState((item && `${item.qty}`) || "1");
  const [txtUnit, setTxtUnit] = useState((item && `${item.unit}`) || "");
  const [txtUnitUnit, setTxtUnitUnit] = useState((item && item.unitUnit) || "");

  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (Date.now() - lastFetch > 5 * 60 * 1000) dispatch({ type: "GET_SKUS" });
  }, [item]);

  const currency = `${sellingCurrency} `;
  const qty = parseInt(txtQty, 10);
  const unit = parseFloat(txtUnit);
  const sum = !Number.isNaN(qty) && !Number.isNaN(unit) ? qty * unit : 0;

  const loadSKU = async (sku) => {
    setLoading(true);
    const product = await fn("get-product", { sku });
    const rate = inventoryCurrency === sellingCurrency ? 1 : conversionRate;
    const name = product.external_name || product.name;
    const cost = product.cost_price && rate * product.cost_price;
    const unit = product.selling_price && rate * product.selling_price;
    const unitUnit = product.unit || "";
    setTxtName(name);
    setTxtUnit((unit && `${unit}`) || "0");
    setItemCost(cost);
    setLoading(false);
    setTxtUnitUnit(unitUnit);
  };

  const clearProductDetails = () => {
    setItemCost(null);
  };

  const clearError = () => {
    setSKUError(null);
    setNameError(null);
    setUnitError(null);
    setQtyError(null);
  };

  const onSaveClicked = () => {
    let error = false;
    if (!txtSKU.length) {
      setSKUError("Please enter a valid SKU.");
      error = true;
    }
    if (!txtName.length) {
      setNameError("Please enter a valid item name.");
      error = true;
    }
    if (!txtUnit.length || Number.parseFloat(txtUnit) < 0) {
      setUnitError("Price >= 0");
      error = true;
    }
    if (!txtQty.length || Number.parseFloat(txtQty) <= 0) {
      setQtyError("Qty > 0");
      error = true;
    }

    if (error) return;
    onSave({
      ...item,
      sku: txtSKU,
      name: txtName,
      unitUnit: txtUnitUnit,
      cost: itemCost,
      unit,
      qty,
    });
  };

  return (
    <>
      <TableCell width="5%">
        <DragIndicatorIcon />
      </TableCell>
      <TableCell width="5%" align="right">
        <IconButton disabled={loading} color="primary" onClick={onSaveClicked}>
          <CheckIcon fontSize="small" />
        </IconButton>
        <IconButton disabled={loading} color="secondary" onClick={onCancel}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </TableCell>
      <TableCell width="45%">
        <Autocomplete
          ListboxComponent={ListboxComponent}
          value={txtSKU}
          disabled={loading}
          onInputChange={(_, value) => {
            //setTxtSKU(value);
            clearError();
            if (value !== txtSKU) clearProductDetails();
          }}
          onChange={(_, selected) => {
            if (!selected || !selected.sku) {
              setTxtSKU(selected || "");
              return;
            }
            setTxtSKU(selected.sku);
            loadSKU(selected.sku);
          }}
          freeSolo
          autoSelect
          loading={skuLoading}
          options={skus}
          getOptionLabel={(option) => {
            if (option.sku && option.name)
              return `${option.sku} ${option.name}`;
            return option.sku || option;
          }}
          renderOption={(option) => {
            const txt = `${option.sku} (${option.name})`;
            return (
              <Typography variant={txt.length > 75 ? "caption" : "body2"}>
                {txt}
              </Typography>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!skuError}
              helperText={skuError}
              autoFocus
              className={classes.sku}
              fullWidth
              required
              placeholder="SKU"
              size="small"
            />
          )}
        />
        <TextField
          disabled={loading}
          value={txtName}
          error={!!nameError}
          helperText={nameError}
          onChange={(e) => {
            setTxtName(e.target.value);
            clearError();
          }}
          fullWidth
          required
          placeholder="Product Name"
          InputProps={{
            endAdornment: loading && (
              <InputAdornment position="end">
                <CircularProgress />
              </InputAdornment>
            ),
          }}
        />
      </TableCell>
      <TableCell width="15%" align="right">
        <NumberField
          value={txtQty}
          error={!!qtyError}
          helperText={qtyError}
          onChange={(e) => {
            setTxtQty(e.target.value);
            clearError();
          }}
          disabled={loading}
          className={classnames(classes.number, classes.qty)}
          placeholder="Qty"
        />
        <TextField
          disabled={loading}
          value={txtUnitUnit}
          onChange={(e) => {
            setTxtUnitUnit(e.target.value);
            clearError();
          }}
          className={classnames(classes.number, classes.qty)}
          required
          placeholder="unit"
        />
      </TableCell>
      <TableCell width="15%" align="right">
        <NumberField
          value={txtUnit}
          error={!!unitError}
          helperText={
            unitError ||
            (itemCost &&
              itemCost > unit &&
              `COGS is ${currency}${itemCost.toLocaleString()}`)
          }
          onChange={(e) => {
            setTxtUnit(e.target.value);
            clearError();
          }}
          disabled={loading}
          className={classes.number}
          fullWidth
          placeholder="Unit"
          currency={currency}
        />
      </TableCell>
      <TableCell width="10%" align="right">
        {currency} {sum.toLocaleString()}
      </TableCell>
    </>
  );
}

export default EditView;
