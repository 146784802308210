import React, { useState } from "react";
import { GooSpinner } from "react-spinners-kit";
import { useDispatch, useSelector } from "react-redux";
import { Beforeunload } from "react-beforeunload";
import { Prompt } from "react-router";
import Alert from "@material-ui/lab/Alert";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import PanoramaIcon from "@material-ui/icons/Panorama";

import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

import { getCurrencySymbol } from "../../../lib/currency";
import useInputRedux from "../../../hooks/useInputRedux";
import ConfirmDialog from "../../../components/ConfirmDialog";
import ContentLoading from "../../../components/ContentLoading";
import FileInput from "../../../components/FileInput";
import NumberField from "../../../components/NumberField";
import Empty from "./Empty";
import useStyles from "./style";

function ProductEditor({ id }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(null);
  const loadingCurrency = useSelector(
    (state) => state.settings.currency.loading
  );
  const inventoryCurrency = useSelector(
    (state) => state.settings.currency.inventory
  );

  const error = useSelector((state) => state.products.editor.error);
  const activeProduct = useSelector(
    (state) => state.products.editor.activeProduct
  );
  const changed = useSelector((state) => state.products.editor.changed);
  const loading = useSelector((state) => state.products.editor.loading);
  const saving = useSelector((state) => state.products.editor.saving);
  const [sku, setSKU] = useInputRedux(
    ["products", "editor", "sku"],
    (value) => ({
      type: "SET_PRODUCT_DETAIL",
      payload: { field: "sku", value, sku: id },
    })
  );
  const [name, setName] = useInputRedux(
    ["products", "editor", "name"],
    (value) => ({
      type: "SET_PRODUCT_DETAIL",
      payload: { field: "name", value, sku: id },
    })
  );
  const [externalName, setExternalName] = useInputRedux(
    ["products", "editor", "externalName"],
    (value) => ({
      type: "SET_PRODUCT_DETAIL",
      payload: { field: "externalName", value, sku: id },
    })
  );
  const [costPrice, setCostPrice] = useInputRedux(
    ["products", "editor", "costPrice"],
    (value) => ({
      type: "SET_PRODUCT_DETAIL",
      payload: { field: "costPrice", value, sku: id },
    })
  );
  const [sellingPrice, setSellingPrice] = useInputRedux(
    ["products", "editor", "sellingPrice"],
    (value) => ({
      type: "SET_PRODUCT_DETAIL",
      payload: { field: "sellingPrice", value, sku: id },
    })
  );
  const [unit, setUnit] = useInputRedux(
    ["products", "editor", "unit"],
    (value) => ({
      type: "SET_PRODUCT_DETAIL",
      payload: { field: "unit", value, sku: id },
    })
  );
  const image = useSelector((state) => state.products.editor.image);

  if (!id) return <Empty />;
  if (loadingCurrency || loading || activeProduct !== id)
    return <ContentLoading />;

  const currency = `${inventoryCurrency} `;
  const newProduct = id === "new";
  const title = newProduct ? "New Product" : id;

  return (
    <>
      <OverlayScrollbarsComponent className={classes.main}>
        <Paper className={classes.paper}>
          <AppBar
            component="div"
            className={classes.appBar}
            color="paper"
            position="static"
            elevation={0}
          >
            <Toolbar className={classes.toolbar}>
              <Typography
                variant="h5"
                id="tableTitle"
                component="div"
                className={classes.toolbarTitle}
              >
                {changed ? (
                  <Badge badgeContent={"Unsaved"} color="error">
                    {title}
                  </Badge>
                ) : (
                  title
                )}
              </Typography>
              {saving && (
                <div className={classes.loading}>
                  <GooSpinner size={24} color="#000" />
                </div>
              )}
              {!saving && (
                <IconButton
                  color="inherit"
                  disabled={!changed}
                  onClick={() =>
                    dispatch({
                      type: newProduct ? "NEW_PRODUCT" : "UPDATE_PRODUCT",
                    })
                  }
                >
                  <SaveIcon />
                </IconButton>
              )}
              {!newProduct && (
                <IconButton
                  color="inherit"
                  onClick={() => setDeleting("product")}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Toolbar>
          </AppBar>
          <Divider />
          <Grid container className={classes.content} spacing={2}>
            {error && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            <Grid
              container
              item
              spacing={1}
              xs={12}
              sm={3}
              lg={2}
              direction="column"
            >
              <Grid item>
                <Avatar
                  variant="square"
                  className={classes.picture}
                  src={image}
                >
                  <PanoramaIcon />
                </Avatar>
              </Grid>
              <Grid item>
                <FileInput
                  buttonText="Upload Picture"
                  toolTip={newProduct && "You can upload picture after saving."}
                  buttonProps={{
                    fullWidth: true,
                    size: "small",
                    variant: "outlined",
                    disabled: newProduct,
                  }}
                  onSelected={(file) => {
                    dispatch({
                      type: "UPLOAD_PRODUCT_PICTURE",
                      payload: { sku: id, image: file },
                    });
                  }}
                />
              </Grid>
              {image && (
                <Grid item>
                  <Button
                    fullWidth
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={() => setDeleting("picture")}
                  >
                    Remove Picture
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} sm={9} lg={10} container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  placeholder="PCS-ABCD-X8122-001"
                  disabled={saving}
                  autoFocus={newProduct}
                  label="SKU"
                  value={sku}
                  onChange={(e) => {
                    if (!newProduct) return;
                    setSKU(
                      e.target.value.toUpperCase().replace(/[^A-Z0-9-]/g, "")
                    );
                  }}
                  error={sku.length > 50}
                  helperText={
                    sku.length > 50 && "SKU must be less than 50 characters"
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  disabled={saving}
                  autoFocus={!newProduct}
                  placeholder="Product Name"
                  label="Product Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  placeholder="Customer Facing Product Name"
                  label="External Product Name"
                  disabled={saving}
                  value={externalName}
                  onChange={(e) => setExternalName(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  required
                  placeholder="pc/set/dozen"
                  label="Unit"
                  disabled={saving}
                  value={unit}
                  onChange={(e) => setUnit(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <NumberField
                  fullWidth
                  placeholder={`${currency} 123`}
                  label="Unit Cost"
                  disabled={saving}
                  currency={currency}
                  value={costPrice}
                  onChange={(e) => {
                    if (costPrice !== e.target.value)
                      setCostPrice(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <NumberField
                  fullWidth
                  placeholder={`${currency} 123`}
                  label="Selling Price"
                  disabled={saving}
                  currency={currency}
                  value={sellingPrice}
                  onChange={(e) => {
                    if (sellingPrice !== e.target.value)
                      setSellingPrice(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <div className={classes.spacer} />
        {changed && (
          <>
            <Beforeunload
              onBeforeunload={() =>
                "You have unsaved changes. Are you sure? Unsaved changes will lost."
              }
            />
            <Prompt message="You have unsaved changes. Are you sure? Unsaved changes will lost." />
          </>
        )}
      </OverlayScrollbarsComponent>
      <ConfirmDialog
        show={deleting}
        onClose={() => setDeleting(null)}
        onConfirm={() => {
          setDeleting(null);
          dispatch({
            type:
              deleting === "product"
                ? "DELETE_PRODUCT"
                : "REMOVE_PRODUCT_PICTURE",
            payload: { sku: id, name },
          });
        }}
        title={`Deleting ...`}
        message={
          deleting === "product" ? (
            <>
              Are you sure you want to delete <strong>{name}</strong>? This
              action cannot be undone.
            </>
          ) : (
            "Are you sure you want to remove the product's picture? This action cannot be undone."
          )
        }
      />
    </>
  );
}

export default ProductEditor;
