import Promise from "bluebird";
import { push } from "connected-react-router";
import { eventChannel } from "redux-saga";
import { takeEvery, take, put, select } from "redux-saga/effects";
import { fn, fnUpload } from "../../../lib/fn";

function* handleGetUser({ payload }) {
  const { id } = payload;
  if (id === "new") {
    yield put({
      type: "GET_USER_SUCCESS",
      payload: {
        id: "new",
        picture: null,
        email: null,
        name: null,
        parent: null,
        level: "USR",
      },
    });
    return;
  }

  const data = yield fn("get-user", { id });
  if (!data) {
    yield put(push("/users"));
    return;
  }
  data.parent = null;
  if (data.parent_id) {
    data.parent = yield fn("get-user", { id: data.parent_id });
  }

  yield put({
    type: "GET_USER_SUCCESS",
    payload: data,
  });
}

function* handleNewUser() {
  try {
    // give time to the debounced redux updater
    yield Promise.delay(1500);
    const editor = yield select((state) => state.users.editor);
    const { success, id } = yield fn("new-user", {
      name: editor.name,
      email: editor.email,
      level: editor.level,
      parent_id: (editor.parent && editor.parent.id) || null,
    });

    if (!success) {
      yield put({
        type: "NEW_USER_ERROR",
        payload: "Fail to update the database. Please try again later.",
      });
      return;
    }
    yield put({
      type: "GET_USERS",
    });
    yield put({
      type: "NEW_USER_SUCCESS",
      payload: { id: "new" },
    });
    yield put(push(`/user/${id}`));
  } catch (e) {
    console.log(e.response);
    yield put({
      type: "NEW_USER_ERROR",
      payload: e.message || "Unknown Error",
    });
  }
}

function* handleUpdateUser() {
  try {
    // give time to the debounced redux updater
    yield Promise.delay(1500);
    const editor = yield select((state) => state.users.editor);
    const { success } = yield fn("update-user", {
      id: editor.activeUser,
      name: editor.name,
      email: editor.email,
      level: editor.level,
      parent_id: (editor.parent && editor.parent.id) || null,
    });

    if (!success) {
      yield put({
        type: "UPDATE_USER_ERROR",
        payload: "Fail to update the database. Please try again later.",
      });
      return;
    }
    yield put({
      type: "GET_USERS",
    });
    yield put({
      type: "UPDATE_USER_SUCCESS",
      payload: { id: editor.activeUser },
    });
  } catch (e) {
    yield put({
      type: "UPDATE_USER_ERROR",
      payload: e.message || "Unknown Error",
    });
  }
}

function* handleUploadUserPicture({ payload }) {
  const { id } = payload;
  yield put({
    type: "SHOW_LOADING",
    payload: { message: "Uploading ..." },
  });
  const chan = eventChannel((emit) => {
    fnUpload(
      "upload-user-image",
      payload,
      (progress) => emit({ type: "PROGRESS", progress }),
      (data) => emit({ type: "DONE", data }),
      (error) => emit({ type: "ERROR", error })
    );
    return () => {};
  });

  try {
    while (true) {
      const event = yield take(chan);
      if (event.type === "PROGRESS") {
        yield put({
          type: "SHOW_LOADING",
          payload: {
            message:
              event.progress < 100
                ? `(${event.progress.toFixed(2)}%) Uploading ...`
                : "Processing ...",
          },
        });
      } else if (event.type === "DONE") {
        yield put({
          type: "UPLOAD_USER_PICTURE_SUCCESS",
          payload: { id, image: event.data.id },
        });
        return;
      } else {
        throw event.error;
      }
    }
  } catch (e) {
    yield put({
      type: "UPLOAD_USER_PICTURE_ERROR",
      payload: e.message || "Unknown Error",
    });
  } finally {
    chan.close();
    yield put({ type: "HIDE_LOADING" });
  }
}

function* handleRemoveUserPicture({ payload }) {
  const { id } = payload;
  yield put({
    type: "SHOW_LOADING",
    payload: { message: "Removing user image ..." },
  });

  try {
    const { success } = yield fn("remove-user-picture", { id });
    if (!success) {
      throw new Error("Fail to update the database. Please try again later.");
    }
    yield put({
      type: "REMOVE_USER_PICTURE_SUCCESS",
      payload: { id },
    });
  } catch (e) {
    yield put({
      type: "REMOVE_USER_PICTURE_ERROR",
      payload: e.message || "Unknown Error",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}
function* handleDeleteUser({ payload }) {
  const { id, name } = payload;
  yield put({
    type: "SHOW_LOADING",
    payload: { message: `Deleting ${name} ...` },
  });

  try {
    const { success } = yield fn("delete-user", { id });
    if (!success) {
      throw new Error("Fail to update the database. Please try again later.");
    }
    yield put({
      type: "GET_USERS",
    });
    yield put(push("/users"));
  } catch (e) {
    yield put({
      type: "DELETE_USER_ERROR",
      payload: e.message || "Unknown Error",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* usersEditorSaga() {
  yield takeEvery("GET_USER", handleGetUser);
  yield takeEvery("NEW_USER", handleNewUser);
  yield takeEvery("UPDATE_USER", handleUpdateUser);
  yield takeEvery("DELETE_USER", handleDeleteUser);
  yield takeEvery("UPLOAD_USER_PICTURE", handleUploadUserPicture);
  yield takeEvery("REMOVE_USER_PICTURE", handleRemoveUserPicture);
}

export { usersEditorSaga };
