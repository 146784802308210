import React from "react";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";

function Step3({ processing }) {
  return (
    <Grid
      container
      item
      xs={12}
      direction="column"
      spacing={2}
      alignContent="center"
      alignItems="center"
    >
      <Grid item>
        {processing ? (
          <CircularProgress />
        ) : (
          <CheckCircleIcon fontSize="large" color="primary" />
        )}
      </Grid>
      <Grid item>
        {processing
          ? "Activating your account ..."
          : "Success! Redirecting you to the login page ..."}
      </Grid>
    </Grid>
  );
}

export default Step3;
