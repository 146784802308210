import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

import Copyright from "../../components/Copyright";
import ContentLoading from "../../components/ContentLoading";
import useQuery from "../../hooks/useQuery";
import useInputRedux from "../../hooks/useInputRedux";
import useStyles from "./styles";

import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";

function Onboarding() {
  const classes = useStyles();
  const query = useQuery();
  const dispatch = useDispatch();

  const [passwordError, setPasswordError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [step, setStep] = useState(1);

  const error = useSelector((state) => state.onboarding.error);
  const userId = useSelector((state) => state.onboarding.userId);
  const loading = useSelector((state) => state.onboarding.loading);
  const processing = useSelector((state) => state.onboarding.processing);
  const companyName = useSelector((state) => state.onboarding.companyName);
  const companyLogo = useSelector((state) => state.onboarding.companyLogo);
  const level = useSelector((state) => state.onboarding.level);
  const picture = useSelector((state) => state.onboarding.picture);

  const [name, setName] = useInputRedux(["onboarding", "name"], (value) => ({
    type: "SET_ONBOARDING_DETAIL",
    payload: { field: "name", value },
  }));
  const [email, setEmail] = useInputRedux(["onboarding", "email"], (value) => ({
    type: "SET_ONBOARDING_DETAIL",
    payload: { field: "email", value },
  }));
  const [password, setPassword] = useInputRedux(
    ["onboarding", "password"],
    (value) => ({
      type: "SET_ONBOARDING_DETAIL",
      payload: { field: "password", value },
    })
  );
  const [passwordConfirm, setPasswordConfirm] = useInputRedux(
    ["onboarding", "passwordConfirm"],
    (value) => ({
      type: "SET_ONBOARDING_DETAIL",
      payload: { field: "passwordConfirm", value },
    })
  );

  const auth = query.get("auth");
  useEffect(() => {
    dispatch({ type: "GET_ONBOARDING_DETAILS", payload: { auth } });
  }, [auth]);

  const handleUploadPicture = (file) => {
    dispatch({
      type: "UPLOAD_ONBOARDING_PICTURE",
      payload: { auth, userId, image: file },
    });
  };
  const handleClickPrev = () => {
    if (step > 1) setStep(step - 1);
  };
  const handleClickNext = () => {
    let error = false;
    if (step === 1) {
      setEmailError(null);
      setNameError(null);
      setPasswordError(null);
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!re.test(email.toLowerCase())) {
        setEmailError("Please provide a valid email address.");
        error = true;
      }

      if (name.trim().length === 0) {
        setNameError("Name is required.");
        error = true;
      }

      if (password.length < 6) {
        setPasswordError("Minimum password length is 6");
        error = true;
      }
      if (password !== passwordConfirm) {
        setPasswordError("Password didn't match!");
        error = true;
      }
    }

    if (step === 2) {
      dispatch({
        type: "ACTIVATE_ACCOUNT",
        payload: { auth },
      });
    }

    if (error) return;
    setStep(step + 1);
  };

  if (loading) return <ContentLoading />;
  return (
    <OverlayScrollbarsComponent className={classes.main}>
      <Grid container justify="center">
        <Grid item xs={12} sm={8} md={6}>
          {companyLogo && (
            <div className={classes.logo}>
              <img src={companyLogo} />
            </div>
          )}
          <Paper className={classes.paper}>
            <AppBar
              component="div"
              className={classes.appBar}
              color="paper"
              position="static"
              elevation={0}
            >
              <Toolbar className={classes.toolbar}>
                <Typography variant="h5" component="div">
                  Welcome to {companyName}!
                </Typography>
              </Toolbar>
            </AppBar>
            <Divider />
            <Grid container className={classes.content} spacing={2}>
              {error && (
                <Grid item xs={12}>
                  <Alert severity="error">
                    {error}{" "}
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      Reload
                    </Button>
                  </Alert>
                </Grid>
              )}
              {step === 1 && (
                <Step1
                  name={name}
                  setName={setName}
                  email={email}
                  setEmail={setEmail}
                  password={password}
                  setPassword={setPassword}
                  passwordConfirm={passwordConfirm}
                  setPasswordConfirm={setPasswordConfirm}
                  passwordError={passwordError}
                  emailError={emailError}
                  nameError={nameError}
                />
              )}
              {step === 2 && (
                <Step2 picture={picture} onUpload={handleUploadPicture} />
              )}
              {step === 3 && <Step3 processing={processing} />}
            </Grid>
            <Divider />
            <Grid
              container
              className={classes.actions}
              spacing={2}
              direction="row-reverse"
            >
              <Button
                onClick={handleClickNext}
                disabled={step === 3}
                color="primary"
              >
                Next
              </Button>
              <Button
                onClick={handleClickPrev}
                disabled={step === 1 || step === 3}
              >
                Previous
              </Button>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Copyright />
        </Grid>
      </Grid>
    </OverlayScrollbarsComponent>
  );
}

export default Onboarding;
