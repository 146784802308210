import React, { useState } from "react";
import { GooSpinner } from "react-spinners-kit";
import { useDispatch, useSelector } from "react-redux";
import { Beforeunload } from "react-beforeunload";
import { Prompt } from "react-router";
import Alert from "@material-ui/lab/Alert";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import SaveIcon from "@material-ui/icons/Save";
import PanoramaIcon from "@material-ui/icons/Panorama";

import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

import useInputRedux from "../../../../hooks/useInputRedux";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import ContentLoading from "../../../../components/ContentLoading";
import FileInput from "../../../../components/FileInput";
import useStyles from "./style";

function UserProfile() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(false);
  const [nameError, setNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState(null);

  const error = useSelector((state) => state.settings.profile.error);
  const loading = useSelector((state) => state.settings.profile.loading);
  const changed = useSelector((state) => state.settings.profile.changed);
  const saving = useSelector((state) => state.settings.profile.saving);

  const picture = useSelector((state) => state.settings.profile.picture);
  const [email, setEmail] = useInputRedux(
    ["settings", "profile", "email"],
    (value) => ({
      type: "SET_SETTING_PROFILE_DETAIL",
      payload: { field: "email", value },
    })
  );
  const [name, setName] = useInputRedux(
    ["settings", "profile", "name"],
    (value) => ({
      type: "SET_SETTING_PROFILE_DETAIL",
      payload: { field: "name", value },
    })
  );
  const [password, setPassword] = useInputRedux(
    ["settings", "profile", "password"],
    (value) => ({
      type: "SET_SETTING_PROFILE_DETAIL",
      payload: { field: "password", value },
    })
  );
  const [confirmPassword, setConfirmPassword] = useInputRedux(
    ["settings", "profile", "confirmPassword"],
    (value) => ({
      type: "SET_SETTING_PROFILE_DETAIL",
      payload: { field: "confirmPassword", value },
    })
  );

  const validateAndSubmit = () => {
    let error = false;
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(email.toLowerCase())) {
      setEmailError("Please provide a valid email address.");
      error = true;
    }
    if (name.trim().length === 0) {
      setNameError("Name is required.");
      error = true;
    }
    if (password !== confirmPassword) {
      setPasswordError("Password didn't match.");
      setConfirmPasswordError("Password didn't match.");
      error = true;
    }
    if (password.length !== 0 && password.length < 6) {
      setPasswordError("Minimum password length is 6 characters.");
      error = true;
    }
    if (error) return;
    dispatch({
      type: "UPDATE_SETTING_PROFILE",
    });
  };

  if (loading) return <ContentLoading />;

  return (
    <>
      <OverlayScrollbarsComponent className={classes.main}>
        <Paper className={classes.paper}>
          <AppBar
            component="div"
            className={classes.appBar}
            color="paper"
            position="static"
            elevation={0}
          >
            <Toolbar className={classes.toolbar}>
              <Typography
                variant="h5"
                id="tableTitle"
                component="div"
                className={classes.toolbarTitle}
              >
                {changed && (
                  <Badge badgeContent={"Unsaved"} color="error">
                    Your Account
                  </Badge>
                )}
                {!changed && "Your Account"}
              </Typography>
              {saving && (
                <div className={classes.loading}>
                  <GooSpinner size={24} color="#000" />
                </div>
              )}
              {!saving && (
                <IconButton
                  color="inherit"
                  disabled={!changed}
                  onClick={validateAndSubmit}
                >
                  <SaveIcon />
                </IconButton>
              )}
            </Toolbar>
          </AppBar>
          <Divider />
          <Grid container className={classes.content} spacing={2}>
            {error && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            <Grid
              container
              item
              spacing={1}
              xs={12}
              sm={3}
              lg={2}
              direction="column"
            >
              <Grid item>
                <Avatar
                  variant="square"
                  className={classes.picture}
                  src={picture}
                >
                  <PanoramaIcon />
                </Avatar>
              </Grid>
              <Grid item>
                <FileInput
                  buttonText="Upload Picture"
                  buttonProps={{
                    fullWidth: true,
                    size: "small",
                    variant: "outlined",
                  }}
                  onSelected={(file) => {
                    dispatch({
                      type: "UPLOAD_SETTING_PROFILE_PICTURE",
                      payload: { image: file },
                    });
                  }}
                />
              </Grid>
              {picture && (
                <Grid item>
                  <Button
                    fullWidth
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={() => setDeleting(true)}
                  >
                    Remove Picture
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} sm={9} lg={10} container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  autoFocus
                  placeholder="name@company.com"
                  disabled={saving}
                  label="Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError(null);
                  }}
                  error={!!emailError}
                  helperText={emailError}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  disabled={saving}
                  placeholder="Name"
                  label="Full Name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameError(null);
                  }}
                  error={!!nameError}
                  helperText={nameError}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled={saving}
                  type="password"
                  autoComplete="new-password"
                  placeholder="Enter new password (optional)"
                  label="New Password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordError(null);
                    setConfirmPasswordError(null);
                  }}
                  error={!!passwordError}
                  helperText={passwordError}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled={saving}
                  type="password"
                  autoComplete="new-password"
                  placeholder="Confirm your new password"
                  label="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setPasswordError(null);
                    setConfirmPasswordError(null);
                  }}
                  error={!!confirmPasswordError}
                  helperText={confirmPasswordError}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <div className={classes.spacer} />
        {changed && (
          <>
            <Beforeunload
              onBeforeunload={() =>
                "You have unsaved changes. Are you sure? Unsaved changes will lost."
              }
            />
            <Prompt message="You have unsaved changes. Are you sure? Unsaved changes will lost." />
          </>
        )}
      </OverlayScrollbarsComponent>
      <ConfirmDialog
        show={deleting}
        onClose={() => setDeleting(false)}
        onConfirm={() => {
          setDeleting(false);
          dispatch({
            type: "REMOVE_SETTING_PROFILE_PICTURE",
          });
        }}
        title="Deleting ..."
        message="Are you sure you want to remove your profile picture? This action cannot be undone."
      />
    </>
  );
}

export default UserProfile;
