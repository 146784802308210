// see: https://material-ui.com/components/text-fields/#integration-with-3rd-party-input-libraries
import React from "react";
import Editor from "react-simple-code-editor";
import TextField from "@material-ui/core/TextField";

import highlight from "../../lib/highlighter";
import useStyles from "./style-editor";

function CodeEditor({ inputId, ...other }) {
  return <Editor textareaId={inputId} {...other} />;
}

function Base({
  required,
  error,
  helperText,
  label,
  disabled,
  placeholder,
  variant,
  InputLabelProps,
  message,
  setMessage,
  inputId,
}) {
  const classes = useStyles();

  return (
    <TextField
      fullWidth
      multiline
      required={required}
      error={error}
      helperText={helperText}
      label={label}
      className={classes.editor}
      disabled={disabled}
      placeholder={placeholder}
      variant={variant}
      InputLabelProps={InputLabelProps}
      InputProps={{
        inputComponent: CodeEditor,
        inputProps: {
          value: message,
          onValueChange: (e) => {
            if (setMessage) setMessage(e);
          },
          style: {
            minHeight: "200px",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontSize: 12,
          },
          inputId: inputId,
          highlight,
        },
      }}
    />
  );
}

export default Base;
