import React, { useRef, useState, useEffect } from "react";
import * as uuid from "uuid";
import { useDispatch, useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";

import getTitle from "../../lib/template-title";

import Base from "./Base";
import useStyle from "./style-modal";

const actions = {
  message: "GET_SETTING_TEMPLATE_MESSAGE",
  delivery: "GET_SETTING_TEMPLATE_DELIVERY",
  payment: "GET_SETTING_TEMPLATE_PAYMENT",
};

function LoadModal({ show, template, onLoad, onClose }) {
  const inputId = useRef(uuid.v4());
  const classes = useStyle();
  const dispatch = useDispatch();
  const [selectedId, setSelectedId] = useState(null);
  const activeTemplateType = useSelector(
    (state) => state.settings.template.activeTemplateType
  );
  const loadingTemplates = useSelector(
    (state) => state.settings.template.loading
  );
  const loadingContent = useSelector(
    (state) => state.settings.template.loadingContent
  );
  const templates = useSelector((state) => state.settings.template.templates);
  const title = useSelector((state) => state.settings.template.title);
  const content = useSelector((state) => state.settings.template.content);
  const loading = loadingTemplates || activeTemplateType !== template;

  useEffect(() => {
    if (template && show) {
      setSelectedId(null);
      dispatch({ type: actions[template] });
    }
  }, [template, show]);

  useEffect(() => {
    if (selectedId) {
      dispatch({
        type: "GET_TEMPLATE",
        payload: { id: selectedId, type: template },
      });
    }
  }, [selectedId]);

  if (!template) return null;

  return (
    <Dialog
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      classes={classes}
      open={show}
    >
      <DialogTitle disableTypography>
        <Typography variant="h6">{getTitle(template)}</Typography>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel shrink id="template-selector-label">
                Select {getTitle(template)}
              </InputLabel>
              {loading && (
                <Select value="1" disabled>
                  <MenuItem value="1" selected>
                    Loading ...
                  </MenuItem>
                </Select>
              )}
              {!loading && (
                <Select
                  labelId="template-selector-label"
                  id="template-selector-select"
                  value={selectedId || ""}
                  onChange={(e) => setSelectedId(e.target.value)}
                  MenuProps={{
                    PopoverClasses: {
                      root: classes.menu,
                    },
                  }}
                >
                  {templates.map((template) => (
                    <MenuItem key={template.id} value={template.id}>
                      {template.title}
                    </MenuItem>
                  ))}
                </Select>
              )}
              <FormHelperText>&nbsp;</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled
              placeholder={loading || loadingContent ? "Loading ..." : ""}
              label="Template Title"
              value={title || ""}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Base
              disabled
              placeholder={loading || loadingContent ? "Loading ..." : ""}
              label="Content"
              message={content || ""}
              inputId={inputId.current}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={!selectedId}
          onClick={() => onLoad(content || "")}
          color="primary"
        >
          Load
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LoadModal;
