const update = require("immutability-helper");

const initialState = {
  error: null,
  loading: true,
  loadingContent: false,
  saving: false,
  activeTemplateType: null,
  activeTemplate: null,
  changed: false,
  templates: [],

  title: null,
  content: null,
};

function message(state = initialState, action) {
  switch (action.type) {
    case "GET_SETTING_TEMPLATE_MESSAGE": {
      return update(state, {
        error: {
          $set: null,
        },
        title: {
          $set: null,
        },
        content: {
          $set: null,
        },
        loading: {
          $set: true,
        },
        changed: {
          $set: false,
        },
        activeTemplateType: {
          $set: "message",
        },
        activeTemplate: {
          $set: null,
        },
      });
    }
    case "GET_SETTING_TEMPLATE_DELIVERY": {
      return update(state, {
        error: {
          $set: null,
        },
        title: {
          $set: null,
        },
        content: {
          $set: null,
        },
        loading: {
          $set: true,
        },
        changed: {
          $set: false,
        },
        activeTemplateType: {
          $set: "delivery",
        },
        activeTemplate: {
          $set: null,
        },
      });
    }
    case "GET_SETTING_TEMPLATE_PAYMENT": {
      return update(state, {
        error: {
          $set: null,
        },
        title: {
          $set: null,
        },
        content: {
          $set: null,
        },
        loading: {
          $set: true,
        },
        changed: {
          $set: false,
        },
        activeTemplateType: {
          $set: "payment",
        },
        activeTemplate: {
          $set: null,
        },
      });
    }

    // generics:
    case "GET_SETTING_TEMPLATE_LIST_SUCCESS": {
      const { templates, type } = action.payload;
      if (state.activeTemplateType !== type) return state;
      return update(state, {
        loading: {
          $set: false,
        },
        templates: {
          $set: templates,
        },
      });
    }
    case "GET_TEMPLATE": {
      const { id } = action.payload;
      return update(state, {
        error: {
          $set: null,
        },
        loadingContent: {
          $set: true,
        },
        changed: {
          $set: false,
        },
        activeTemplate: {
          $set: id,
        },
      });
    }
    case "SET_TEMPLATE_DETAIL": {
      const { field, value, id } = action.payload;
      if (state.activeTemplate !== id) return state;

      return update(state, {
        changed: {
          $set: true,
        },
        error: {
          $set: null,
        },
        [field]: {
          $set: value,
        },
      });
    }
    case "GET_TEMPLATE_SUCCESS": {
      const { id, title, content } = action.payload;
      if (state.activeTemplate !== id) return state;
      return update(state, {
        loadingContent: {
          $set: false,
        },
        title: {
          $set: title,
        },
        content: {
          $set: content,
        },
      });
    }
    case "DELETE_TEMPLATE_SUCCESS": {
      const { id } = action.payload;
      return update(state, {
        templates: (templates) =>
          templates.filter((template) => template.id !== id),
      });
    }
    case "NEW_TEMPLATE":
    case "UPDATE_TEMPLATE": {
      return update(state, {
        error: {
          $set: null,
        },
        saving: {
          $set: true,
        },
      });
    }
    case "NEW_TEMPLATE_SUCCESS": {
      return update(state, {
        templates: {
          $push: [action.payload],
        },
        changed: {
          $set: false,
        },
        saving: {
          $set: false,
        },
      });
    }
    case "UPDATE_TEMPLATE_SUCCESS": {
      return update(state, {
        changed: {
          $set: false,
        },
        saving: {
          $set: false,
        },
      });
    }
    case "NEW_TEMPLATE_ERROR":
    case "UPDATE_TEMPLATE_ERROR": {
      return update(state, {
        saving: {
          $set: false,
        },
        error: {
          $set: action.payload,
        },
      });
    }
    default:
      return state;
  }
}

export default message;
