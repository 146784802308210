const update = require("immutability-helper");
const uuid = require("uuid");

const initialState = {
  error: null,
  loading: false,
  saving: false,
  sending: false,
  sendingSteps: [],
  showSend: false,
  sendError: null,
  shareError: null,
  showShare: false,
  sharingLoading: false,
  activeQuote: null,
  changed: false,
  details: null,
  status: null,
  items: [],
  acl: null,
  audit: null,
  aclLoading: true,
  auditLoading: true,
  summary: {
    subtotal: 0,
  },
  recipients: [],
};

function calculateSummary(items) {
  const subtotal = items
    .filter((item) => item.sku)
    .map((item) => item.qty * item.unit)
    .reduce((p, c) => p + c, 0);
  return { subtotal };
}

function quotes(state = initialState, action) {
  switch (action.type) {
    case "SEND_QUOTE_SUCCESS": {
      return update(state, {
        sending: {
          $set: false,
        },
      });
    }
    case "SEND_QUOTE_ERROR": {
      return update(state, {
        sendError: {
          $set: action.payload,
        },
      });
    }
    case "SEND_QUOTE": {
      return update(state, {
        sending: {
          $set: true,
        },
      });
    }
    case "UPDATE_SENDING_STEPS": {
      return update(state, {
        sendingSteps: {
          $set: action.payload,
        },
      });
    }
    case "UPDATE_SENDING_STEP_PROGRESS": {
      const { idx, status } = action.payload;
      return update(state, {
        sendingSteps: (sendingSteps) =>
          sendingSteps.map((old, i) => {
            if (i !== idx) return old;
            return { ...old, status };
          }),
      });
    }
    case "SHOW_SEND_QUOTE": {
      return update(state, {
        sendError: {
          $set: null,
        },
        showSend: {
          $set: true,
        },
      });
    }
    case "SEND_QUOTE_DONE": {
      return update(state, {
        showSend: {
          $set: false,
        },
      });
    }
    case "CANCEL_SEND": {
      return update(state, {
        showSend: {
          $set: false,
        },
      });
    }
    case "SHOW_SHARE": {
      return update(state, {
        shareError: {
          $set: null,
        },
        showShare: {
          $set: true,
        },
      });
    }
    case "HIDE_SHARE": {
      return update(state, {
        showShare: {
          $set: false,
        },
      });
    }
    case "SHARE_QUOTE": {
      return update(state, {
        shareError: {
          $set: null,
        },
        sharingLoading: {
          $set: true,
        },
      });
    }
    case "SHARE_QUOTE_SUCCESS": {
      return update(state, {
        sharingLoading: {
          $set: false,
        },
        showShare: {
          $set: false,
        },
      });
    }
    case "SHARE_QUOTE_ERROR": {
      return update(state, {
        sharingLoading: {
          $set: false,
        },
        shareError: {
          $set: action.payload,
        },
      });
    }
    case "GET_QUOTE": {
      const { id } = action.payload;
      return update(state, {
        error: {
          $set: null,
        },
        loading: {
          $set: true,
        },
        changed: {
          $set: false,
        },
        activeQuote: {
          $set: id,
        },
      });
    }
    case "GET_QUOTE_ACL": {
      return update(state, {
        aclLoading: {
          $set: true,
        },
      });
    }
    case "GET_QUOTE_AUDIT": {
      return update(state, {
        auditLoading: {
          $set: true,
        },
      });
    }
    case "SET_QUOTE_RECIPIENTS": {
      return update(state, {
        changed: {
          $set: true,
        },
        recipients: {
          $set: action.payload,
        },
      });
    }
    case "SET_QUOTE_DETAIL": {
      const { field, value, id } = action.payload;
      if (state.activeQuote !== id) return state;

      return update(state, {
        changed: {
          $set: true,
        },
        details: {
          [field]: {
            $set: value,
          },
        },
      });
    }
    case "SET_QUOTE_ITEM_ORDER": {
      return update(state, {
        changed: {
          $set: true,
        },
        items: {
          $set: action.payload,
        },
      });
    }
    case "UPDATE_QUOTE_ITEM_DETAIL": {
      const { index, item } = action.payload;
      const summary = calculateSummary(state.items);

      if (item.sku) {
        summary.subtotal -= state.items[index].qty * state.items[index].unit;
        summary.subtotal += item.qty * item.unit;
      }

      return update(state, {
        changed: {
          $set: true,
        },
        items: {
          [index]: { $set: item },
        },
        summary: {
          $set: summary,
        },
      });
    }
    case "REMOVE_QUOTE_ITEM": {
      const { ref } = action.payload;
      const items = state.items.filter((item) => item.ref !== ref);
      const summary = calculateSummary(items);
      return update(state, {
        changed: {
          $set: true,
        },
        items: { $set: items },
        summary: { $set: summary },
      });
    }
    case "ADD_QUOTE_ITEM": {
      const { type } = action.payload;
      return update(state, {
        changed: {
          $set: true,
        },
        items: {
          $push:
            type === "separator"
              ? [{ ref: uuid.v4(), sku: null, txt: null }]
              : [
                  {
                    ref: uuid.v4(),
                    sku: "",
                    name: "",
                    qty: 1,
                    unit: 0,
                  },
                ],
        },
      });
    }
    case "GET_QUOTE_SUCCESS": {
      const {
        id,
        title,
        recipients,
        message,
        payment_details,
        delivery_details,
        items,
        original_quote,
        status,
        discount,
        created_at,
        modified_at,
      } = action.payload;
      if (state.activeQuote !== id) return state;
      return update(state, {
        loading: {
          $set: false,
        },
        items: {
          $set: items.map((item) => ({
            ref: uuid.v4(),
            ...item,
          })),
        },
        status: {
          $set: status,
        },
        recipients: {
          $set: recipients,
        },
        details: {
          $set: {
            title,
            message,
            originalQuote: original_quote,
            paymentDetails: payment_details,
            deliveryDetails: delivery_details,
            discount: discount,
            createdAt: created_at,
            modifiedAt: modified_at,
          },
        },
        summary: {
          $set: calculateSummary(items),
        },
      });
    }
    case "GET_QUOTE_ACL_SUCCESS": {
      const { id, data } = action.payload;
      if (state.activeQuote !== id) return state;
      return update(state, {
        aclLoading: {
          $set: false,
        },
        acl: {
          $set: data,
        },
      });
    }
    case "GET_QUOTE_AUDIT_SUCCESS": {
      const { id, data } = action.payload;
      if (state.activeQuote !== id) return state;
      return update(state, {
        auditLoading: {
          $set: false,
        },
        audit: {
          $set:
            data &&
            data.map((item) => ({
              ...item,
              created_at: new Date(item.created_at).toLocaleString(undefined, {
                dateStyle: "long",
                timeStyle: "short",
              }),
            })),
        },
      });
    }
    case "UPDATE_QUOTE": {
      return update(state, {
        error: {
          $set: null,
        },
        saving: {
          $set: true,
        },
      });
    }
    case "UPDATE_QUOTE_SUCCESS": {
      return update(state, {
        changed: {
          $set: false,
        },
        saving: {
          $set: false,
        },
      });
    }
    case "NEW_QUOTE_ERROR":
    case "COPY_QUOTE_ERROR":
    case "DELETE_QUOTE_ERROR":
    case "UPDATE_QUOTE_ERROR": {
      return update(state, {
        saving: {
          $set: false,
        },
        error: {
          $set: action.payload,
        },
      });
    }
    default:
      return state;
  }
}

export default quotes;
