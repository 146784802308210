import React, { useState, useRef } from "react";
import * as uuid from "uuid";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import HelpIcon from "@material-ui/icons/HelpOutline";

import Base from "./Base";
import HelpModal from "./HelpModal";
import LoadModal from "./LoadModal";
import useStyles from "./style-editor";

function Editor({
  message,
  setMessage,
  template,
  inputProps,
  disabled,
  ...other
}) {
  const inputId = useRef(uuid.v4());
  const editorRef = useRef(null);
  const classes = useStyles();
  const [showHelp, setShowHelp] = useState(false);
  const [showLoad, setShowLoad] = useState(false);
  const placeholder = (inputProps && inputProps.placeholder) || "Message";
  const variant = (inputProps && inputProps.variant) || "outlined";
  const label = (inputProps && inputProps.label) || undefined;
  const required = (inputProps && inputProps.required) || undefined;
  const error = (inputProps && inputProps.error) || undefined;
  const helperText = (inputProps && inputProps.helperText) || undefined;
  const InputLabelProps =
    (inputProps && inputProps.InputLabelProps) || undefined;

  if (!editorRef.current) {
    editorRef.current = document.getElementById(inputId.current);
  }

  const onHelpInsertText = (text) => {
    if (!editorRef.current) return;
    const start = editorRef.current.selectionStart;
    const end = editorRef.current.selectionEnd;
    if (start || start === 0) {
      setMessage(
        `${message.substring(0, start)}${text}${message.substring(
          end,
          message.length
        )}`
      );
    } else {
      setMessage(`${message} ${text}`);
    }
    setShowHelp(false);
  };

  return (
    <Grid item className={classes.floatingContainer} {...other}>
      <Base
        required={required}
        error={error}
        helperText={helperText}
        label={label}
        disabled={disabled}
        placeholder={placeholder}
        variant={variant}
        InputLabelProps={InputLabelProps}
        message={message}
        setMessage={setMessage}
        inputId={inputId.current}
      />
      <Grid container className={classes.floating}>
        <Grid item>
          <Tooltip title="Help">
            <IconButton onClick={() => setShowHelp(true)}>
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        {template && (
          <Grid item>
            <Tooltip title="Load from template">
              <IconButton onClick={() => setShowLoad(true)}>
                <ImportContactsIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
      <HelpModal
        show={showHelp}
        onInsert={onHelpInsertText}
        onClose={() => setShowHelp(false)}
      />
      <LoadModal
        template={template}
        show={showLoad}
        onLoad={(content) => {
          setMessage(content);
          setShowLoad(false);
        }}
        onClose={() => setShowLoad(false)}
      />
    </Grid>
  );
}

export default Editor;
