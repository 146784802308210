import React from "react";
import { useHistory } from "react-router-dom";
import TimeAgo from "react-timeago";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import useStyles from "./style";

function QuoteListItem({
  selected,
  quoteId,
  quoteTitle,
  quoteStatus,
  lastModifiedBy,
  lastModified,
}) {
  const history = useHistory();
  const classes = useStyles();

  return (
    <ListItem
      selected={selected}
      key={quoteId}
      component="a"
      button
      href={`/quote/${quoteId}`}
      onClick={(e) => {
        e.preventDefault();
        history.push(`/quote/${quoteId}`);
      }}
    >
      <ListItemText
        primary={
          <Grid container>
            <Grid item xs>
              <Typography variant="subtitle2" noWrap className={classes.title}>
                #{quoteId}
                {quoteTitle && ` - ${quoteTitle}`}
              </Typography>
            </Grid>
            {quoteStatus === "DRAFT" && (
              <Grid item>
                <Chip
                  size="small"
                  label={<Typography variant="overline">Draft</Typography>}
                />
              </Grid>
            )}
            {quoteStatus === "UNSAVED" && (
              <Grid item>
                <Chip
                  size="small"
                  label={<Typography variant="overline">Unsaved</Typography>}
                />
              </Grid>
            )}
          </Grid>
        }
        secondary={
          <Typography variant="caption">
            {lastModifiedBy} (
            <TimeAgo date={lastModified} />)
          </Typography>
        }
      />
    </ListItem>
  );
}

export default QuoteListItem;
