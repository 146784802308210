import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";
import useStyle from "./styles";

function ImportDialog({
  show,
  name,
  itemUnits,
  importType,
  processing,
  uploadProgress,
  templateURL,
  onFileSelected,
  onImportTypeSelected,
  importResult,
  step,
  error,
  setStep,
  onConfirm,
  onClose,
}) {
  const classes = useStyle();
  const [activeFile, setActiveFile] = useState(0);

  const handleClose = () => !processing && onClose();

  return (
    <Dialog
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      classes={classes}
      open={show}
    >
      <DialogTitle disableTypography onClose={handleClose}>
        <Typography variant="h6">Import {name}</Typography>
        {!processing && (
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent dividers>
        {step === 1 && <Step1 name={name} templateURL={templateURL} />}
        {step === 2 && (
          <Step2
            importType={importType}
            itemUnits={itemUnits}
            onImportTypeSelected={onImportTypeSelected}
          />
        )}
        {step === 3 && (
          <Step3
            onFileSelected={(files) => {
              setActiveFile(files.length);
              onFileSelected(files);
            }}
          />
        )}
        {step === 4 && (
          <Step4
            processing={processing}
            uploadPercentage={uploadProgress}
            importResult={importResult}
            itemUnits={itemUnits}
            error={error}
          />
        )}
      </DialogContent>
      <DialogActions>
        {step <= 3 && (
          <>
            <Button onClick={() => setStep(step - 1)} disabled={step === 1}>
              Previous
            </Button>
            <Button
              onClick={() => {
                if (step === 3) return onConfirm();
                setStep(step + 1);
              }}
              disabled={step >= 3 && activeFile === 0}
              color="primary"
            >
              {step === 3 ? "Confirm" : "Next"}
            </Button>
          </>
        )}
        {step === 4 && (
          <Button disabled={processing} onClick={handleClose}>
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default ImportDialog;
