import { getImageURL } from "../../../lib/image";
const update = require("immutability-helper");

const initialState = {
  error: null,
  loading: false,
  saving: false,
  activeUser: null,
  changed: false,

  picture: null,
  email: null,
  name: null,
  parent: null,
  level: null,
};

function users(state = initialState, action) {
  switch (action.type) {
    case "GET_USER": {
      const { id } = action.payload;
      return update(state, {
        error: {
          $set: null,
        },
        loading: {
          $set: true,
        },
        changed: {
          $set: false,
        },
        activeUser: {
          $set: id,
        },
      });
    }
    case "SET_USER_DETAIL": {
      const { field, value, id } = action.payload;
      if (state.activeUser !== id) return state;

      return update(state, {
        changed: {
          $set: true,
        },
        error: {
          $set: null,
        },
        [field]: {
          $set: value,
        },
      });
    }
    case "REMOVE_USER_PICTURE_SUCCESS": {
      const { id } = action.payload;
      if (state.activeUser !== id) return state;
      return update(state, {
        picture: {
          $set: null,
        },
      });
    }
    case "UPLOAD_USER_PICTURE": {
      return update(state, {
        error: {
          $set: null,
        },
      });
    }
    case "UPLOAD_USER_PICTURE_SUCCESS": {
      const { id, image } = action.payload;
      if (state.activeUser !== id) return state;
      return update(state, {
        picture: {
          $set: getImageURL(image),
        },
      });
    }
    case "GET_USER_SUCCESS": {
      const { id, picture, email, name, parent, level } = action.payload;
      if (state.activeUser !== id) return state;
      return update(state, {
        loading: {
          $set: false,
        },
        email: {
          $set: email,
        },
        name: {
          $set: name,
        },
        picture: {
          $set: picture && getImageURL(picture),
        },
        parent: {
          $set: parent,
        },
        level: {
          $set: level,
        },
      });
    }
    case "NEW_USER":
    case "UPDATE_USER": {
      return update(state, {
        error: {
          $set: null,
        },
        saving: {
          $set: true,
        },
      });
    }
    case "NEW_USER_SUCCESS":
    case "UPDATE_USER_SUCCESS": {
      return update(state, {
        changed: {
          $set: false,
        },
        saving: {
          $set: false,
        },
      });
    }
    case "NEW_USER_ERROR":
    case "UPDATE_USER_ERROR":
    case "DELETE_USER_ERROR":
    case "REMOVE_USER_PICTURE_ERROR":
    case "UPLOAD_USER_PICTURE_ERROR": {
      return update(state, {
        saving: {
          $set: false,
        },
        error: {
          $set: action.payload,
        },
      });
    }
    default:
      return state;
  }
}

export default users;
