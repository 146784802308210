const update = require("immutability-helper");

const initialState = {
  error: null,
  loading: true,
  saving: false,
  changed: false,

  inventory: null,
  selling: null,
  rate: null,
  taxRate: null,
};

function currency(state = initialState, action) {
  switch (action.type) {
    case "GET_SETTING_CURRENCY": {
      return update(state, {
        error: {
          $set: null,
        },
        loading: {
          $set: true,
        },
        changed: {
          $set: false,
        },
      });
    }
    case "SET_SETTING_CURRENCY_DETAIL": {
      const { field, value } = action.payload;
      return update(state, {
        changed: {
          $set: true,
        },
        error: {
          $set: null,
        },
        [field]: {
          $set: value,
        },
      });
    }
    case "GET_SETTING_CURRENCY_SUCCESS": {
      const { inventory, selling, rate, tax_rate } = action.payload;
      return update(state, {
        loading: {
          $set: false,
        },
        inventory: {
          $set: inventory,
        },
        selling: {
          $set: selling,
        },
        rate: {
          $set: rate,
        },
        taxRate: {
          $set: tax_rate,
        },
      });
    }
    case "UPDATE_SETTING_CURRENCY": {
      return update(state, {
        error: {
          $set: null,
        },
        saving: {
          $set: true,
        },
      });
    }
    case "UPDATE_SETTING_CURRENCY_SUCCESS": {
      return update(state, {
        changed: {
          $set: false,
        },
        saving: {
          $set: false,
        },
      });
    }
    case "UPDATE_SETTING_CURRENCY_ERROR": {
      return update(state, {
        saving: {
          $set: false,
        },
        error: {
          $set: action.payload,
        },
      });
    }
    default:
      return state;
  }
}

export default currency;
