import Promise from "bluebird";
import { push } from "connected-react-router";
import { takeEvery, put, select } from "redux-saga/effects";
import { fn } from "../../../lib/fn";

function createGetTemplateHandler(type) {
  return function* handleGetMessageTemplates() {
    const templates = yield fn("get-templates", { type });
    yield put({
      type: "GET_SETTING_TEMPLATE_LIST_SUCCESS",
      payload: { templates, type },
    });
  };
}

function* handleGetTemplateDetails({ payload }) {
  const { id, type } = payload;
  if (id === "new") {
    yield put({
      type: "GET_TEMPLATE_SUCCESS",
      payload: {
        id: "new",
        title: "",
        content: "",
      },
    });
    return;
  }

  const data = yield fn("get-template", { id, type });
  if (!data) {
    yield put(push("/setting/message"));
    return;
  }

  yield put({
    type: "GET_TEMPLATE_SUCCESS",
    payload: data,
  });
}

function* handleNewTemplate() {
  try {
    // give time to the debounced redux updater
    yield Promise.delay(1500);
    const editor = yield select((state) => state.settings.template);
    const { id, success } = yield fn("new-template", {
      title: editor.title,
      content: editor.content,
      type: editor.activeTemplateType,
    });

    if (!success) {
      throw new Error("Fail to update the database. Please try again later.");
    }
    yield put({
      type: "NEW_TEMPLATE_SUCCESS",
      payload: { id, title: editor.title },
    });
    yield put(push(`/setting/${editor.activeTemplateType}/${id}`));
  } catch (e) {
    console.log(e.response);
    yield put({
      type: "NEW_TEMPLATE_ERROR",
      payload: e.message || "Unknown Error",
    });
  }
}

function* handleUpdateTemplate() {
  try {
    // give time to the debounced redux updater
    yield Promise.delay(1500);
    const editor = yield select((state) => state.settings.template);
    const { success } = yield fn("update-template", {
      id: editor.activeTemplate,
      type: editor.activeTemplateType,
      title: editor.title,
      content: editor.content,
    });

    if (!success) {
      throw new Error("Fail to update the database. Please try again later.");
    }
    yield put({
      type: "UPDATE_TEMPLATE_SUCCESS",
    });
  } catch (e) {
    yield put({
      type: "UPDATE_TEMPLATE_ERROR",
      payload: e.message || "Unknown Error",
    });
  }
}

function* handleDeleteTemplate({ payload }) {
  const { id, type, name } = payload;
  const editor = yield select((state) => state.settings.template);
  yield put({
    type: "SHOW_LOADING",
    payload: { message: `Deleting ${name} ...` },
  });

  try {
    const { success } = yield fn("delete-template", { id, type });
    if (!success) {
      throw new Error("Fail to update the database. Please try again later.");
    }
    yield put(push(`/setting/${editor.activeTemplateType}`));
    yield put({
      type: "DELETE_TEMPLATE_SUCCESS",
      payload: { id },
    });
  } catch (e) {
    yield put({
      type: "DELETE_TEMPLATE_ERROR",
      payload: e.message || "Unknown Error",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* templateSettingSaga() {
  yield takeEvery(
    "GET_SETTING_TEMPLATE_MESSAGE",
    createGetTemplateHandler("message")
  );
  yield takeEvery(
    "GET_SETTING_TEMPLATE_DELIVERY",
    createGetTemplateHandler("delivery")
  );
  yield takeEvery(
    "GET_SETTING_TEMPLATE_PAYMENT",
    createGetTemplateHandler("payment")
  );

  yield takeEvery("GET_TEMPLATE", handleGetTemplateDetails);
  yield takeEvery("NEW_TEMPLATE", handleNewTemplate);
  yield takeEvery("UPDATE_TEMPLATE", handleUpdateTemplate);
  yield takeEvery("DELETE_TEMPLATE", handleDeleteTemplate);
}

export { templateSettingSaga };
