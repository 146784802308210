import React, { useEffect, useState } from "react";
import { GooSpinner } from "react-spinners-kit";
import { useDispatch, useSelector } from "react-redux";
import { Beforeunload } from "react-beforeunload";
import { Prompt } from "react-router";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";

import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

import useInputRedux from "../../../hooks/useInputRedux";
import ConfirmDialog from "../../../components/ConfirmDialog";
import ContentLoading from "../../../components/ContentLoading";
import FileInput from "../../../components/FileInput";
import Empty from "./Empty";
import useStyles from "./style";

function ContactEditor({ id }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);

  const activeContact = useSelector(
    (state) => state.contacts.editor.activeContact
  );
  const error = useSelector((state) => state.contacts.editor.error);
  const changed = useSelector((state) => state.contacts.editor.changed);
  const loading = useSelector((state) => state.contacts.editor.loading);
  const saving = useSelector((state) => state.contacts.editor.saving);

  const [email, setEmail] = useInputRedux(
    ["contacts", "editor", "email"],
    (value) => ({
      type: "SET_CONTACT_DETAIL",
      payload: { field: "email", value, id },
    })
  );
  const [phone, setPhone] = useInputRedux(
    ["contacts", "editor", "phone"],
    (value) => ({
      type: "SET_CONTACT_DETAIL",
      payload: { field: "phone", value, id },
    })
  );
  const [name, setName] = useInputRedux(
    ["contacts", "editor", "name"],
    (value) => ({
      type: "SET_CONTACT_DETAIL",
      payload: { field: "name", value, id },
    })
  );
  const [companyName, setCompanyName] = useInputRedux(
    ["contacts", "editor", "companyName"],
    (value) => ({
      type: "SET_CONTACT_DETAIL",
      payload: { field: "companyName", value, id },
    })
  );
  const [companyAddress, setCompanyAddress] = useInputRedux(
    ["contacts", "editor", "companyAddress"],
    (value) => ({
      type: "SET_CONTACT_DETAIL",
      payload: { field: "companyAddress", value, id },
    })
  );
  useEffect(() => {
    setNameError(null);
    setEmailError(null);
  }, [id]);

  const validateAndSubmit = () => {
    let error = false;
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(email.toLowerCase())) {
      setEmailError("Please provide a valid email address.");
      error = true;
    }
    if (name.trim().length === 0) {
      setNameError("Name is required.");
      error = true;
    }
    if (error) return;
    dispatch({
      type: newContact ? "NEW_CONTACT" : "UPDATE_CONTACT",
    });
  };

  if (!id) return <Empty />;
  if (loading || activeContact !== id) return <ContentLoading />;
  const newContact = id === "new";
  const title = newContact ? "New Contact" : name;

  return (
    <>
      <OverlayScrollbarsComponent className={classes.main}>
        <Paper className={classes.paper}>
          <AppBar
            component="div"
            className={classes.appBar}
            color="paper"
            position="static"
            elevation={0}
          >
            <Toolbar className={classes.toolbar}>
              <Typography
                variant="h5"
                id="tableTitle"
                component="div"
                className={classes.toolbarTitle}
              >
                {changed ? (
                  <Badge badgeContent={"Unsaved"} color="error">
                    {title}
                  </Badge>
                ) : (
                  title
                )}
              </Typography>
              {saving && (
                <div className={classes.loading}>
                  <GooSpinner size={24} color="#000" />
                </div>
              )}
              {!saving && (
                <IconButton
                  color="inherit"
                  disabled={!changed}
                  onClick={validateAndSubmit}
                >
                  <SaveIcon />
                </IconButton>
              )}
              {!newContact && (
                <IconButton
                  color="inherit"
                  onClick={() => setDeleting("product")}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Toolbar>
          </AppBar>
          <Divider />
          <Grid container className={classes.content} spacing={2}>
            {error && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  autoFocus
                  fullWidth
                  placeholder="Full Name"
                  disabled={saving}
                  label="Full Name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameError(null);
                  }}
                  error={!!nameError}
                  helperText={nameError}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  placeholder="name@company.com"
                  label="Email Address"
                  disabled={saving}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError(null);
                  }}
                  error={!!emailError}
                  helperText={emailError}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  placeholder="+1 123 123 1234"
                  label="Phone Number"
                  disabled={saving}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  disabled={saving}
                  placeholder="Company Name"
                  label="Company Name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  fullWidth
                  rows={3}
                  rowsMax={3}
                  disabled={saving}
                  placeholder={"123 Some Example Rd\nCity Name, State\n12345"}
                  label="Company Address"
                  value={companyAddress}
                  onChange={(e) => setCompanyAddress(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <div className={classes.spacer} />
        {changed && (
          <>
            <Beforeunload
              onBeforeunload={() =>
                "You have unsaved changes. Are you sure? Unsaved changes will lost."
              }
            />
            <Prompt message="You have unsaved changes. Are you sure? Unsaved changes will lost." />
          </>
        )}
      </OverlayScrollbarsComponent>
      <ConfirmDialog
        show={deleting}
        onClose={() => setDeleting(null)}
        onConfirm={() => {
          setDeleting(null);
          dispatch({
            type: "DELETE_CONTACT",
            payload: { id, name },
          });
        }}
        title="Deleting ..."
        message={
          <>
            Are you sure you want to delete <strong>{name}</strong>? This action
            cannot be undone.
          </>
        }
      />
    </>
  );
}

export default ContactEditor;
