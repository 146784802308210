import { put, takeEvery } from "redux-saga/effects";
import { fn } from "../../../lib/fn";

function* getUsers() {
  const users = yield fn("get-users");
  yield put({ type: "GET_USERS_SUCCESS", payload: users });
}

function* userListSaga() {
  yield takeEvery("GET_USERS", getUsers);
}

export { userListSaga };
