import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    display: "flex",
  },
  left: {
    width: "23%",
    display: "flex",
  },
  right: {
    width: "77%",
    display: "flex",
  },
}));
