import React, { useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

function FileInput({ buttonProps, buttonText, onSelected, toolTip }) {
  const inputRef = useRef(null);
  const _buttonProps = buttonProps || {};
  const onClick = () => {
    if (inputRef.current) inputRef.current.click();
  };
  const onChange = (e) => {
    if (e.target.files.length > 0) {
      onSelected(e.target.files[0]);
    }
    inputRef.current.value = null;
  };

  const btn = (
    <Button {..._buttonProps} onClick={onClick}>
      {buttonText}
    </Button>
  );
  return (
    <span>
      <input
        type="file"
        accept="image/x-png,image/bmp,image/jpeg"
        ref={inputRef}
        style={{ display: "none" }}
        onChange={onChange}
      />
      {toolTip ? (
        <Tooltip title={toolTip}>
          <span>{btn}</span>
        </Tooltip>
      ) : (
        btn
      )}
    </span>
  );
}

export default FileInput;
