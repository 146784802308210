import update from "immutability-helper";

import createInitialState from "../../../lib/time-list-loader/create-initial-state";

const initialState = createInitialState(new Date().toISOString());

function quotes(state = initialState, action) {
  switch (action.type) {
    case "SEARCH_QUOTES": {
      const { keyword } = action.payload;
      return update(state, {
        searchKeyword: {
          $set: keyword,
        },
        searchLoading: {
          $set: true,
        },
      });
    }
    case "SEARCH_QUOTES_SUCCESS": {
      const { keyword, result } = action.payload;
      if (keyword !== state.searchKeyword) return state;

      const quoteList = [];
      const details = {};
      for (let quote of result) {
        details[quote.quote_id] = {
          id: quote.quote_id,
          status: quote.status,
          lastModified: quote.modified_at,
          lastModifiedBy: quote.last_modified_name,
        };
        quoteList.push(quote.quote_id);
      }
      return update(state, {
        details: {
          $merge: details,
        },
        searchResult: {
          $set: quoteList,
        },
        searchLoading: {
          $set: false,
        },
      });
    }
    case "LOAD_QUOTES": {
      const { direction } = action.payload;
      return update(state, {
        searchKeyword: {
          $set: "",
        },
        loadingNewer: {
          $set: direction === "before",
        },
        loadingOlder: {
          $set: direction === "after",
        },
      });
    }
    case "UPDATE_QUOTE_SUCCESS": {
      const { id, actor, title } = action.payload;
      return update(state, {
        items: (items) => [id].concat(items.filter((quote) => quote !== id)),
        details: {
          [id]: {
            lastModified: { $set: new Date().toISOString() },
            title: { $set: title },
            lastModifiedBy: { $set: actor },
          },
        },
      });
    }
    case "SEND_QUOTE_DONE": {
      const { id } = action.payload;
      return update(state, {
        items: (items) => [id].concat(items.filter((quote) => quote !== id)),
        details: {
          [id]: {
            status: { $set: "FINAL" },
          },
        },
      });
    }
    case "DELETE_QUOTE_SUCCESS": {
      const { id } = action.payload;
      return update(state, {
        items: (items) => items.filter((quote) => quote !== id),
        details: {
          $unset: [id],
        },
      });
    }
    case "LOAD_QUOTES_SUCCESS": {
      const { data, direction } = action.payload;
      const newQuotes = [];
      const newQuoteListDetails = {};
      for (let quote of data) {
        newQuoteListDetails[quote.quote_id] = {
          id: quote.quote_id,
          status: quote.status,
          title: quote.title,
          lastModified: quote.modified_at,
          lastModifiedBy: quote.last_modified_name,
        };
        newQuotes.push(quote.quote_id);
      }

      let needSort = false;
      const oldQuotes = state.items.filter((quote) => {
        if (!newQuoteListDetails[quote]) return true;
        if (
          newQuoteListDetails[quote].lastModified !==
          state.details[quote].lastModified
        ) {
          needSort = true;
          return false;
        }
        return false;
      });
      const quoteListDetails = update(state.details, {
        $merge: newQuoteListDetails,
      });

      const newerQuotes = direction === "before" ? newQuotes : oldQuotes;
      const olderQuotes = direction === "before" ? oldQuotes : newQuotes;
      const quotes = needSort
        ? newerQuotes
            .concat(olderQuotes)
            .sort((a, b) =>
              quoteListDetails[a].lastModified <
              quoteListDetails[b].lastModified
                ? 1
                : -1
            )
        : newerQuotes.concat(olderQuotes);

      return update(state, {
        loadingNewer: (value) => (direction === "before" ? false : value),
        loadingOlder: (value) => (direction === "after" ? false : value),
        hasOlder: (value) =>
          direction === "after" ? newQuotes.length > 1 : value,
        cursor: (value) => {
          if (!quotes.length || direction !== "after") return value;
          return quoteListDetails[quotes[quotes.length - 1]].lastModified;
        },
        loading: {
          $set: false,
        },
        items: {
          $set: quotes,
        },
        details: {
          $set: quoteListDetails,
        },
      });
    }
    default:
      return state;
  }
}

export default quotes;
