import { takeEvery, put } from "redux-saga/effects";
import createListLoaderSaga from "../../../lib/time-list-loader/create-saga";
import { fn } from "../../../lib/fn";

const handleLoadQuotes = createListLoaderSaga(
  "quotes",
  "lastModified",
  "LOAD_QUOTES"
);

function* handleSearchQuotes({ payload }) {
  const { keyword } = payload;
  if (!keyword.length) {
    yield put({
      type: "SEARCH_QUOTES_SUCCESS",
      payload: { keyword, result: [] },
    });
    return;
  }
  const quotes = yield fn("search-quotes", { keyword });
  yield put({
    type: "SEARCH_QUOTES_SUCCESS",
    payload: { keyword, result: quotes },
  });
}

function* quoteListSaga() {
  yield takeEvery("SEARCH_QUOTES", handleSearchQuotes);
  yield takeEvery("LOAD_QUOTES", handleLoadQuotes);
}

export { quoteListSaga };
