import React from "react";
import Grid from "@material-ui/core/Grid";
import GroupIcon from "@material-ui/icons/Group";

import useStyles from "./style";

function Empty() {
  const classes = useStyles();
  return (
    <Grid
      item
      container
      direction="column"
      alignItems="center"
      justify="center"
      className={classes.empty}
    >
      <Grid item>
        <GroupIcon fontSize="large" />
      </Grid>
      <Grid item>No User Selected</Grid>
    </Grid>
  );
}

export default Empty;
