import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  dropzone: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(4),
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    "&:focus": {
      borderColor: "#2196f3",
    },
    "&:disabled": {
      opacity: 0.6,
    },
  },
}));
