import React from "react";
import { useSelector } from "react-redux";

import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

import useStyles from "./style-view";

function SeparatorView({ onClickDelete, onClickEdit, text }) {
  const classes = useStyles();
  const saving = useSelector((state) => state.quotes.editor.saving);

  return (
    <>
      <TableCell width="5%">
        <DragIndicatorIcon />
      </TableCell>
      <TableCell width="5%" align="right">
        <IconButton color="inherit" onClick={onClickEdit} disabled={saving}>
          <EditIcon fontSize="small" />
        </IconButton>
        <IconButton color="inherit" onClick={onClickDelete} disabled={saving}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </TableCell>
      <TableCell
        width="90%"
        align="center"
        colSpan={5}
        className={classes.break}
      >
        <em>{text || "Blank Separator"}</em>
      </TableCell>
    </>
  );
}

export default SeparatorView;
