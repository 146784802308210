import React from "react";
import { useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import useStyle from "./style";

function ErrorDialog() {
  const fatalError = useSelector((state) => state.dialog.fatalError);
  const classes = useStyle();

  return (
    <Dialog
      open={!!fatalError}
      aria-labelledby="form-dialog-title"
      classes={classes}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Fatal Error</DialogTitle>
      <form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          return false;
        }}
      >
        <DialogContent className={classes.content}>
          <DialogContentText>{fatalError}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            color="primary"
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default ErrorDialog;
