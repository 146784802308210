import { put, takeEvery } from "redux-saga/effects";
import { fn } from "../../lib/fn";

function* getSKUs() {
  const skus = yield fn("get-skus");
  yield put({ type: "GET_SKUS_SUCCESS", payload: skus });
}

function* skuSagas() {
  yield takeEvery("GET_SKUS", getSKUs);
}

export { skuSagas };
