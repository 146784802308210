import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import DownloadIcon from "@material-ui/icons/GetApp";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { getImageURL } from "../../../../lib/image";
import { getAuditText } from "../../../../lib/audit";
import useStyles from "./styles";

function QuoteMeta({ id }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (e) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const title = useSelector((state) => state.quotes.editor.details.title);
  const recipients = useSelector((state) => state.quotes.editor.recipients);
  const aclLoading = useSelector((state) => state.quotes.editor.aclLoading);
  const auditLoading = useSelector((state) => state.quotes.editor.auditLoading);

  const acl = useSelector((state) => state.quotes.editor.acl);
  const audit = useSelector((state) => state.quotes.editor.audit);

  useEffect(() => {
    dispatch({ type: "GET_QUOTE_ACL", payload: { id } });
  }, [id]);
  useEffect(() => {
    dispatch({ type: "GET_QUOTE_AUDIT", payload: { id } });
  }, [id]);

  return (
    <>
      <AppBar
        component="div"
        className={classes.appBar}
        color="paper"
        position="static"
        elevation={0}
      >
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" id="tableTitle" component="div">
            Activity
          </Typography>
        </Toolbar>
      </AppBar>
      <Divider />
      <Grid container className={classes.content} spacing={2}>
        <Grid container item xs={12} sm={4} spacing={2} direction="column">
          <Grid item>
            <Paper variant="outlined">
              <List>
                {aclLoading && (
                  <>
                    <ListItem>
                      <ListItemAvatar>
                        <Skeleton circle height={32} width={32} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={<Skeleton width={150} height={20} />}
                      />
                    </ListItem>
                    <Divider component="li" />
                    <ListItem>
                      <ListItemAvatar>
                        <Skeleton circle height={32} width={32} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={<Skeleton width={150} height={20} />}
                      />
                    </ListItem>
                  </>
                )}
                {!aclLoading &&
                  recipients &&
                  recipients.map((user, i) => (
                    <React.Fragment key={user.user_id}>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar alt={user.name || user.email}>
                            {(user.name || user.email)[0]}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={user.name || user.email} />
                      </ListItem>
                      {i !== recipients.length - 1 && (
                        <Divider component="li" />
                      )}
                    </React.Fragment>
                  ))}
              </List>
            </Paper>
          </Grid>
          <Grid container item className={classes.grow}>
            <Paper variant="outlined" className={classes.grow}>
              <List>
                {aclLoading && (
                  <>
                    <ListItem>
                      <ListItemAvatar>
                        <Skeleton circle height={32} width={32} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={<Skeleton width={150} height={20} />}
                      />
                    </ListItem>
                    <Divider component="li" />
                    <ListItem>
                      <ListItemAvatar>
                        <Skeleton circle height={32} width={32} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={<Skeleton width={150} height={20} />}
                      />
                    </ListItem>
                  </>
                )}
                {!aclLoading &&
                  acl &&
                  acl.map((user, i) => (
                    <React.Fragment key={user.user_id}>
                      <ListItem>
                        <ListItemAvatar>
                          {user.picture ? (
                            <Avatar
                              alt={user.name}
                              src={getImageURL(user.picture)}
                            />
                          ) : (
                            <Avatar alt={user.name}>{user.name[0]}</Avatar>
                          )}
                        </ListItemAvatar>
                        <ListItemText primary={user.name} />
                      </ListItem>
                      {i !== acl.length - 1 && <Divider component="li" />}
                    </React.Fragment>
                  ))}
              </List>
            </Paper>
          </Grid>
        </Grid>
        <Grid container item direction="column" xs={12} sm={8}>
          <Paper variant="outlined" className={classes.grow}>
            <List dense>
              {auditLoading && (
                <>
                  <ListItem>
                    <ListItemAvatar className={classes.avatarContainer}>
                      <Skeleton circle height={24} width={24} />
                    </ListItemAvatar>
                    <ListItemText
                      secondary={
                        <Skeleton style={{ marginLeft: 8 }} width={320} />
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar className={classes.avatarContainer}>
                      <Skeleton circle height={24} width={24} />
                    </ListItemAvatar>
                    <ListItemText
                      secondary={
                        <Skeleton style={{ marginLeft: 8 }} width={320} />
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar className={classes.avatarContainer}>
                      <Skeleton circle height={24} width={24} />
                    </ListItemAvatar>
                    <ListItemText
                      secondary={
                        <Skeleton style={{ marginLeft: 8 }} width={320} />
                      }
                    />
                  </ListItem>
                </>
              )}
              {!auditLoading &&
                audit &&
                audit.map((item) => (
                  <ListItem key={item.id}>
                    <ListItemAvatar className={classes.avatarContainer}>
                      {item.actor_picture ? (
                        <Avatar
                          alt={item.actor_name}
                          src={getImageURL(item.actor_picture)}
                          className={classes.avatar}
                        />
                      ) : (
                        <Avatar
                          alt={item.actor_name}
                          className={classes.avatar}
                        >
                          {item.actor_name[0]}
                        </Avatar>
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      secondary={
                        <>
                          <Typography
                            variant="caption"
                            display="inline"
                            color="textPrimary"
                          >
                            {getAuditText(item)}
                          </Typography>
                          <Typography variant="caption" display="inline">
                            &nbsp;&mdash;&nbsp;{item.created_at}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default QuoteMeta;
