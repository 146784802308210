import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import DownloadIcon from "@material-ui/icons/GetApp";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { getImageURL } from "../../../../lib/image";
import { getAuditText } from "../../../../lib/audit";
import useStyles from "./styles";

function QuoteHeader({ id }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (e) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const title = useSelector((state) => state.quotes.editor.details.title);

  return (
    <>
      <AppBar
        component="div"
        className={classes.appBar}
        color="paper"
        position="static"
        elevation={0}
      >
        <Toolbar className={classes.toolbar}>
          <Typography
            variant="h5"
            id="tableTitle"
            component="div"
            className={classes.toolbarTitle}
          >
            #{id} - {title}
          </Typography>
          <IconButton
            color="inherit"
            onClick={() =>
              dispatch({
                type: "DOWNLOAD_QUOTE",
                payload: { id },
              })
            }
          >
            <DownloadIcon />
          </IconButton>
          <IconButton color="inherit" onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={!!anchorEl}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                dispatch({
                  type: "COPY_QUOTE",
                  payload: { id },
                });
              }}
            >
              Make a Copy
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                dispatch({
                  type: "COPY_QUOTE",
                  payload: { id, revise: true },
                });
              }}
            >
              Revise
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Divider />
    </>
  );
}

export default QuoteHeader;
