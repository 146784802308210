import React from "react";

import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";

import Skeleton from "./Skeleton";
import Toolbar from "./Toolbar";
import useStyles from "./style";

function GenericList({
  loading,
  children,
  emptyText,
  onNewClicked,
  onImportClicked,
  onSearch,
  manual,
}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {onNewClicked && (
        <Toolbar
          onNewClicked={onNewClicked}
          onImportClicked={onImportClicked}
          onSearch={onSearch}
        />
      )}
      {!loading && children.length === 0 && (
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justify="center"
          className={classes.empty}
        >
          <Grid item>{emptyText}</Grid>
        </Grid>
      )}
      {manual && loading && (
        <OverlayScrollbarsComponent
          className={classes.scroll}
          options={{ scrollbars: { autoHide: "scroll" } }}
        >
          <List component="nav" className={classes.list}>
            {loading && <Skeleton n={3} />}
          </List>
        </OverlayScrollbarsComponent>
      )}
      {manual && !loading && children}
      {!manual && (
        <OverlayScrollbarsComponent
          className={classes.scroll}
          options={{ scrollbars: { autoHide: "scroll" } }}
        >
          <List component="nav" className={classes.list}>
            {loading && <Skeleton n={3} />}
            {children}
          </List>
        </OverlayScrollbarsComponent>
      )}
    </div>
  );
}

export default GenericList;
