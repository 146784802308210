import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";

import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import useStyle from "./style-step3";

function Step3({ onFileSelected }) {
  const classes = useStyle();
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: ".xlsx",
    maxFiles: 1,
  });

  useEffect(() => {
    onFileSelected(acceptedFiles);
  }, [acceptedFiles]);

  return (
    <Grid direction="column" container spacing={1}>
      {acceptedFiles.map((file, i) => (
        <Grid key={`${i}-${file.name}`} item>
          <Alert severity="success">
            <strong>{file.name}</strong>: is accepted. Please click Confirm to
            start importing data.
          </Alert>
        </Grid>
      ))}
      {fileRejections.map(({ file, errors }, i) => (
        <Grid key={`${i}-${file.name}`} item>
          <Alert severity="error">
            <strong>{file.name}</strong>: {errors[0].message}
          </Alert>
        </Grid>
      ))}
      <Grid item>
        <div {...getRootProps({ className: classes.dropzone })}>
          <input {...getInputProps()} />
          <p>
            {acceptedFiles.length
              ? "If you want to change the file, drag"
              : "Drag"}{" "}
            'n' drop the Excel file here, or click to select the file
          </p>
        </div>
      </Grid>
    </Grid>
  );
}

export default Step3;
