import React, { useEffect } from "react";
import { push } from "connected-react-router";
import { useSelector, useDispatch } from "react-redux";

import List from "../../../components/GenericList";
import ListItem from "../../../components/GenericList/Item";

function ContactList({ id }) {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.contacts.list.loading);
  const contacts = useSelector((state) => state.contacts.list.contacts);
  const searchKeyword = useSelector(
    (state) => state.contacts.list.searchKeyword
  );
  const searchResult = useSelector((state) => state.contacts.list.searchResult);

  useEffect(() => {
    dispatch({ type: "GET_CONTACTS" });
  }, []);
  const items = searchKeyword.length ? searchResult : contacts;
  return (
    <List
      onNewClicked={() => dispatch(push("/contact/new"))}
      onImportClicked={() => dispatch({ type: "SHOW_IMPORT_CONTACTS" })}
      onSearch={(keyword) =>
        dispatch({ type: "SEARCH_CONTACTS", payload: { keyword } })
      }
      emptyText={
        searchKeyword.length
          ? "No contact matching your search criteria found."
          : "To create a new contact, use the the + button below."
      }
      loading={loading}
    >
      {items.map((item) => (
        <ListItem
          key={item.id}
          id={item.id}
          title={item.name}
          subtitle={item.email}
          selected={item.id === id}
          page="contact"
        />
      ))}
    </List>
  );
}

export default ContactList;
