import React, { useState, useEffect, useRef } from "react";
import { push } from "connected-react-router";
import { useParams } from "react-router-dom";
import { GooSpinner } from "react-spinners-kit";
import { useDispatch, useSelector } from "react-redux";
import { Beforeunload } from "react-beforeunload";
import { Prompt } from "react-router";
import Alert from "@material-ui/lab/Alert";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";

import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";

import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

import getTitle from "../../../../lib/template-title";
import useInputRedux from "../../../../hooks/useInputRedux";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import Editor from "../../../../components/HighlightedEditor";
import ContentLoading from "../../../../components/ContentLoading";
import useStyles from "./style";

function Templates() {
  const { id: activeTemplateType, selectedId } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(false);
  const [titleError, setTitleError] = useState(null);
  const [contentError, setContentError] = useState(null);

  const error = useSelector((state) => state.settings.template.error);
  const loading = useSelector((state) => state.settings.template.loading);
  const loadingContent = useSelector(
    (state) => state.settings.template.loadingContent
  );
  const changed = useSelector((state) => state.settings.template.changed);
  const saving = useSelector((state) => state.settings.template.saving);
  const activeTemplate = useSelector(
    (state) => state.settings.template.activeTemplate
  );
  const templates = useSelector((state) => state.settings.template.templates);

  const [title, setTitle] = useInputRedux(
    ["settings", "template", "title"],
    (value) => ({
      type: "SET_TEMPLATE_DETAIL",
      payload: { field: "title", value, id: activeTemplate },
    })
  );
  const [content, setContent] = useInputRedux(
    ["settings", "template", "content"],
    (value) => ({
      type: "SET_TEMPLATE_DETAIL",
      payload: { field: "content", value, id: activeTemplate },
    })
  );

  useEffect(() => {
    if (activeTemplateType && selectedId) {
      dispatch({
        type: "GET_TEMPLATE",
        payload: { id: selectedId, type: activeTemplateType },
      });
    }
  }, [selectedId, activeTemplateType]);

  const newTemplate = activeTemplate === "new";
  const lockContent = loadingContent || activeTemplate !== selectedId || saving;

  const validateAndSubmit = () => {
    let error = false;
    if (title.trim().length === 0) {
      setTitleError("Title is required.");
      error = true;
    }
    if (content.trim().length === 0) {
      setContentError("Content is required.");
      error = true;
    }
    if (error) return;
    dispatch({
      type: newTemplate ? "NEW_TEMPLATE" : "UPDATE_TEMPLATE",
    });
  };

  if (loading) return <ContentLoading />;

  return (
    <>
      <OverlayScrollbarsComponent className={classes.main}>
        <Paper className={classes.paper}>
          <AppBar
            component="div"
            className={classes.appBar}
            color="paper"
            position="static"
            elevation={0}
          >
            <Toolbar className={classes.toolbar}>
              <Typography
                variant="h5"
                id="tableTitle"
                component="div"
                className={classes.toolbarTitle}
              >
                {changed && (
                  <Badge badgeContent={"Unsaved"} color="error">
                    {getTitle(activeTemplateType)}
                  </Badge>
                )}
                {!changed && getTitle(activeTemplateType)}
              </Typography>
              {saving && (
                <div className={classes.loading}>
                  <GooSpinner size={24} color="#000" />
                </div>
              )}
              {!saving && (
                <IconButton
                  color="inherit"
                  disabled={!changed}
                  onClick={validateAndSubmit}
                >
                  <SaveIcon />
                </IconButton>
              )}
              {!newTemplate && (
                <IconButton
                  color="inherit"
                  disabled={activeTemplate === "default"}
                  onClick={() => setDeleting(true)}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Toolbar>
          </AppBar>
          <Divider />
          <Grid container className={classes.content} spacing={2}>
            {error && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth required>
                  <InputLabel shrink id="template-selector-label">
                    Select {getTitle(activeTemplateType)}
                  </InputLabel>
                  <Select
                    labelId="template-selector-label"
                    id="template-selector-select"
                    value={selectedId}
                    onChange={(e) =>
                      dispatch(
                        push(`/setting/${activeTemplateType}/${e.target.value}`)
                      )
                    }
                  >
                    {[{ id: "new", title: "-- New Template --" }]
                      .concat(templates)
                      .map((template) => (
                        <MenuItem key={template.id} value={template.id}>
                          {template.title}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText>&nbsp;</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  disabled={lockContent || activeTemplate === "default"}
                  placeholder="Title"
                  label="Template Title"
                  error={!!titleError}
                  helperText={titleError}
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                    setTitleError(null);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Editor
                  disabled={lockContent}
                  message={content}
                  setMessage={(message) => {
                    setContent(message);
                    setContentError(null);
                  }}
                  inputProps={{
                    error: !!contentError,
                    helperText: contentError,
                    required: true,
                    label: "Content",
                    placeholder: "Some content ...",
                    variant: "standard",
                    InputLabelProps: {
                      shrink: true,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <div className={classes.spacer} />
        {changed && (
          <>
            <Beforeunload
              onBeforeunload={() =>
                "You have unsaved changes. Are you sure? Unsaved changes will lost."
              }
            />
            <Prompt message="You have unsaved changes. Are you sure? Unsaved changes will lost." />
          </>
        )}
      </OverlayScrollbarsComponent>
      <ConfirmDialog
        show={deleting}
        onClose={() => setDeleting(false)}
        onConfirm={() => {
          setDeleting(false);
          dispatch({
            type: "DELETE_TEMPLATE",
            payload: {
              type: activeTemplateType,
              name: title,
              id: activeTemplate,
            },
          });
        }}
        title="Deleting ..."
        message={
          <>
            Are you sure you want to delete <b>{title}</b>? This action cannot
            be undone.
          </>
        }
      />
    </>
  );
}

export default Templates;
