import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  title: {
    "& input": {
      fontSize: "1.4em",
    },
  },
  editor: {
    "& textarea:focus": {
      outline: "none",
    },
  },
  // protector: {
  //   width: "300px",
  //   overflow: "hidden",
  // },
  avatarContainer: {
    minWidth: `${theme.spacing(3)}px !important`,
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(),
  },
  floatingContainer: {
    position: "relative",
  },
  floating: {
    position: "absolute",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    width: "auto",
  },
  grow: {
    flexGrow: 1,
  },
}));
