import Promise from "bluebird";
import { push } from "connected-react-router";
import { eventChannel } from "redux-saga";
import { takeEvery, take, put, select } from "redux-saga/effects";
import { fn, fnUpload } from "../../../lib/fn";

function* handleGetProfile() {
  const id = yield select((state) => state.auth.userId);
  const data = yield fn("get-user", { id });
  if (!data) {
    yield put(push("/settings"));
    return;
  }
  yield put({
    type: "GET_SETTING_PROFILE_SUCCESS",
    payload: data,
  });
}

function* handleUpdateProfile() {
  try {
    // give time to the debounced redux updater
    yield Promise.delay(1500);
    const editor = yield select((state) => state.settings.profile);
    const { success } = yield fn("update-profile", {
      name: editor.name,
      email: editor.email,
      password: editor.password || null,
    });

    if (!success) {
      throw new Error("Fail to update the database. Please try again later.");
    }
    yield put({
      type: "UPDATE_SETTING_PROFILE_SUCCESS",
    });
  } catch (e) {
    yield put({
      type: "UPDATE_SETTING_PROFILE_ERROR",
      payload: e.message || "Unknown Error",
    });
  }
}

function* handleUploadProfilePicture({ payload }) {
  const id = yield select((state) => state.auth.userId);
  yield put({
    type: "SHOW_LOADING",
    payload: { message: "Uploading ..." },
  });
  const chan = eventChannel((emit) => {
    fnUpload(
      "upload-user-image",
      { ...payload, id },
      (progress) => emit({ type: "PROGRESS", progress }),
      (data) => emit({ type: "DONE", data }),
      (error) => emit({ type: "ERROR", error })
    );
    return () => {};
  });

  try {
    while (true) {
      const event = yield take(chan);
      if (event.type === "PROGRESS") {
        yield put({
          type: "SHOW_LOADING",
          payload: {
            message:
              event.progress < 100
                ? `(${event.progress.toFixed(2)}%) Uploading ...`
                : "Processing ...",
          },
        });
      } else if (event.type === "DONE") {
        yield put({
          type: "UPLOAD_SETTING_PROFILE_PICTURE_SUCCESS",
          payload: { image: event.data.id },
        });
        return;
      } else {
        throw event.error;
      }
    }
  } catch (e) {
    yield put({
      type: "UPLOAD_SETTING_PROFILE_PICTURE_ERROR",
      payload: e.message || "Unknown Error",
    });
  } finally {
    chan.close();
    yield put({ type: "HIDE_LOADING" });
  }
}

function* handleRemoveProfilePicture() {
  const id = yield select((state) => state.auth.userId);
  yield put({
    type: "SHOW_LOADING",
    payload: { message: "Removing image ..." },
  });

  try {
    const { success } = yield fn("remove-user-picture", { id });
    if (!success) {
      throw new Error("Fail to update the database. Please try again later.");
    }
    yield put({
      type: "REMOVE_SETTING_PROFILE_PICTURE_SUCCESS",
    });
  } catch (e) {
    yield put({
      type: "REMOVE_SETTING_PROFILE_PICTURE_ERROR",
      payload: e.message || "Unknown Error",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* profileSettingSaga() {
  yield takeEvery("GET_SETTING_PROFILE", handleGetProfile);
  yield takeEvery("UPDATE_SETTING_PROFILE", handleUpdateProfile);
  yield takeEvery("UPLOAD_SETTING_PROFILE_PICTURE", handleUploadProfilePicture);
  yield takeEvery("REMOVE_SETTING_PROFILE_PICTURE", handleRemoveProfilePicture);
}

export { profileSettingSaga };
