import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import ImportDialog from "../../../components/ImportDialog";
import { fnDownloadURL } from "../../../lib/fn";

function ProductImporter() {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.products.import.show);
  const step = useSelector((state) => state.products.import.step);
  const error = useSelector((state) => state.products.import.error);
  const processing = useSelector((state) => state.products.import.processing);
  const importResult = useSelector(
    (state) => state.products.import.importResult
  );
  const uploadProgress = useSelector(
    (state) => state.products.import.uploadProgress
  );
  const importType = useSelector((state) => state.products.import.importType);

  const [importFiles, setImportFiles] = useState([]);

  return (
    <ImportDialog
      name="Product"
      itemUnits="SKU(s)"
      show={show}
      step={step}
      error={error}
      processing={processing}
      importResult={importResult}
      uploadProgress={uploadProgress}
      importType={importType}
      onClose={() => dispatch({ type: "CLOSE_IMPORT_PRODUCTS" })}
      onConfirm={() =>
        dispatch({
          type: "IMPORT_PRODUCT",
          payload: {
            products: importFiles[0],
            type: importType,
          },
        })
      }
      setStep={(step) =>
        dispatch({ type: "IMPORT_PRODUCT_SET_STEP", payload: step })
      }
      onImportTypeSelected={(type) =>
        dispatch({ type: "IMPORT_PRODUCT_SET_TYPE", payload: type })
      }
      onFileSelected={setImportFiles}
      templateURL={fnDownloadURL("get-product-template")}
    />
  );
}

export default ProductImporter;
