import React from "react";

import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";

import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

function DeleteView({ onConfirmDelete, onCancelDelete }) {
  return (
    <>
      <TableCell width="5%">
        <DragIndicatorIcon />
      </TableCell>
      <TableCell width="5%" align="right">
        <IconButton color="secondary" onClick={onConfirmDelete}>
          <CheckIcon fontSize="small" />
        </IconButton>
        <IconButton color="primary" onClick={onCancelDelete}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </TableCell>
      <TableCell width="90%" align="center" colSpan={5}>
        <em>
          Are you sure you want to delete this item? This action cannot be
          undone.
        </em>
      </TableCell>
    </>
  );
}

export default DeleteView;
