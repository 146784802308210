import { getImageURL } from "../../../lib/image";
const update = require("immutability-helper");

const initialState = {
  error: null,
  loading: false,
  saving: false,
  changed: false,

  type: null,
  activeUntil: null,

  name: null,
  phone: null,
  address: null,
  logo: null,
};

function company(state = initialState, action) {
  switch (action.type) {
    case "GET_SETTING_COMPANY": {
      return update(state, {
        error: {
          $set: null,
        },
        loading: {
          $set: true,
        },
        changed: {
          $set: false,
        },
      });
    }
    case "SET_SETTING_COMPANY_DETAIL": {
      const { field, value } = action.payload;
      return update(state, {
        changed: {
          $set: true,
        },
        error: {
          $set: null,
        },
        [field]: {
          $set: value,
        },
      });
    }
    case "REMOVE_SETTING_COMPANY_PICTURE_SUCCESS": {
      return update(state, {
        logo: {
          $set: null,
        },
      });
    }
    case "REMOVE_SETTING_COMPANY_PICTURE":
    case "UPLOAD_SETTING_COMPANY_PICTURE": {
      return update(state, {
        error: {
          $set: null,
        },
      });
    }
    case "UPLOAD_SETTING_COMPANY_PICTURE_SUCCESS": {
      const { image } = action.payload;
      return update(state, {
        logo: {
          $set: getImageURL(image),
        },
      });
    }
    case "GET_SETTING_COMPANY_SUCCESS": {
      const { logo, phone, address, name, type, active_until } = action.payload;
      return update(state, {
        loading: {
          $set: false,
        },
        activeUntil: {
          $set: new Date(active_until).toLocaleDateString(undefined, {
            day: "2-digit",
            month: "short",
            year: "numeric",
          }),
        },
        type: {
          $set: type,
        },
        name: {
          $set: name,
        },
        address: {
          $set: address,
        },
        phone: {
          $set: phone,
        },
        logo: {
          $set: logo && getImageURL(logo),
        },
      });
    }
    case "UPDATE_SETTING_COMPANY": {
      return update(state, {
        error: {
          $set: null,
        },
        saving: {
          $set: true,
        },
      });
    }
    case "UPDATE_SETTING_COMPANY_SUCCESS": {
      return update(state, {
        changed: {
          $set: false,
        },
        saving: {
          $set: false,
        },
      });
    }
    case "UPDATE_SETTING_COMPANY_ERROR":
    case "REMOVE_SETTING_COMPANY_PICTURE_ERROR":
    case "UPLOAD_SETTING_COMPANY_PICTURE_ERROR": {
      return update(state, {
        saving: {
          $set: false,
        },
        error: {
          $set: action.payload,
        },
      });
    }
    default:
      return state;
  }
}

export default company;
