import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    zIndex: "99999 !important",
    "&:first-child": {
      paddingTop: 0,
    },
  },
}));
