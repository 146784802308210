import React from "react";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";

import ContentLoading from "../../components/ContentLoading";
import QuoteEditor from "./QuoteEditor";
import QuoteView from "./QuoteView";
import useStyles from "./style-content";

function QuoteContent({ id }) {
  const classes = useStyles();
  const loadingNewer = useSelector((state) => state.quotes.list.loadingNewer);
  const loadingOlder = useSelector((state) => state.quotes.list.loadingOlder);

  const loadingCurrency = useSelector(
    (state) => state.settings.currency.loading
  );
  const loading = useSelector((state) => state.quotes.editor.loading);
  const activeQuote = useSelector((state) => state.quotes.editor.activeQuote);
  const quote = useSelector((state) => id && state.quotes.list.details[id]);

  if (!id)
    return (
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        justify="center"
        className={classes.empty}
      >
        <Grid item>
          <FolderOpenIcon fontSize="large" />
        </Grid>
        <Grid item>No Quote Selected</Grid>
      </Grid>
    );
  if (
    loadingCurrency ||
    loadingNewer ||
    loadingOlder ||
    loading ||
    id !== activeQuote
  )
    return <ContentLoading />;
  if (quote && quote.status === "FINAL") return <QuoteView id={id} />;
  return <QuoteEditor id={id} />;
}

export default QuoteContent;
