import React from "react";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";

function Sending() {
  const steps = useSelector((state) => state.quotes.editor.sendingSteps);

  return (
    <Grid container direction="column" spacing={1}>
      {steps.map((step, i) => (
        <Grid key={i} xs container item spacing={1} justify="center">
          <Grid item>
            {step.status === "ACTIVE" && <CircularProgress size={16} />}
            {step.status === "WAITING" && (
              <HourglassEmptyIcon fontSize="small" />
            )}
            {step.status === "DONE" && (
              <CheckCircleIcon fontSize="small" color="primary" />
            )}
          </Grid>
          <Grid xs item>
            {step.status === "ACTIVE" && <strong>{step.textProgress}</strong>}
            {step.status === "WAITING" && step.textWaiting}
            {step.status === "DONE" && step.textDone}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

export default Sending;
