import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  sku: {
    "& input": {
      fontSize: "0.8em",
    },
    marginBottom: theme.spacing(),
  },
  qty: {
    width: "35%",
  },
  number: {
    "& input": {
      fontSize: "0.8em",
      textAlign: "right",
    },
  },
}));
