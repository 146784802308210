import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    zIndex: "99999 !important",
    "&:first-child": {
      paddingTop: 0,
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));
