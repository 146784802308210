const update = require("immutability-helper");

function createReducer(name, initialState) {
  return function _importer_reducer(state = initialState, action) {
    switch (action.type) {
      case `SHOW_IMPORT_${name}`: {
        return update(initialState, {
          show: {
            $set: true,
          },
        });
      }
      case `CLOSE_IMPORT_${name}`: {
        return update(state, {
          show: {
            $set: false,
          },
        });
      }
      case `IMPORT_${name}`: {
        return update(state, {
          processing: {
            $set: true,
          },
          step: {
            $set: 4,
          },
        });
      }
      case `IMPORT_${name}_SET_STEP`: {
        return update(state, {
          step: {
            $set: action.payload,
          },
        });
      }
      case `IMPORT_${name}_SET_TYPE`: {
        return update(state, {
          importType: {
            $set: action.payload,
          },
        });
      }
      case `IMPORT_${name}_PROGRESS`: {
        return update(state, {
          uploadProgress: {
            $set: action.payload,
          },
        });
      }
      case `IMPORT_${name}_SUCCESS`: {
        return update(state, {
          importResult: {
            $set: action.payload,
          },
          processing: {
            $set: false,
          },
        });
      }
      case `IMPORT_${name}_ERROR`: {
        return update(state, {
          error: {
            $set: action.payload,
          },
          processing: {
            $set: false,
          },
        });
      }
      default:
        return state;
    }
  };
}

export default createReducer;
