function createInitialState(defaultCursor = false, additional = {}) {
  return {
    loadingBefore: false,
    loadingAfter: false,
    hasAfter: true,
    items: [],
    details: {},
    cursor: defaultCursor,
    searchLoading: false,
    searchKeyword: "",
    searchResult: [],
    ...additional,
  };
}

export default createInitialState;
