import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "underscore";

function useInputRedux(paths, updater, nullIfEmpty) {
  const emptyValue = nullIfEmpty ? null : "";
  const debounced = useRef(
    debounce(function _updateStore(dispatch, action) {
      dispatch(action);
    }, 1500)
  );
  const [value, setValue] = useState(emptyValue);
  const dispatch = useDispatch();
  const storeValue = useSelector((state) => {
    let node = state;
    for (let path of paths) {
      if (!node[path]) return emptyValue;
      node = node[path];
    }
    return node;
  });
  useEffect(() => {
    if (storeValue !== value) setValue(storeValue);
  }, [storeValue]);

  const onValueChanged = (newValue) => {
    setValue(newValue);
    debounced.current(dispatch, updater(newValue));
  };

  return [value, onValueChanged];
}

export default useInputRedux;
