import React from "react";
import { useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";

import Editor from "../../../../components/HighlightedEditor";
import useInputRedux from "../../../../hooks/useInputRedux";

function QuotePayments({ id }) {
  const saving = useSelector((state) => state.quotes.editor.saving);
  const [payments, setPayments] = useInputRedux(
    ["quotes", "editor", "details", "paymentDetails"],
    (value) => ({
      type: "SET_QUOTE_DETAIL",
      payload: { field: "paymentDetails", value, id },
    })
  );
  const [delivery, setDelivery] = useInputRedux(
    ["quotes", "editor", "details", "deliveryDetails"],
    (value) => ({
      type: "SET_QUOTE_DETAIL",
      payload: { field: "deliveryDetails", value, id },
    })
  );

  return (
    <Grid container spacing={2}>
      <Editor
        xs={12}
        sm={6}
        disabled={saving}
        message={delivery}
        setMessage={setDelivery}
        template="delivery"
        inputProps={{ placeholder: "Delivery details ..." }}
      />
      <Editor
        xs={12}
        sm={6}
        disabled={saving}
        message={payments}
        setMessage={setPayments}
        template="payment"
        inputProps={{ placeholder: "Payment details ..." }}
      />
    </Grid>
  );
}

export default QuotePayments;
