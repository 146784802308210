import { getImageURL } from "../../lib/image";
const update = require("immutability-helper");

const initialState = {
  error: null,
  loading: true,

  id: null,
  title: null,
  message: null,
  paymentDetails: null,
  deliveryDetails: null,
  items: null,
  companyName: null,
  companyLogo: null,
  currency: null,
  taxRate: null,
  discount: null,
  date: null,

  subtotal: null,
  tax: null,
  total: null,
};

function skus(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case "GET_QUOTE_FOR_VIEWER":
      return update(initialState, {
        loading: {
          $set: true,
        },
      });
    case "GET_QUOTE_FOR_VIEWER_SUCCESS": {
      const {
        id,
        title,
        message,
        payment_details,
        delivery_details,
        items,
        company_name,
        company_logo,
        currency,
        discount,
        created_at,
        tax_rate,
      } = payload;

      const taxRate = tax_rate / 100;
      let subtotal = 0;
      for (let i = 0; i < items.length; i += 1) {
        if (!items[i].sku) continue;
        if (items[i].image) items[i].image = getImageURL(items[i].image);
        subtotal += items[i].qty * items[i].unit;
      }

      const discountAmount =
        Number.parseFloat(discount) !== Number.NaN
          ? Number.parseFloat(discount)
          : 0;
      const afterDiscount = subtotal - discountAmount;
      const dateCreated = new Date(created_at);

      return update(state, {
        id: { $set: id },
        loading: { $set: false },
        title: { $set: title },
        message: { $set: message.replace(/\n/g, "\\\n") },
        paymentDetails: { $set: payment_details.replace(/\n/g, "\\\n") },
        deliveryDetails: { $set: delivery_details.replace(/\n/g, "\\\n") },
        items: { $set: items },
        companyName: { $set: company_name },
        companyLogo: { $set: company_logo && getImageURL(company_logo) },
        currency: { $set: currency },
        discount: { $set: discount },
        date: {
          $set: dateCreated.toLocaleDateString(),
        },
        taxRate: { $set: tax_rate },
        subtotal: { $set: subtotal },
        tax: { $set: afterDiscount * taxRate },
        total: { $set: afterDiscount * (1 + taxRate) },
      });
    }
    default:
      return state;
  }
}

export default skus;
