import { getImageURL } from "../../lib/image";
const update = require("immutability-helper");

const initialState = {
  error: null,
  done: false,
  loading: true,
  processing: true,
  level: null,
  picture: null,
  userId: null,
  name: null,
  email: null,
  companyName: null,
  companyLogo: null,
  password: null,
  passwordConfirm: null,
};

function onboarding(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case "SET_ONBOARDING_DETAIL": {
      const { field, value } = action.payload;
      return update(state, {
        error: {
          $set: null,
        },
        [field]: {
          $set: value,
        },
      });
    }
    case "GET_ONBOARDING_DETAILS":
      return update(initialState, {
        loading: {
          $set: true,
        },
      });
    case "GET_ONBOARDING_DETAILS_SUCCESS": {
      const { id, email, level, name, company, picture } = payload;
      return update(state, {
        loading: {
          $set: false,
        },
        userId: {
          $set: id,
        },
        email: {
          $set: email,
        },
        name: {
          $set: name,
        },
        level: {
          $set: level,
        },
        companyName: {
          $set: company.name,
        },
        picture: {
          $set: picture && getImageURL(picture),
        },
        companyLogo: {
          $set: company.logo && getImageURL(company.logo),
        },
      });
    }
    case "ACTIVATE_ACCOUNT_SUCCESS": {
      return update(state, {
        processing: {
          $set: false,
        },
      });
    }
    case "ACTIVATE_ACCOUNT_ERROR":
    case "UPLOAD_ONBOARDING_PICTURE_ERROR":
    case "GET_ONBOARDING_DETAILS_ERROR":
      return update(state, {
        loading: {
          $set: false,
        },
        error: {
          $set: payload,
        },
      });
    case "UPLOAD_ONBOARDING_PICTURE_SUCCESS": {
      return update(state, {
        picture: {
          $set: getImageURL(payload.image),
        },
      });
    }
    default:
      return state;
  }
}

export default onboarding;
