import { push } from "connected-react-router";
import { eventChannel } from "redux-saga";
import { put, take, takeEvery } from "redux-saga/effects";
import { fnUpload } from "../fn";

function createSaga(name, fnName, loaderActionType, path) {
  function* _importHandlerSaga({ payload }) {
    const chan = eventChannel((emit) => {
      fnUpload(
        fnName,
        payload,
        (progress) => emit({ type: "PROGRESS", progress }),
        (data) => emit({ type: "DONE", data }),
        (error) => emit({ type: "ERROR", error })
      );
      return () => {};
    });
    try {
      while (true) {
        const event = yield take(chan);
        if (event.type === "PROGRESS") {
          yield put({
            type: `IMPORT_${name}_PROGRESS`,
            payload: event.progress,
          });
        } else if (event.type === "DONE") {
          const { success, inserted, updated, deleted } = event.data;

          if (!success) {
            yield put({
              type: `IMPORT_${name}_ERROR`,
              payload:
                "Fail to perform import. Please try again later. If this error doesn't disappear, please contact support.",
            });
            return;
          }

          yield put({
            type: loaderActionType,
          });
          yield put(push(path));
          yield put({
            type: `IMPORT_${name}_SUCCESS`,
            payload: { inserted, updated, deleted },
          });
          return;
        } else {
          throw event.error;
        }
      }
    } catch (e) {
      yield put({
        type: `IMPORT_${name}_ERROR`,
        payload: e.message || "Unknown Error",
      });
    } finally {
      chan.close();
    }
  }

  function* _importerSaga() {
    yield takeEvery(`IMPORT_${name}`, _importHandlerSaga);
  }

  return _importerSaga;
}

export default createSaga;
