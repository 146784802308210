function getAuditText({ type, actor_name, actor_type }) {
  switch (type) {
    case "NEW":
      return `${actor_name} created the document`;
    case "UPDATE":
      return `${actor_name} edited the document`;
    case "SEND":
      return `${actor_name} finalised and sent the document`;
    case "VIEW":
      return `${actor_name} openned the link`;
    case "DOWNLOAD":
      return `${actor_name} downloaded the quotation`;
  }
}

export { getAuditText };
