import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import QuoteList from "./QuoteList";
import QuoteContent from "./QuoteContent";
import { LayoutSwitcher } from "../../components/Layout";

function Quotes() {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) dispatch({ type: "GET_QUOTE", payload: { id } });
  }, [id]);

  useEffect(() => {
    dispatch({ type: "GET_SETTING_CURRENCY" });
  }, []);

  return (
    <LayoutSwitcher
      sidebar={<QuoteList id={id} />}
      main={<QuoteContent id={id} />}
      isActive={!!id}
    />
  );
}

export default Quotes;
