const update = require("immutability-helper");

const initialState = {
  loading: false,
  message: null,
  error: null,
};

function password(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case "CLEAR_RESET_PASSWORD":
      return initialState;

    case "RESET_PASSWORD":
    case "REQUEST_RESET_PASSWORD":
      return update(initialState, {
        loading: {
          $set: true,
        },
        error: {
          $set: null,
        },
      });
    case "RESET_PASSWORD_FAILED":
    case "REQUEST_RESET_PASSWORD_FAILED": {
      return update(state, {
        loading: {
          $set: false,
        },
        error: {
          $set: payload,
        },
      });
    }
    case "RESET_PASSWORD_SUCCESS": {
      return update(state, {
        message: {
          $set: payload,
        },
      });
    }
    case "REQUEST_RESET_PASSWORD_SUCCESS": {
      return update(state, {
        loading: {
          $set: false,
        },
        message: {
          $set: payload,
        },
      });
    }
    default:
      return state;
  }
}

export default password;
