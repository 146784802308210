import React from "react";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import useStyles from "./style";

function GenericItem({ id, page, title, subtitle, selected, style }) {
  const history = useHistory();
  const classes = useStyles();

  return (
    <ListItem
      selected={selected}
      key={id}
      style={style}
      component="a"
      button
      href={`/${page}/${id}`}
      onClick={(e) => {
        e.preventDefault();
        history.push(`/${page}/${id}`);
      }}
    >
      <ListItemText
        primary={
          <Typography noWrap variant="subtitle2" className={classes.title}>
            {title}
          </Typography>
        }
        secondary={<Typography variant="caption">{subtitle}</Typography>}
      />
    </ListItem>
  );
}

export default GenericItem;
