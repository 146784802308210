import Promise from "bluebird";
import { push } from "connected-react-router";
import { eventChannel } from "redux-saga";
import { takeEvery, take, put, select } from "redux-saga/effects";
import { fn, fnUpload } from "../../../lib/fn";

function* handleGetCompany() {
  const data = yield fn("get-company");
  if (!data) {
    yield put(push("/settings"));
    return;
  }
  yield put({
    type: "GET_SETTING_COMPANY_SUCCESS",
    payload: data,
  });
}

function* handleUpdateCompany() {
  try {
    // give time to the debounced redux updater
    yield Promise.delay(1500);
    const editor = yield select((state) => state.settings.company);
    const { success } = yield fn("update-company", {
      name: editor.name,
      phone: editor.phone || null,
      address: editor.address || null,
    });

    if (!success) {
      throw new Error("Fail to update the database. Please try again later.");
    }
    yield put({
      type: "UPDATE_SETTING_COMPANY_SUCCESS",
    });
  } catch (e) {
    yield put({
      type: "UPDATE_SETTING_COMPANY_ERROR",
      payload: e.message || "Unknown Error",
    });
  }
}

function* handleUploadCompanyLogo({ payload }) {
  yield put({
    type: "SHOW_LOADING",
    payload: { message: "Uploading ..." },
  });
  const chan = eventChannel((emit) => {
    fnUpload(
      "upload-company-logo",
      payload,
      (progress) => emit({ type: "PROGRESS", progress }),
      (data) => emit({ type: "DONE", data }),
      (error) => emit({ type: "ERROR", error })
    );
    return () => {};
  });

  try {
    while (true) {
      const event = yield take(chan);
      if (event.type === "PROGRESS") {
        yield put({
          type: "SHOW_LOADING",
          payload: {
            message:
              event.progress < 100
                ? `(${event.progress.toFixed(2)}%) Uploading ...`
                : "Processing ...",
          },
        });
      } else if (event.type === "DONE") {
        yield put({
          type: "UPLOAD_SETTING_COMPANY_PICTURE_SUCCESS",
          payload: { image: event.data.id },
        });
        return;
      } else {
        throw event.error;
      }
    }
  } catch (e) {
    yield put({
      type: "UPLOAD_SETTING_COMPANY_PICTURE_ERROR",
      payload: e.message || "Unknown Error",
    });
  } finally {
    chan.close();
    yield put({ type: "HIDE_LOADING" });
  }
}

function* handleRemoveCompanyLogo() {
  yield put({
    type: "SHOW_LOADING",
    payload: { message: "Removing image ..." },
  });

  try {
    const { success } = yield fn("remove-company-logo");
    if (!success) {
      throw new Error("Fail to update the database. Please try again later.");
    }
    yield put({
      type: "REMOVE_SETTING_COMPANY_PICTURE_SUCCESS",
    });
  } catch (e) {
    yield put({
      type: "REMOVE_SETTING_COMPANY_PICTURE_ERROR",
      payload: e.message || "Unknown Error",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* companySettingSaga() {
  yield takeEvery("GET_SETTING_COMPANY", handleGetCompany);
  yield takeEvery("UPDATE_SETTING_COMPANY", handleUpdateCompany);
  yield takeEvery("UPLOAD_SETTING_COMPANY_PICTURE", handleUploadCompanyLogo);
  yield takeEvery("REMOVE_SETTING_COMPANY_PICTURE", handleRemoveCompanyLogo);
}

export { companySettingSaga };
