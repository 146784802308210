const update = require("immutability-helper");

const initialState = {
  error: null,
  show: false,
  step: 1,
  importResult: null,
  importType: "append",
  processing: false,
  uploadProgress: 0,
};

function products(state = initialState, action) {
  switch (action.type) {
    case "SHOW_IMPORT_PRODUCTS": {
      return update(initialState, {
        show: {
          $set: true,
        },
      });
    }
    case "CLOSE_IMPORT_PRODUCTS": {
      return update(state, {
        show: {
          $set: false,
        },
      });
    }
    case "IMPORT_PRODUCT": {
      return update(state, {
        processing: {
          $set: true,
        },
        step: {
          $set: 4,
        },
      });
    }
    case "IMPORT_PRODUCT_SET_STEP": {
      return update(state, {
        step: {
          $set: action.payload,
        },
      });
    }
    case "IMPORT_PRODUCT_SET_TYPE": {
      return update(state, {
        importType: {
          $set: action.payload,
        },
      });
    }
    case "IMPORT_PRODUCT_PROGRESS": {
      return update(state, {
        uploadProgress: {
          $set: action.payload,
        },
      });
    }
    case "IMPORT_PRODUCT_SUCCESS": {
      return update(state, {
        importResult: {
          $set: action.payload,
        },
        processing: {
          $set: false,
        },
      });
    }
    case "IMPORT_PRODUCT_ERROR": {
      return update(state, {
        error: {
          $set: action.payload,
        },
        processing: {
          $set: false,
        },
      });
    }

    default:
      return state;
  }
}

export default products;
