import React from "react";
import Markdown from "react-markdown";

function Preview({ idx, total, email, message, payment, delivery }) {
  return (
    <Markdown
      children={`### To: ${email} [${
        idx + 1
      }/${total}]\n${message}\n\n---\n### Delivery Details\n${delivery}\n\n---\n### Payment Details\n${payment}`}
    />
  );
}

export default Preview;
