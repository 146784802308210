import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import UserSelector from "../../../../components/UserSelector";

import useStyle from "./styles";

function ShareDialog({ quoteId }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const error = useSelector((state) => state.quotes.editor.shareError);
  const show = useSelector((state) => state.quotes.editor.showShare);
  const loading = useSelector((state) => state.quotes.editor.sharingLoading);
  const [user, setUser] = useState(null);

  const onClose = () => {
    if (loading) return;
    setUser(null);
    dispatch({
      type: "HIDE_SHARE",
    });
  };

  const onConfirm = () => {
    dispatch({
      type: "SHARE_QUOTE",
      payload: { quoteId, user: user.id },
    });
  };

  return (
    <Dialog
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      classes={{ root: classes.shareDialog }}
      open={show}
    >
      <DialogTitle disableTypography>
        <Typography variant="h6">Share Quote</Typography>
        <IconButton
          className={classes.closeButton}
          disabled={loading}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {error && <Alert severity="error">{error}</Alert>}
        <UserSelector
          disabled={loading}
          required
          placeholder="Select User"
          value={user}
          onValueChanged={setUser}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onClose}>
          Cancel
        </Button>

        <Button disabled={!user || loading} onClick={onConfirm} color="primary">
          {loading ? <CircularProgress size={24} /> : "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShareDialog;
