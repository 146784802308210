import React, { useEffect, useState } from "react";
import { GooSpinner } from "react-spinners-kit";
import { useDispatch, useSelector } from "react-redux";
import { Beforeunload } from "react-beforeunload";
import { Prompt } from "react-router";
import Alert from "@material-ui/lab/Alert";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";

import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import PanoramaIcon from "@material-ui/icons/Panorama";

import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

import useInputRedux from "../../../hooks/useInputRedux";
import ConfirmDialog from "../../../components/ConfirmDialog";
import ContentLoading from "../../../components/ContentLoading";
import FileInput from "../../../components/FileInput";
import UserSelector from "../../../components/UserSelector";
import Empty from "./Empty";
import useStyles from "./style";

function UserEditor({ id }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);

  const me = useSelector((state) => state.auth.userId);
  const activeUser = useSelector((state) => state.users.editor.activeUser);
  const error = useSelector((state) => state.users.editor.error);
  const loading = useSelector((state) => state.users.editor.loading);
  const changed = useSelector((state) => state.users.editor.changed);
  const saving = useSelector((state) => state.users.editor.saving);

  const picture = useSelector((state) => state.users.editor.picture);
  const [email, setEmail] = useInputRedux(
    ["users", "editor", "email"],
    (value) => ({
      type: "SET_USER_DETAIL",
      payload: { field: "email", value, id },
    })
  );
  const [name, setName] = useInputRedux(
    ["users", "editor", "name"],
    (value) => ({
      type: "SET_USER_DETAIL",
      payload: { field: "name", value, id },
    })
  );
  const [level, setLevel] = useInputRedux(
    ["users", "editor", "level"],
    (value) => ({
      type: "SET_USER_DETAIL",
      payload: { field: "level", value, id },
    })
  );
  const [parent, setParent] = useInputRedux(
    ["users", "editor", "parent"],
    (value) => ({
      type: "SET_USER_DETAIL",
      payload: { field: "parent", value, id },
    }),
    true
  );
  useEffect(() => {
    setNameError(null);
    setEmailError(null);
  }, [id]);

  const validateAndSubmit = () => {
    let error = false;
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(email.toLowerCase())) {
      setEmailError("Please provide a valid email address.");
      error = true;
    }
    if (name.trim().length === 0) {
      setNameError("Name is required.");
      error = true;
    }
    if (error) return;
    dispatch({
      type: newUser ? "NEW_USER" : "UPDATE_USER",
    });
  };

  if (!id) return <Empty />;
  if (loading || activeUser !== id) return <ContentLoading />;

  const isMyself = me === id;
  const newUser = id === "new";
  const title = newUser ? "New User" : name;
  return (
    <>
      <OverlayScrollbarsComponent className={classes.main}>
        <Paper className={classes.paper}>
          <AppBar
            component="div"
            className={classes.appBar}
            color="paper"
            position="static"
            elevation={0}
          >
            <Toolbar className={classes.toolbar}>
              <Typography
                variant="h5"
                id="tableTitle"
                component="div"
                className={classes.toolbarTitle}
              >
                {isMyself && !changed && (
                  <Badge badgeContent={"Myself"} color="primary">
                    {title}
                  </Badge>
                )}
                {changed && (
                  <Badge badgeContent={"Unsaved"} color="error">
                    {title}
                  </Badge>
                )}
                {!isMyself && !changed && title}
              </Typography>
              {saving && (
                <div className={classes.loading}>
                  <GooSpinner size={24} color="#000" />
                </div>
              )}
              {!saving && (
                <IconButton
                  color="inherit"
                  disabled={!changed}
                  onClick={validateAndSubmit}
                >
                  <SaveIcon />
                </IconButton>
              )}

              {!newUser && (
                <IconButton
                  disabled={isMyself}
                  color="inherit"
                  onClick={() => setDeleting("user")}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Toolbar>
          </AppBar>
          <Divider />
          <Grid container className={classes.content} spacing={2}>
            {error && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            <Grid
              container
              item
              spacing={1}
              xs={12}
              sm={3}
              lg={2}
              direction="column"
            >
              <Grid item>
                <Avatar
                  variant="square"
                  className={classes.picture}
                  src={picture}
                >
                  <PanoramaIcon />
                </Avatar>
              </Grid>
              <Grid item>
                <FileInput
                  buttonText="Upload Picture"
                  toolTip={newUser && "You can upload picture after saving."}
                  buttonProps={{
                    fullWidth: true,
                    size: "small",
                    variant: "outlined",
                    disabled: newUser,
                  }}
                  onSelected={(file) => {
                    dispatch({
                      type: "UPLOAD_USER_PICTURE",
                      payload: { id, image: file },
                    });
                  }}
                />
              </Grid>
              {picture && (
                <Grid item>
                  <Button
                    fullWidth
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={() => setDeleting("picture")}
                  >
                    Remove Picture
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} sm={9} lg={10} container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  autoFocus
                  placeholder="name@company.com"
                  disabled={saving}
                  label="Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError(null);
                  }}
                  error={!!emailError}
                  helperText={emailError}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  disabled={saving}
                  placeholder="Name"
                  label="Full Name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameError(null);
                  }}
                  error={!!nameError}
                  helperText={nameError}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth required disabled={saving}>
                  <InputLabel shrink id="user-editor-user-type-label">
                    Account Type
                  </InputLabel>
                  <Select
                    labelId="user-editor-user-type-label"
                    id="user-editor-user-type-select"
                    value={level}
                    onChange={(e) => setLevel(e.target.value)}
                  >
                    <MenuItem value="USR">Staff</MenuItem>
                    <MenuItem value="ADM">Administrator</MenuItem>
                  </Select>
                  <FormHelperText>&nbsp;</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <UserSelector
                  label="Supervisor"
                  placeholder="Select Supervisor (optional)"
                  value={parent}
                  onValueChanged={setParent}
                  disabled={saving}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <div className={classes.spacer} />
        {changed && (
          <>
            <Beforeunload
              onBeforeunload={() =>
                "You have unsaved changes. Are you sure? Unsaved changes will lost."
              }
            />
            <Prompt message="You have unsaved changes. Are you sure? Unsaved changes will lost." />
          </>
        )}
      </OverlayScrollbarsComponent>
      <ConfirmDialog
        show={deleting}
        onClose={() => setDeleting(null)}
        onConfirm={() => {
          setDeleting(null);
          dispatch({
            type: deleting === "user" ? "DELETE_USER" : "REMOVE_USER_PICTURE",
            payload: { id, name },
          });
        }}
        title={`Deleting ...`}
        message={
          deleting === "user" ? (
            <>
              Are you sure you want to delete <strong>{name}</strong>? This
              action cannot be undone.
            </>
          ) : (
            "Are you sure you want to remove this user's picture? This action cannot be undone."
          )
        }
      />
    </>
  );
}

export default UserEditor;
