import React, { useRef } from "react";
import * as uuid from "uuid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";

function CurrencySelector({ label, value, onChange, disabled }) {
  const id = useRef(uuid.v4());

  return (
    <FormControl fullWidth required disabled={disabled}>
      <InputLabel shrink id={`${id}-label`}>
        {label}
      </InputLabel>
      <Select
        labelId={`${id}-label`}
        id={`${id}-select`}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        <MenuItem value="USD">USD / $</MenuItem>
        <MenuItem value="IDR">IDR / Rp</MenuItem>
        <MenuItem value="AUD">AUD / A$</MenuItem>
        <MenuItem value="SGD">SGD / S$</MenuItem>
        <MenuItem value="HKD">HKD / H$</MenuItem>
        <MenuItem value="EUR">EUR / €</MenuItem>
        <MenuItem value="JPY">JPY / JP¥</MenuItem>
        <MenuItem value="CNY">CNY / CN¥</MenuItem>
      </Select>
      <FormHelperText>&nbsp;</FormHelperText>
    </FormControl>
  );
}

export default CurrencySelector;
