import React from "react";
import { useSelector } from "react-redux";

import List from "../../../components/GenericList";
import ListItem from "../../../components/GenericList/Item";

const settings = [
  {
    id: "profile",
    name: "Your Account",
    description: "Your personal details and password",
    adminOnly: false,
  },
  {
    id: "company",
    name: "Company Profile",
    description: "Company details such as name and logo",
    adminOnly: true,
  },
  {
    id: "currency",
    name: "Tax and Currency",
    description: "Tax, currency and exchange rates",
    adminOnly: true,
  },
  {
    id: "message",
    name: "Message Templates",
    description: "Manage message templates",
    adminOnly: false,
  },
  {
    id: "delivery",
    name: "Delivery Templates",
    description: "Manage delivery templates",
    adminOnly: false,
  },
  {
    id: "payment",
    name: "Payment Templates",
    description: "Manage payment templates",
    adminOnly: false,
  },
];

function SettingList({ id }) {
  const level = useSelector((state) => state.auth.level);

  return (
    <List>
      {settings
        .filter((setting) => level === "ADM" || !setting.adminOnly)
        .map((setting) => (
          <ListItem
            key={setting.id}
            id={setting.id}
            title={setting.name}
            subtitle={setting.description}
            selected={setting.id === id}
            page="setting"
          />
        ))}
    </List>
  );
}

export default SettingList;
