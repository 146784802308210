import React, { useEffect, useRef, useState } from "react";
import { push } from "connected-react-router";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";

import Copyright from "../../components/Copyright";
import useQuery from "../../hooks/useQuery";
import useStyles from "./style";

export default function SignInSide() {
  const classes = useStyles();
  const query = useQuery();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const txtEmail = useRef(null);
  const txtPassword = useRef(null);
  const txtPasswordConfirm = useRef(null);
  const [passwordError, setPasswordError] = useState(null);

  const flashMessage = useSelector((state) => state.auth.flashMessage);
  const loadingLogin = useSelector((state) => state.auth.loading);
  const loadingError = useSelector((state) => state.auth.error);

  const resetError = useSelector((state) => state.password.error);
  const resetMessage = useSelector((state) => state.password.message);
  const loadingReset = useSelector((state) => state.password.loading);

  const error = loadingError || resetError;
  const loading = loadingLogin || loadingReset;
  const isRequestResetPassword = pathname === "/password-request";
  const isResetPassword = pathname === "/password-reset";

  useEffect(() => {
    if (isResetPassword && txtEmail.current) {
      console.log("hello", query.get("email"));
      txtEmail.current.value = query.get("email") || "";
    }
  }, [isResetPassword]);

  const handleSubmit = () => {
    setPasswordError(null);
    if (isResetPassword) {
      if (txtPassword.current.value !== txtPasswordConfirm.current.value) {
        setPasswordError("Password didn't match!");
        return;
      }
      if (txtPassword.current.value.length < 6) {
        setPasswordError("Minimum password length is 6");
        return;
      }
      dispatch({
        type: "RESET_PASSWORD",
        payload: {
          auth: query.get("auth"),
          password: txtPassword.current.value,
        },
      });
      return;
    }

    if (isRequestResetPassword) {
      dispatch({
        type: "REQUEST_RESET_PASSWORD",
        payload: {
          email: txtEmail.current.value,
        },
      });
      return;
    }

    dispatch({
      type: "LOGIN",
      payload: {
        email: txtEmail.current.value,
        password: txtPassword.current.value,
      },
    });
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {isRequestResetPassword || isResetPassword
              ? "Reset your password"
              : "Sign in"}
          </Typography>
          {error && (
            <Alert severity="error" className={classes.alert}>
              {error}
            </Alert>
          )}
          {flashMessage && (
            <Alert severity="success" className={classes.alert}>
              Hi! Welcome back {flashMessage}!
            </Alert>
          )}
          {resetMessage && (
            <Alert severity="success" className={classes.alert}>
              {resetMessage}
            </Alert>
          )}
          <form
            className={classes.form}
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              return false;
            }}
          >
            <TextField
              disabled={loading || isResetPassword}
              inputRef={txtEmail}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            {!isRequestResetPassword && !isResetPassword && (
              <TextField
                disabled={loading}
                inputRef={txtPassword}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
            )}
            {isResetPassword && (
              <TextField
                autoFocus
                disabled={loading}
                inputRef={txtPassword}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="New Password"
                type="password"
                id="password"
                autoComplete="new-password"
                error={!!passwordError}
                helperText={passwordError}
              />
            )}
            {isResetPassword && (
              <TextField
                disabled={loading}
                inputRef={txtPasswordConfirm}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Confirm Password"
                type="password"
                id="confirm-password"
                autoComplete="new-password"
                error={!!passwordError}
                helperText={passwordError}
              />
            )}
            <Button
              type="submit"
              disabled={loading}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmit}
            >
              {isRequestResetPassword || isResetPassword
                ? "Reset Password"
                : "Sign In"}
            </Button>
            {!isResetPassword && (
              <Grid container>
                <Grid item xs>
                  <Link
                    href={isRequestResetPassword ? "/" : "/password-request"}
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(
                        push(isRequestResetPassword ? "/" : "/password-request")
                      );
                    }}
                    variant="body2"
                  >
                    {isRequestResetPassword
                      ? "Back to login"
                      : "Forgot password?"}
                  </Link>
                </Grid>
              </Grid>
            )}
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
