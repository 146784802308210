import { makeStyles } from "@material-ui/core/styles";
import { indigo } from "@material-ui/core/colors";

export default makeStyles((theme) => ({
  container: {
    zIndex: 99999,
  },
  header: {
    color: theme.palette.getContrastText(indigo[500]),
    backgroundColor: "#232f3e",
  },
  secondaryBar: {
    paddingTop: theme.spacing(1),
    zIndex: 0,
    // paddingBottom: theme.spacing(1),
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: "none",
    color: "rgba(255, 255, 255, 0.7)",
    "&:hover": {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: "rgba(255, 255, 255, 0.7)",
  },
}));
