import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import ImportDialog from "../../../components/ImportDialog";
import { fnDownloadURL } from "../../../lib/fn";

function ContactImporter() {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.contacts.import.show);
  const step = useSelector((state) => state.contacts.import.step);
  const error = useSelector((state) => state.contacts.import.error);
  const processing = useSelector((state) => state.contacts.import.processing);
  const importResult = useSelector(
    (state) => state.contacts.import.importResult
  );
  const uploadProgress = useSelector(
    (state) => state.contacts.import.uploadProgress
  );
  const importType = useSelector((state) => state.contacts.import.importType);

  const [importFiles, setImportFiles] = useState([]);

  return (
    <ImportDialog
      name="Contacts"
      itemUnits="contacts"
      show={show}
      step={step}
      error={error}
      processing={processing}
      importResult={importResult}
      uploadProgress={uploadProgress}
      importType={importType}
      onClose={() => dispatch({ type: "CLOSE_IMPORT_CONTACTS" })}
      onConfirm={() =>
        dispatch({
          type: "IMPORT_CONTACTS",
          payload: {
            contacts: importFiles[0],
            type: importType,
          },
        })
      }
      setStep={(step) =>
        dispatch({ type: "IMPORT_CONTACTS_SET_STEP", payload: step })
      }
      onImportTypeSelected={(type) =>
        dispatch({ type: "IMPORT_CONTACTS_SET_TYPE", payload: type })
      }
      onFileSelected={setImportFiles}
      templateURL={fnDownloadURL("get-contact-template")}
    />
  );
}

export default ContactImporter;
