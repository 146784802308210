import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";

import theme from "./theme";
import Header from "./components/Header";
import ErrorDialog from "./components/ErrorDialog";
import LoadingDialog from "./components/LoadingDialog";
import SessionDialog from "./components/SessionDialog";
import { LayoutProvider } from "./components/Layout";

import Login from "./pages/Login";
import Onboarding from "./pages/Onboarding";
import Viewer from "./pages/Viewer";

import Quotes from "./pages/Quotes";
import Products from "./pages/Products";
import Contacts from "./pages/Contacts";
import Users from "./pages/Users";
import Settings from "./pages/Settings";

const useStyle = makeStyles(() => ({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    background: "#eaeff1",
  },
}));

function App() {
  const classes = useStyle();
  const loggedIn = useSelector((state) => state.auth.loggedIn);

  return (
    <ThemeProvider theme={theme}>
      <LayoutProvider>
        <CssBaseline />

        {loggedIn ? (
          <div className={classes.root}>
            <Header />
            <Switch>
              <Route exact path="/" children={<Quotes />} />
              <Route path="/quote/:id" children={<Quotes />} />
              <Route path="/products" children={<Products />} />
              <Route path="/product/:id" children={<Products />} />
              <Route path="/contacts" children={<Contacts />} />
              <Route path="/contact/:id" children={<Contacts />} />
              <Route path="/users" children={<Users />} />
              <Route path="/user/:id" children={<Users />} />
              <Route path="/settings" children={<Settings />} />
              <Route path="/setting/:id/:selectedId" children={<Settings />} />
              <Route path="/setting/:id" children={<Settings />} />
              <Route
                path="/viewer"
                children={
                  <div>
                    You must log out / use incognito to view quotes as customer.
                  </div>
                }
              />
              <Route
                path="/onboarding"
                children={<div>You must log out to perform this action.</div>}
              />
            </Switch>
          </div>
        ) : (
          <Switch>
            <Route path="/viewer" children={<Viewer />} />
            <Route
              path="/onboarding"
              children={
                <div className={classes.root}>
                  <Onboarding />
                </div>
              }
            />
            <Route
              path="/"
              children={
                <div className={classes.root}>
                  <Login />
                </div>
              }
            />
          </Switch>
        )}
        <SessionDialog />
        <ErrorDialog />
        <LoadingDialog />
      </LayoutProvider>
    </ThemeProvider>
  );
}

export default App;
