import Promise from "bluebird";
import { put, take, delay, select } from "redux-saga/effects";
import { fn, setBearer } from "../../lib/fn";

function* loginSaga() {
  try {
    const auth = JSON.parse(window.localStorage.getItem("x-auth-data"));
    const current = Math.floor(new Date().getTime() / 1000);
    if (current < auth.token_expire) {
      setBearer(auth.token);
      yield put({ type: "AUTHENTICATE", payload: auth });
    }
  } catch (e) {}

  while (true) {
    const { type, payload } = yield take(["LOGIN", "LOGOUT"]);

    if (type === "LOGOUT") {
      window.localStorage.removeItem("x-auth-data");
      window.location = "/";
      return;
    }

    try {
      const data = yield fn("login", payload);
      window.localStorage.setItem("x-auth-data", JSON.stringify(data));
      yield put({ type: "LOGIN_SUCCESS", payload: data.name });
      yield delay(2500);
      setBearer(data.token);
      yield put({ type: "AUTHENTICATE", payload: data });
    } catch (e) {
      yield put({ type: "LOGIN_ERROR", payload: e.message });
    }
  }
}

function* sessionSaga() {
  yield take("AUTHENTICATE");
  while (true) {
    yield Promise.delay(10 * 1000);
    const expire = yield select((state) => state.auth.tokenExpire);
    const current = Math.floor(new Date().getTime() / 1000);
    if (current + 15 * 60 > expire) {
      yield put({ type: "SESSION_EXPIRED" });
      yield take("AUTHENTICATE");
    }
  }
}

export { loginSaga, sessionSaga };
