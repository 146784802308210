import Promise from "bluebird";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Draggable } from "react-beautiful-dnd";
import TableRow from "@material-ui/core/TableRow";

import EditView from "./EditView";
import ItemView from "./ItemView";
import DeleteView from "./DeleteView";
import SeparatorView from "./SeparatorView";
import EditSeparator from "./EditSeparator";
import { useLayout } from "../../../../components/Layout";

function QuoteItem({ item, index }) {
  const dispatch = useDispatch();
  const saving = useSelector((state) => state.quotes.editor.saving);
  const [editing, setEditing] = useState(item.sku === "");
  const [deleting, setDeleting] = useState(false);
  const { mobile } = useLayout();

  useEffect(() => {
    if (saving) {
      setEditing(false);
      setDeleting(false);
    }
  }, [saving]);

  const onClickEdit = async () => {
    await Promise.delay(200);
    setEditing(true);
  };

  const onClickDelete = async () => {
    await Promise.delay(200);
    setDeleting(true);
  };

  const onCancelEdit = async () => {
    await Promise.delay(200);
    setEditing(false);
    if (item.sku === "")
      dispatch({ type: "REMOVE_QUOTE_ITEM", payload: { ref: item.ref } });
  };

  const onCancelDelete = async () => {
    await Promise.delay(200);
    setDeleting(false);
  };

  const onConfirmDelete = async () => {
    await Promise.delay(200);
    setDeleting(false);
    dispatch({ type: "REMOVE_QUOTE_ITEM", payload: { ref: item.ref } });
  };

  const onSaveEdit = async (item) => {
    dispatch({ type: "UPDATE_QUOTE_ITEM_DETAIL", payload: { index, item } });
    await Promise.delay(200);
    setEditing(false);
  };

  const getView = () => {
    if (editing && item.sku === null)
      return (
        <EditSeparator
          item={item}
          onCancel={onCancelEdit}
          onSave={onSaveEdit}
        />
      );
    if (editing)
      return (
        <EditView
          item={item}
          mobile={mobile}
          onCancel={onCancelEdit}
          onSave={onSaveEdit}
        />
      );
    if (deleting)
      return (
        <DeleteView
          onConfirmDelete={onConfirmDelete}
          onCancelDelete={onCancelDelete}
        />
      );
    if (item.sku === null)
      return (
        <SeparatorView
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
          text={item.txt}
        />
      );
    return (
      <ItemView
        item={item}
        mobile={mobile}
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
      />
    );
  };

  return (
    <Draggable draggableId={item.ref} index={index} isDragDisabled={saving}>
      {(provided) => (
        <TableRow
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            background: "#fff",
            ...provided.draggableProps.style,
          }}
        >
          {getView()}
        </TableRow>
      )}
    </Draggable>
  );
}

export default QuoteItem;
