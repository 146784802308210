import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

export default makeStyles((theme) => ({
  main: {
    flexGrow: 1,
    width: "100%",
    padding: theme.spacing(4),
    background: "#eaeff1",
  },
  appBar: {
    borderTopLeftRadius: theme.spacing(),
    borderTopRightRadius: theme.spacing(),
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  toolbarTitle: {
    flex: "1",
  },
  content: {
    padding: theme.spacing(2),
    "& .MuiTextField-root": {
      paddingBottom: theme.spacing(2),
    },
  },
  picture: {
    width: "100%",
    height: theme.spacing(16),
  },
  spacer: {
    width: "100%",
    height: theme.spacing(5),
  },
  loading: {
    overflow: "hidden",
    width: 24,
    height: 24,
  },
}));
