import React from "react";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";

function NumberFormatCustom(props) {
  const { inputRef, onChange, currency, suffix, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix={currency}
      suffix={suffix}
    />
  );
}

function NumberField({ currency, suffix, ...props }) {
  return (
    <TextField
      {...props}
      InputProps={{
        inputComponent: NumberFormatCustom,
        inputProps: { currency, suffix },
      }}
    />
  );
}

export default NumberField;
