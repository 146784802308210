import React from "react";
import { useSelector } from "react-redux";

import Badge from "@material-ui/core/Badge";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

import { getCurrencySymbol } from "../../../../lib/currency";

function ItemView({ item, onClickEdit, onClickDelete }) {
  const saving = useSelector((state) => state.quotes.editor.saving);
  const sellingCurrency = useSelector(
    (state) => state.settings.currency.selling
  );
  const currency = `${sellingCurrency} `;
  return (
    <>
      <TableCell width="5%">
        <DragIndicatorIcon />
      </TableCell>
      <TableCell width="5%" align="right">
        <IconButton color="inherit" onClick={onClickEdit} disabled={saving}>
          <EditIcon fontSize="small" />
        </IconButton>
        <IconButton color="inherit" onClick={onClickDelete} disabled={saving}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </TableCell>
      <TableCell width="45%">
        <Typography variant="overline" display="block">
          {item.sku}
        </Typography>
        <Typography variant="body1" display="block">
          {item.name}
        </Typography>
      </TableCell>
      <TableCell width="15%" align="right">
        {item.qty.toLocaleString()} {item.unitUnit}
      </TableCell>
      <TableCell width="15%" align="right">
        {item.cost && item.cost > item.unit && (
          <Tooltip
            title={`Price is below COGS of ${currency} ${item.cost.toLocaleString()}`}
          >
            <Badge color="secondary" variant="dot">
              {currency} {item.unit.toLocaleString()}
            </Badge>
          </Tooltip>
        )}
        {(!item.cost || item.cost <= item.unit) && (
          <>
            {currency} {item.unit.toLocaleString()}
          </>
        )}
      </TableCell>
      <TableCell width="10%" align="right">
        {currency} {(item.qty * item.unit).toLocaleString()}
      </TableCell>
    </>
  );
}

export default ItemView;
