import { put, take, takeEvery, delay, select } from "redux-saga/effects";
import { push } from "connected-react-router";
import { eventChannel } from "redux-saga";
import { fn, fnUpload } from "../../lib/fn";

function* handleActivateAccount({ payload }) {
  const { auth } = payload;
  try {
    yield delay(1500);
    const editor = yield select((state) => state.onboarding);
    const { success } = yield fn(
      "update-profile",
      {
        name: editor.name || "",
        email: editor.email || "",
        password: editor.password || "",
      },
      { auth }
    );

    if (!success) {
      throw new Error("Fail to update the database. Please try again later.");
    }
    yield put({
      type: "ACTIVATE_ACCOUNT_SUCCESS",
    });
    yield delay(2500);
    yield put(push("/"));
  } catch (e) {
    yield put({
      type: "ACTIVATE_ACCOUNT_ERROR",
      payload: e.message || "Unknown Error",
    });
  }
}

function* handleGetDetails({ payload }) {
  const { auth } = payload;
  const details = yield fn("get-onboarding-details", null, { auth });
  yield put({ type: "GET_ONBOARDING_DETAILS_SUCCESS", payload: details });
}

function* handleUploadPicture({ payload }) {
  const { auth, userId, image } = payload;
  yield put({
    type: "SHOW_LOADING",
    payload: { message: "Uploading ..." },
  });
  const chan = eventChannel((emit) => {
    fnUpload(
      "upload-user-image",
      { id: userId, image },
      (progress) => emit({ type: "PROGRESS", progress }),
      (data) => emit({ type: "DONE", data }),
      (error) => emit({ type: "ERROR", error }),
      { auth }
    );
    return () => {};
  });

  try {
    while (true) {
      const event = yield take(chan);
      if (event.type === "PROGRESS") {
        yield put({
          type: "SHOW_LOADING",
          payload: {
            message:
              event.progress < 100
                ? `(${event.progress.toFixed(2)}%) Uploading ...`
                : "Processing ...",
          },
        });
      } else if (event.type === "DONE") {
        yield put({
          type: "UPLOAD_ONBOARDING_PICTURE_SUCCESS",
          payload: { image: event.data.id },
        });
        return;
      } else {
        throw event.error;
      }
    }
  } catch (e) {
    yield put({
      type: "UPLOAD_ONBOARDING_PICTURE_ERROR",
      payload: e.message || "Unknown Error",
    });
  } finally {
    chan.close();
    yield put({ type: "HIDE_LOADING" });
  }
}

function* onboardingSagas() {
  yield takeEvery("ACTIVATE_ACCOUNT", handleActivateAccount);
  yield takeEvery("UPLOAD_ONBOARDING_PICTURE", handleUploadPicture);
  yield takeEvery("GET_ONBOARDING_DETAILS", handleGetDetails);
}

export { onboardingSagas };
