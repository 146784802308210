export default function renderString(message, values) {
  const components = [];
  let previous = 0;
  let cursor = null;
  for (let i = 0; i < message.length; i += 1) {
    if (message[i] !== "%") continue;
    if (cursor === null) {
      cursor = i;
      continue;
    }

    const key = message.substring(cursor + 1, i);
    if (values[key] !== undefined) {
      components.push(message.substring(previous, cursor));
      components.push(values[key].length ? `*${values[key]}*` : "");
      previous = i + 1;
    }
    cursor = null;
  }
  components.push(message.substring(previous, message.length));
  return components.join("");
}
