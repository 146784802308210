import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import useInputRedux from "../../../../hooks/useInputRedux";
import ContactsSelector from "../../../../components/ContactSelector";
import Editor from "../../../../components/HighlightedEditor";

function QuoteRecipient({ id }) {
  const dispatch = useDispatch();
  const saving = useSelector((state) => state.quotes.editor.saving);
  const [title, setTitle] = useInputRedux(
    ["quotes", "editor", "details", "title"],
    (value) => ({
      type: "SET_QUOTE_DETAIL",
      payload: { field: "title", value, id },
    })
  );
  const [message, setMessage] = useInputRedux(
    ["quotes", "editor", "details", "message"],
    (value) => ({
      type: "SET_QUOTE_DETAIL",
      payload: { field: "message", value, id },
    })
  );

  const recipients = useSelector((state) => state.quotes.editor.recipients);
  const setRecipients = (recipients) =>
    dispatch({ type: "SET_QUOTE_RECIPIENTS", payload: recipients });

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <ContactsSelector
          disabled={saving}
          value={recipients}
          onValueChanged={setRecipients}
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          placeholder="Title"
          variant="outlined"
          disabled={saving}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </Grid>
      <Editor
        template="message"
        disabled={saving}
        message={message}
        setMessage={setMessage}
      />
    </Grid>
  );
}

export default QuoteRecipient;
