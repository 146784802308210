import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  appBar: {
    borderTopLeftRadius: theme.spacing(),
    borderTopRightRadius: theme.spacing(),
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  toolbarTitle: {
    flex: "1",
  },
  content: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  avatarContainer: {
    minWidth: `${theme.spacing(3)}px !important`,
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(),
  },
  grow: {
    flexGrow: 1,
  },
}));
