const update = require("immutability-helper");

const initialState = {
  lastFetch: 0,
  loading: true,
  error: null,
  contacts: [],
  searchKeyword: "",
  searchResult: [],
};

function contacts(state = initialState, action) {
  switch (action.type) {
    case "SEARCH_CONTACTS": {
      const { keyword } = action.payload;
      return update(state, {
        searchKeyword: {
          $set: keyword,
        },
        searchResult: {
          $set: state.contacts.filter((contact) => {
            const keywordLowered = keyword.toLowerCase();
            const emailLowered = contact.email.toLowerCase();
            const nameLowered = contact.name.toLowerCase();
            return (
              emailLowered.includes(keywordLowered) ||
              nameLowered.includes(keywordLowered)
            );
          }),
        },
      });
    }
    case "GET_CONTACTS":
      return update(state, {
        contacts: {
          $set: [],
        },
        loading: {
          $set: true,
        },
        error: {
          $set: null,
        },
      });
    case "GET_CONTACTS_SUCCESS":
      return update(state, {
        lastFetch: {
          $set: Date.now(),
        },
        loading: {
          $set: false,
        },
        contacts: {
          $set: action.payload,
        },
      });
    default:
      return state;
  }
}

export default contacts;
