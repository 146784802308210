import qs from "qs";
import axios from "axios";
import { EventSource } from "launchdarkly-eventsource";
import { useDispatch } from "react-redux";
import { takeEvery } from "redux-saga/effects";

const gateway = process.env.REACT_APP_LOCAL ? "http://localhost/fn" : "/fn";
let bearer = null;

async function fn(name, params, queries) {
  const extras = queries ? `?${qs.stringify(queries)}` : "";
  try {
    const { data } = await axios.post(
      `${gateway}/${name}${extras}`,
      params,
      bearer && name !== "login"
        ? {
            headers: {
              "X-Bearer": bearer,
            },
          }
        : null
    );
    return data;
  } catch (e) {
    if (e.response && e.response.data && e.response.data.error)
      throw new Error(e.response.data.error);
    throw e;
  }
}

async function fnUpload(
  name,
  params,
  onProgress = null,
  onDone = null,
  onError = null,
  queries
) {
  try {
    const extras = queries ? `?${qs.stringify(queries)}` : "";
    const fd = new FormData();
    for (let param of Object.keys(params)) fd.append(param, params[param]);

    const { data } = await axios.post(`${gateway}/${name}${extras}`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        "X-Bearer": bearer,
      },
      onUploadProgress: (e) =>
        onProgress && onProgress((100 * e.loaded) / e.total),
    });
    if (onDone) onDone(data);
    return data;
  } catch (e) {
    if (onError && e.response && e.response.data && e.response.data.error)
      return onError(new Error(e.response.data.error));
    if (onError) return onError(e);

    if (e.response && e.response.data && e.response.data.error)
      throw new Error(e.response.data.error);
    throw e;
  }
}

async function fnStream(name, params) {
  try {
    console.log("begin!");
    const es = new EventSource(`${gateway}/${name}`, {
      errorFilter: () => false,
      method: "POST",
      body: params,
      headers:
        bearer && name !== "login"
          ? {
              "X-Bearer": bearer,
            }
          : null,
    });

    es.addEventListener("message", (e) => console.log("received data", e));
    es.addEventListener("open", (e) => console.log("ES Open"));
    es.addEventListener("error", (e) => {
      console.log("received error", e);
      es.close();
    });
    es.addEventListener("end", (e) => console.log("ES end"));
    es.addEventListener("closed", (e) => console.log("ES Closed"));
  } catch (e) {
    if (e.response && e.response.data && e.response.data.error)
      throw new Error(e.response.data.error);
    throw e;
  }
}

function setBearer(token) {
  bearer = token;
}

function fnDownloadURL(name, params) {
  const extras = params ? `&${qs.stringify(params)}` : "";
  return `${gateway}/${name}?dl=t&auth=${bearer}${extras}`;
}

function fnDownloadWithKey(name, key) {
  return `${gateway}/${name}?dl=t&key=${key}`;
}

function* handleFn({ payload }) {
  const { name, params } = payload;
  const res = yield fn(name, params);
  return res;
}

function* fnSagas() {
  yield takeEvery("EXECUTE_FN", handleFn);
}

function* handleAuthExpired(e) {
  if (e.message !== "Invalid Token: Expired token") return false;
  console.log("action login");
  return true;
}

// function useFn(fn, params) {
//   const dispatch = useDispatch();
//   const [loading, setLoading] = useState(true);
//   const [data, setData] = useState(null);
//   const [error, setError] = useState(null);
//   useEffect(() => {
//     function handleStatusChange(status) {
//       setIsOnline(status.isOnline);
//     }
//     ChatAPI.subscribeToFriendStatus(props.friend.id, handleStatusChange);
//     return () => {
//       ChatAPI.unsubscribeFromFriendStatus(props.friend.id, handleStatusChange);
//     };
//   });
//   return [loading, data, error];
// }

export {
  fn,
  fnUpload,
  fnDownloadURL,
  fnDownloadWithKey,
  fnSagas,
  setBearer,
  handleAuthExpired,
};
