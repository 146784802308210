import React from "react";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

import useStyle from "./style";

function LoadingDialog() {
  const loading = useSelector((state) => state.dialog.loading);
  const classes = useStyle();

  return (
    <Dialog open={!!loading} classes={classes} fullWidth maxWidth="sm">
      <DialogTitle>Loading</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item>
            <CircularProgress />
          </Grid>
          <Grid item className={classes.loadingText}>
            <DialogContentText>{loading}</DialogContentText>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default LoadingDialog;
