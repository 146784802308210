import React, { useRef, useState } from "react";
import { debounce } from "underscore";
import { useDispatch } from "react-redux";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";

import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";

import useStyles from "./style";

function QuoteToolbar() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = useRef(
    debounce(function _handleSearch(dispatch, keyword) {
      dispatch({ type: "SEARCH_QUOTES", payload: { keyword } });
    }, 500)
  );

  return (
    <AppBar position="relative" color="primary" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              handleSearch.current(dispatch, e.target.value);
            }}
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
          />
        </div>
        <div className={classes.grow} />
        <IconButton
          color="inherit"
          onClick={() => dispatch({ type: "NEW_QUOTE" })}
        >
          <AddIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

export default QuoteToolbar;
