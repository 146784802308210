import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import UserList from "./UserList";
import UserEditor from "./UserEditor";

import { LayoutSwitcher } from "../../components/Layout";

function Users() {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) dispatch({ type: "GET_USER", payload: { id } });
  }, [id]);
  return (
    <LayoutSwitcher
      sidebar={<UserList id={id} />}
      main={<UserEditor id={id} />}
      isActive={!!id}
    />
  );
}

export default Users;
