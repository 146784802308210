import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import renderString from "../../../../lib/string-renderer";

import Preview from "./steps/Preview";
import Sending from "./steps/Sending";
import useStyle from "./styles";

function SendDialog({ id }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const loadingCompany = useSelector((state) => state.settings.company.loading);
  const recipients = useSelector((state) => state.quotes.editor.recipients);
  const show = useSelector((state) => state.quotes.editor.showSend);
  const sending = useSelector((state) => state.quotes.editor.sending);
  const error = useSelector((state) => state.quotes.editor.sendError);

  const auth = useSelector((state) => state.auth);
  const company = useSelector((state) => state.settings.company);
  const paymentDetails = useSelector(
    (state) =>
      (state.quotes.editor.details &&
        state.quotes.editor.details.paymentDetails) ||
      ""
  );
  const deliveryDetails = useSelector(
    (state) =>
      (state.quotes.editor.details &&
        state.quotes.editor.details.deliveryDetails) ||
      ""
  );
  const changed = useSelector((state) => state.quotes.editor.changed);
  const title = useSelector(
    (state) =>
      (state.quotes.editor.details && state.quotes.editor.details.title) || ""
  );
  const message = useSelector(
    (state) =>
      (state.quotes.editor.details && state.quotes.editor.details.message) || ""
  );
  const [previewRecepient, setPreviewRecepient] = useState(0);
  const [
    previewEmail,
    previewMessage,
    previewPayment,
    previewDelivery,
  ] = useMemo(() => {
    if (!recipients.length || loadingCompany) return ["", "", "", ""];
    const recipient = recipients[previewRecepient];
    if (!recipient) return ["", "", "", ""];
    const variables = {
      NAME: recipient.name || "",
      COMPANY: recipient.company_name || "",
      ADDRESS: recipient.company_address || "",
      MY_COMPANY: company.name || "",
      MY_COMPANY_ADDRESS: recipient.address || "",
      MY_COMPANY_PHONE: recipient.phone || "",
      MY_NAME: auth.userName || "",
      MY_EMAIL: auth.userEmail || "",
    };
    return [
      recipient.email || "",
      renderString(message, variables).replace(/\n/g, "\\\n"),
      renderString(paymentDetails, variables).replace(/\n/g, "\\\n"),
      renderString(deliveryDetails, variables).replace(/\n/g, "\\\n"),
    ];
  }, [
    loadingCompany,
    company,
    message,
    paymentDetails,
    deliveryDetails,
    recipients,
    previewRecepient,
  ]);

  useEffect(() => {
    if (show) {
      setStep(1);
      setPreviewRecepient(0);
      dispatch({ type: "GET_SETTING_COMPANY" });
    }
  }, [show]);

  const loading = loadingCompany;
  const handleClose = () => {
    if (sending) return;
    if (step === 3) {
      dispatch({ type: "SEND_QUOTE_DONE", payload: { id } });
      return;
    }
    dispatch({ type: "CANCEL_SEND" });
  };
  const handlePrev = () => {
    if (step === 1 && previewRecepient > 0) {
      setPreviewRecepient(previewRecepient - 1);
    }
  };
  const handleNext = () => {
    if (step === 1 && previewRecepient < recipients.length - 1) {
      setPreviewRecepient(previewRecepient + 1);
      return;
    }
    if (step === 2) {
      dispatch({ type: "SEND_QUOTE" });
    }
    setStep(step + 1);
  };

  return (
    <Dialog
      onClose={handleClose}
      fullWidth
      scroll="body"
      maxWidth="sm"
      classes={classes}
      open={show}
    >
      <DialogTitle disableTypography onClose={handleClose}>
        <Typography variant="h6">Review &amp; Send</Typography>
        {!sending && (
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent dividers>
        {error && <Alert severity="error">{error}</Alert>}
        {recipients.length === 0 &&
          "Please select at least one recipient to start sending the quotation."}
        {loading && !!recipients.length && (
          <>
            <CircularProgress size={16} /> Processing ...
          </>
        )}
        {!loading && !!recipients.length && step === 1 && (
          <Preview
            idx={previewRecepient}
            total={recipients.length}
            email={previewEmail}
            message={previewMessage}
            payment={previewPayment}
            delivery={previewDelivery}
          />
        )}
        {step === 2 && (
          <>
            <Typography gutterBottom>
              After sending this quotation, it will be finalised and you will
              not be able to modify it. Do you want to proceed?
            </Typography>
            {changed && (
              <Typography gutterBottom>
                <br />
                <strong>Note:</strong> You have unsaved changes. Your changes
                will be automatically saved before we send your quotation.
              </Typography>
            )}
          </>
        )}
        {step === 3 && <Sending />}
      </DialogContent>
      <DialogActions>
        {step < 3 && recipients.length !== 0 && (
          <>
            <Button
              onClick={handlePrev}
              disabled={sending || (step === 1 && previewRecepient === 0)}
            >
              Previous
            </Button>
            <Button onClick={handleNext} disabled={sending} color="primary">
              {step === 2 ? "Confirm and Send" : "Next"}
            </Button>
          </>
        )}
        {(step === 3 || recipients.length === 0) && (
          <Button disabled={sending} onClick={handleClose}>
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default SendDialog;
