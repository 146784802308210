import React, { useState, useEffect } from "react";
import update from "immutability-helper";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import { fn } from "../../lib/fn";

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const filter = createFilterOptions();

function ContactsSelector({ disabled, value, onValueChanged }) {
  const loading = useSelector((state) => state.contacts.list.loading);
  const lastFetch = useSelector((state) => state.contacts.list.lastFetch);
  const contacts = useSelector((state) => state.contacts.list.contacts);
  const [loadingDetails, setloadingDetails] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Date.now() - lastFetch > 5 * 60 * 1000)
      dispatch({ type: "GET_CONTACTS" });
  }, []);

  return (
    <Autocomplete
      multiple
      value={value}
      disabled={loadingDetails || disabled}
      loading={loading || loadingDetails}
      options={contacts}
      onChange={async (_, value, reason) => {
        if (!value.length || reason !== "select-option")
          return onValueChanged(value);

        const newValue = value[value.length - 1];
        if (!emailRegex.test(newValue.email)) {
          alert(`"${newValue.email}" is not a valid email address.`);
          return;
        }
        if (newValue.id) {
          setloadingDetails(true);
          const details = await fn("get-contact", { id: newValue.id });
          onValueChanged(
            value
              .splice(0, value.length - 1)
              .concat([{ ...newValue, ...details }])
          );
          setloadingDetails(false);
          return;
        }

        onValueChanged(value);
      }}
      getOptionSelected={(option, val) => option.email === val.email}
      getOptionLabel={(option) =>
        option.name && option.email
          ? `${option.name} (${option.email})`
          : option.email
      }
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== "") {
          filtered.push({
            id: null,
            email: params.inputValue,
          });
        }

        return filtered;
      }}
      renderOption={(option) =>
        option.id
          ? `${option.name} (${option.email})`
          : `Send to "${option.email}"`
      }
      renderInput={(params) => (
        <TextField
          variant="outlined"
          placeholder="Recipients"
          {...update(params, {
            InputProps: {
              endAdornment: (val) =>
                loadingDetails ? (
                  <InputAdornment>
                    <CircularProgress size={24} />
                  </InputAdornment>
                ) : (
                  val
                ),
            },
          })}
        />
      )}
    />
  );
}

export default ContactsSelector;
