import Promise from "bluebird";
import { push } from "connected-react-router";
import { put, takeEvery, select } from "redux-saga/effects";
import { fn } from "../../../lib/fn";

function* getContact({ payload }) {
  const { id } = payload;
  if (id === "new") {
    yield put({
      type: "GET_CONTACT_SUCCESS",
      payload: {
        id: "new",
        email: "",
        name: "",
        phone: null,
        company_name: null,
        company_address: null,
      },
    });
    return;
  }

  const details = yield fn("get-contact", { id });
  if (!details) {
    yield put(push("/contacts"));
    return;
  }
  yield put({ type: "GET_CONTACT_SUCCESS", payload: details });
}

function* newContact() {
  try {
    // give time to the debounced redux updater
    yield Promise.delay(1500);
    const editor = yield select((state) => state.contacts.editor);
    const { success, id } = yield fn("new-contact", {
      email: editor.email,
      name: editor.name,
      phone: editor.phone || null,
      company_name: editor.companyName || null,
      company_address: editor.companyAddress || null,
    });
    if (!success)
      throw new Error("Fail to update the database. Please try again later.");

    yield put({
      type: "GET_CONTACTS",
    });
    yield put({
      type: "NEW_CONTACT_SUCCESS",
      payload: { id: "new" },
    });
    yield put(push(`/contact/${id}`));
  } catch (e) {
    yield put({
      type: "NEW_CONTACT_ERROR",
      payload: e.message || "Unknown Error",
    });
  }
}

function* updateContact() {
  try {
    // give time to the debounced redux updater
    yield Promise.delay(1500);
    const editor = yield select((state) => state.contacts.editor);
    const { success } = yield fn("update-contact", {
      id: editor.activeContact,
      email: editor.email,
      name: editor.name,
      phone: editor.phone || null,
      company_name: editor.companyName || null,
      company_address: editor.companyAddress || null,
    });
    if (!success)
      throw new Error("Fail to update the database. Please try again later.");

    yield put({
      type: "GET_CONTACTS",
    });
    yield put({
      type: "UPDATE_CONTACT_SUCCESS",
      payload: { id: editor.activeContact },
    });
  } catch (e) {
    yield put({
      type: "UPDATE_CONTACT_ERROR",
      payload: e.message || "Unknown Error",
    });
  }
}

function* handleDeleteContact({ payload }) {
  const { id, name } = payload;
  yield put({
    type: "SHOW_LOADING",
    payload: { message: `Deleting ${name} ...` },
  });

  try {
    const { success } = yield fn("delete-contact", { id });
    if (!success)
      throw new Error("Fail to update the database. Please try again later.");

    yield put({
      type: "GET_CONTACTS",
    });
    yield put(push("/contacts"));
  } catch (e) {
    yield put({
      type: "DELETE_CONTACT_ERROR",
      payload: e.message || "Unknown Error",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* contactEditorSaga() {
  yield takeEvery("GET_CONTACT", getContact);
  yield takeEvery("NEW_CONTACT", newContact);
  yield takeEvery("UPDATE_CONTACT", updateContact);
  yield takeEvery("DELETE_CONTACT", handleDeleteContact);
}

export { contactEditorSaga };
