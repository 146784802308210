import React from "react";

import UserProfile from "./UserProfile";
import CompanyProfile from "./CompanyProfile";
import Templates from "./Templates";
import Currency from "./Currency";

import Empty from "./Empty";

function SettingEditor({ id }) {
  switch (id) {
    case "profile":
      return <UserProfile />;
    case "company":
      return <CompanyProfile />;
    case "currency":
      return <Currency />;
    case "message":
    case "delivery":
    case "payment":
      return <Templates />;
  }
  return <Empty />;
}

export default SettingEditor;
