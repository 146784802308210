const update = require("immutability-helper");

const initialState = {
  error: null,
  sessionExpired: false,
  flashMessage: null,
  loading: false,
  loggedIn: false,
  userId: null,
  userName: null,
  userEmail: null,
  companyId: null,
  companyName: null,
  companyType: null,
  tokenExpire: null,
  level: null,
};

function auth(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case "LOGIN":
      return update(state, {
        loading: {
          $set: true,
        },
        error: {
          $set: null,
        },
      });
    case "LOGIN_SUCCESS":
      return update(state, {
        flashMessage: {
          $set: payload,
        },
      });
    case "LOGIN_ERROR":
      return update(state, {
        loading: {
          $set: false,
        },
        error: {
          $set: payload,
        },
      });
    case "SESSION_EXPIRED": {
      return update(state, {
        error: {
          $set: null,
        },
        flashMessage: {
          $set: null,
        },
        sessionExpired: {
          $set: true,
        },
      });
    }
    case "AUTHENTICATE": {
      return update(state, {
        loading: {
          $set: false,
        },
        loggedIn: {
          $set: true,
        },
        userId: {
          $set: payload.id,
        },
        userName: {
          $set: payload.name,
        },
        userEmail: {
          $set: payload.email,
        },
        companyId: {
          $set: payload.company.id,
        },
        companyName: {
          $set: payload.company.name,
        },
        companyType: {
          $set: payload.company.type,
        },
        level: {
          $set: payload.level,
        },
        tokenExpire: {
          $set: payload.token_expire,
        },
        sessionExpired: {
          $set: false,
        },
      });
    }
    default:
      return state;
  }
}

export default auth;
