const update = require("immutability-helper");

const initialState = {
  error: null,
  loading: true,
  lastFetch: 0,
  skus: [],
  searchKeyword: "",
  searchResult: [],
};

function skus(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case "SEARCH_SKUS": {
      const { keyword } = action.payload;
      return update(state, {
        searchKeyword: {
          $set: keyword,
        },
        searchResult: {
          $set: state.skus.filter((sku) => {
            const keywordLowered = keyword.toLowerCase();
            const skuLowered = sku.sku.toLowerCase();
            const nameLowered = sku.name.toLowerCase();
            return (
              skuLowered.includes(keywordLowered) ||
              nameLowered.includes(keywordLowered)
            );
          }),
        },
      });
    }
    case "GET_SKUS":
      return update(initialState, {
        loading: {
          $set: true,
        },
      });
    case "GET_SKUS_SUCCESS": {
      return update(state, {
        lastFetch: {
          $set: Date.now(),
        },
        loading: {
          $set: false,
        },
        skus: {
          $set: payload.sort((a, b) =>
            a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
          ),
        },
      });
    }
    case "UPDATE_PRODUCT_SUCCESS": {
      return update(state, {
        lastFetch: {
          $set: 0,
        },
      });
    }
    case "GET_SKUS_ERROR":
      return update(state, {
        loading: {
          $set: false,
        },
        error: {
          $set: payload,
        },
      });
    default:
      return state;
  }
}

export default skus;
