import React, { useEffect } from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import Markdown from "react-markdown";

import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";

import DownloadIcon from "@material-ui/icons/GetApp";
import CameraIcon from "@material-ui/icons/CameraAlt";

import useQuery from "../../hooks/useQuery";
import Copyright from "../../components/Copyright";
import ContentLoading from "../../components/ContentLoading";
import useStyles from "./styles";

import "./style.css";

function Viewer() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const query = useQuery();

  const viewKey = query.get("key");
  const loading = useSelector((state) => state.viewer.loading);

  const id = useSelector((state) => state.viewer.id);
  const title = useSelector((state) => state.viewer.title);
  const message = useSelector((state) => state.viewer.message);
  const paymentDetails = useSelector((state) => state.viewer.paymentDetails);
  const deliveryDetails = useSelector((state) => state.viewer.deliveryDetails);
  const companyLogo = useSelector((state) => state.viewer.companyLogo);
  const companyName = useSelector((state) => state.viewer.companyName);
  const subtotal = useSelector((state) => state.viewer.subtotal);
  const taxRate = useSelector((state) => state.viewer.taxRate);
  const discount = useSelector((state) => state.viewer.discount);
  const currency = useSelector((state) => state.viewer.currency);
  const date = useSelector((state) => state.viewer.date);
  const tax = useSelector((state) => state.viewer.tax);
  const total = useSelector((state) => state.viewer.total);
  const items = useSelector((state) => state.viewer.items);

  useEffect(() => {
    dispatch({ type: "GET_QUOTE_FOR_VIEWER", payload: { viewKey } });
  }, [viewKey]);

  if (loading) return <ContentLoading />;

  return (
    <Grid container justify="center" className="print">
      <Grid container item xs={12} sm={10} spacing={2}>
        <div className={classes.logo}>
          {companyLogo ? <img src={companyLogo} /> : <h1>{companyName}</h1>}
        </div>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <AppBar
              component="div"
              className={classes.appBar}
              color="paper"
              position="static"
              elevation={0}
            >
              <Toolbar className={classes.toolbar}>
                <Typography
                  variant="h5"
                  component="div"
                  className={classes.toolbarTitle}
                >
                  #{id} - {title}
                </Typography>
                <Typography variant="body" component="div">
                  <strong>{date}</strong>
                </Typography>
              </Toolbar>
            </AppBar>
            <Divider />
            <Grid container className={classes.content} spacing={2}>
              <Grid item xs={12}>
                <Markdown>{message}</Markdown>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <TableContainer>
              <Table className={classes.table} aria-label="spanning table">
                <TableHead>
                  <TableRow>
                    <TableCell width="55%">Products</TableCell>
                    <TableCell width="15%" align="right">
                      Qty.
                    </TableCell>
                    <TableCell width="15%" align="right">
                      Unit
                    </TableCell>
                    <TableCell width="15%" align="right">
                      Sum
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items &&
                    items.map((item) =>
                      item.sku ? (
                        <TableRow key={item.ref}>
                          <TableCell width="55%">
                            <Grid container spacing={1} alignItems="center">
                              {item.image ? (
                                <Grid item>
                                  <Avatar
                                    variant="square"
                                    className={classes.image}
                                    src={item.image}
                                  />
                                </Grid>
                              ) : (
                                <Box
                                  display={{ xs: "none", md: "block" }}
                                  className="no-print"
                                >
                                  <Grid item>
                                    <Avatar
                                      variant="square"
                                      className={classes.image}
                                    >
                                      <CameraIcon />
                                    </Avatar>
                                  </Grid>
                                </Box>
                              )}
                              <Grid item xs>
                                <Typography variant="overline" display="block">
                                  {item.sku}
                                </Typography>
                                <Typography variant="body1" display="block">
                                  {item.name}
                                </Typography>
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell width="15%" align="right">
                            {item.qty.toLocaleString()} {item.unitUnit}
                          </TableCell>
                          <TableCell width="15%" align="right">
                            {currency} {item.unit.toLocaleString()}
                          </TableCell>
                          <TableCell width="10%" align="right">
                            {currency} {(item.qty * item.unit).toLocaleString()}
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow key={item.ref}>
                          <TableCell
                            width="100%"
                            className={classes.separator}
                            colSpan={4}
                          >
                            <em>{item.txt}</em>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                </TableBody>
              </Table>
              <Table className={classes.table} aria-label="spanning table">
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={1} rowSpan={4} width="50%" />
                    <TableCell colSpan={2}>Subtotal</TableCell>
                    <TableCell align="right">
                      {currency} {subtotal.toLocaleString()}
                    </TableCell>
                  </TableRow>
                  {parseFloat(discount) !== 0 && (
                    <TableRow>
                      <TableCell colspan={2}>Discount</TableCell>
                      <TableCell align="right">
                        {currency} {parseFloat(discount).toLocaleString()}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell>Tax</TableCell>
                    <TableCell align="right">{taxRate}%</TableCell>
                    <TableCell align="right">
                      {currency} {tax.toLocaleString()}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>Total</TableCell>
                    <TableCell align="right">
                      {currency} {total.toLocaleString()}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        <Grid container item direction="column" xs={6}>
          <Paper className={classnames(classes.paper, classes.grow)}>
            <AppBar
              component="div"
              className={classes.appBar}
              color="paper"
              position="static"
              elevation={0}
            >
              <Toolbar className={classes.toolbar}>
                <strong>Payment Details</strong>
              </Toolbar>
            </AppBar>
            <Divider />
            <Grid container className={classes.content} spacing={2}>
              <Grid item xs={12}>
                <Markdown>{paymentDetails}</Markdown>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid container item direction="column" xs={6}>
          <Paper className={classnames(classes.paper, classes.grow)}>
            <AppBar
              component="div"
              className={classes.appBar}
              color="paper"
              position="static"
              elevation={0}
            >
              <Toolbar className={classes.toolbar}>
                <strong>Delivery Details</strong>
              </Toolbar>
            </AppBar>
            <Divider />
            <Grid container className={classes.content} spacing={2}>
              <Grid item xs={12}>
                <Markdown>{deliveryDetails}</Markdown>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Copyright />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Viewer;
