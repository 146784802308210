import React, { useEffect } from "react";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import SettingList from "./SettingList";
import SettingEditor from "./SettingEditor";

import { LayoutSwitcher } from "../../components/Layout";

const settings = {
  profile: "PROFILE",
  company: "COMPANY",
  currency: "CURRENCY",
  message: "TEMPLATE_MESSAGE",
  delivery: "TEMPLATE_DELIVERY",
  payment: "TEMPLATE_PAYMENT",
};

function Settings() {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      const name = settings[id];
      if (!name) {
        dispatch(push("/settings"));
        return;
      }
      dispatch({ type: `GET_SETTING_${name}` });
    }
  }, [id]);
  return (
    <LayoutSwitcher
      sidebar={<SettingList id={id} />}
      main={<SettingEditor id={id} />}
      isActive={!!id}
    />
  );
}

export default Settings;
