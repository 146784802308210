import React, { useEffect, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useIsVisible } from "react-is-visible";

function GenericSkeleton({ onVisible, n }) {
  const ref = useRef();
  const isVisible = useIsVisible(ref);
  useEffect(() => {
    if (isVisible && onVisible) onVisible();
  }, [isVisible]);

  const numItem = n || 3;
  return (
    <div ref={ref}>
      {Array(numItem)
        .fill()
        .map((_, i) => (
          <ListItem key={i}>
            <ListItemText
              primary={
                <Typography variant="subtitle2">
                  <Skeleton width={70} />
                </Typography>
              }
              secondary={
                <Typography variant="caption">
                  <Skeleton width={220} />
                </Typography>
              }
            />
          </ListItem>
        ))}
    </div>
  );
}

export default GenericSkeleton;
