import React from "react";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";

import GetAppIcon from "@material-ui/icons/GetApp";

function Step4({
  error,
  processing,
  uploadPercentage,
  importResult,
  itemUnits,
}) {
  return (
    <Grid container direction="column" spacing={2}>
      {!processing && error && (
        <Grid item xs>
          <Alert severity="error">{error}</Alert>
        </Grid>
      )}
      {!processing && !error && (
        <Grid item xs>
          <Alert severity="success">
            Import completed with <strong>{importResult.inserted} new</strong>{" "}
            {itemUnits}, <strong>{importResult.updated} updated</strong>{" "}
            {itemUnits}, and <strong>{importResult.deleted} deleted</strong>{" "}
            {itemUnits}.
          </Alert>
        </Grid>
      )}
      {processing && (
        <Grid item xs>
          <Typography gutterBottom>
            {uploadPercentage < 100 &&
              `(${uploadPercentage.toFixed(
                2
              )}%) Uploading file ... Please don't close this window.`}
            {uploadPercentage === 100 &&
              "Processing file ... Please don't close this window."}
          </Typography>
        </Grid>
      )}
      {processing && (
        <Grid item xs>
          <LinearProgress
            variant={uploadPercentage < 100 ? "determinate" : "indeterminate"}
            value={uploadPercentage}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default Step4;
