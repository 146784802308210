function createInitialState(additional = {}) {
  return {
    error: null,
    show: false,
    step: 1,
    importResult: null,
    importType: "append",
    processing: false,
    uploadProgress: 0,
    ...additional,
  };
}

export default createInitialState;
