import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import UpdateIcon from "@material-ui/icons/Update";
import SyncIcon from "@material-ui/icons/Sync";

import useStyles from "./style-step2";

function Step2({ importType, itemUnits, onImportTypeSelected }) {
  const classes = useStyles();
  return (
    <Grid direction="column" container spacing={1}>
      <Grid xs item container alignItems="center">
        <Typography gutterBottom>Select the import behaviour:</Typography>
      </Grid>
      <Grid xs item container alignItems="center">
        <Grid xs={2} item>
          <Button
            color={importType === "append" ? "primary" : "default"}
            variant={importType === "append" ? "outlined" : "text"}
            className={classes.button}
            onClick={() => onImportTypeSelected("append")}
          >
            <PlaylistAddIcon />
          </Button>
        </Grid>
        <Grid xs item>
          <Typography variant="caption">
            <strong>Append Only</strong>: Will only add new {itemUnits} from the
            Excel file that don't exist in the system yet.
          </Typography>
        </Grid>
      </Grid>
      <Grid xs item container alignItems="center">
        <Grid xs={2} item>
          <Button
            color={importType === "update" ? "primary" : "default"}
            variant={importType === "update" ? "outlined" : "text"}
            className={classes.button}
            onClick={() => onImportTypeSelected("update")}
          >
            <UpdateIcon />
          </Button>
        </Grid>
        <Grid xs item>
          <Typography variant="caption">
            <strong>Update</strong>: Will add new {itemUnits} and update
            existing {itemUnits} in the system if they are different from the
            Excel file.
          </Typography>
        </Grid>
      </Grid>
      <Grid xs item container alignItems="center">
        <Grid xs={2} item>
          <Button
            color={importType === "sync" ? "primary" : "default"}
            variant={importType === "sync" ? "outlined" : "text"}
            className={classes.button}
            onClick={() => onImportTypeSelected("sync")}
          >
            <SyncIcon />
          </Button>
        </Grid>
        <Grid xs item>
          <Typography variant="caption">
            <strong>Sync</strong>: Will add new {itemUnits}, update the existing{" "}
            {itemUnits}, and <font color="red">remove {itemUnits}</font> from
            the system that don't exist in the Excel file.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Step2;
