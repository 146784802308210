import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import GetAppIcon from "@material-ui/icons/GetApp";

function Step1({ name, templateURL }) {
  return (
    <>
      <Typography gutterBottom>
        To import data from an Excel file, you have to provide the data in a
        specific format so that we can read it. If you have an existing Excel
        file, you can easily copy-paste your data into the Excel template we
        provide below.
      </Typography>
      <Button color="primary" href={templateURL} startIcon={<GetAppIcon />}>
        {name} Template File
      </Button>
    </>
  );
}

export default Step1;
