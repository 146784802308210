import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  logo: {
    width: "100%",
    textAlign: "center",
    "& > img": {
      maxWidth: "100%",
      height: theme.spacing(12),
      marginBottom: theme.spacing(2),
      borderRadius: theme.spacing(),
      textAlign: "center",
    },
  },
  appBar: {
    borderTopLeftRadius: theme.spacing(),
    borderTopRightRadius: theme.spacing(),
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  main: {
    flexGrow: 1,
    width: "100%",
    padding: theme.spacing(4),
    background: "#eaeff1",
  },
  spacer: {
    width: "100%",
    height: theme.spacing(1),
  },
  paper: {
    marginBottom: theme.spacing(4),
  },
  tableActions: {
    verticalAlign: "top",
  },
  content: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  actions: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
}));
