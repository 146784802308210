import React, { useEffect, forwardRef } from "react";
import { push } from "connected-react-router";
import { useSelector, useDispatch } from "react-redux";

import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";

import List from "../../../components/GenericList";
import ListItem from "../../../components/GenericList/Item";

import useStyle from "./style";

const innerElementType = forwardRef(({ style, ...rest }, ref) => (
  <div
    ref={ref}
    style={{
      ...style,
      height: `${parseFloat(style.height) + 70}px`,
    }}
    {...rest}
  />
));

function ProductList({ id }) {
  const dispatch = useDispatch();
  const classes = useStyle();
  const loading = useSelector((state) => state.skus.loading);
  const skus = useSelector((state) => state.skus.skus);
  const searchKeyword = useSelector((state) => state.skus.searchKeyword);
  const searchResult = useSelector((state) => state.skus.searchResult);
  const items = searchKeyword.length ? searchResult : skus;

  useEffect(() => {
    dispatch({ type: "GET_SKUS" });
  }, []);

  function Row({ index, style }) {
    const item = items[index];
    return (
      <ListItem
        style={style}
        id={item.sku}
        title={item.name}
        subtitle={item.sku}
        selected={item.sku === id}
        page="product"
      />
    );
  }

  return (
    <List
      manual
      onNewClicked={() => dispatch(push("/product/new"))}
      onImportClicked={() => dispatch({ type: "SHOW_IMPORT_PRODUCTS" })}
      onSearch={(keyword) =>
        dispatch({ type: "SEARCH_SKUS", payload: { keyword } })
      }
      emptyText={
        searchKeyword.length
          ? "No product matching your search criteria found."
          : "To create a new product, use the the + button below."
      }
      loading={loading}
    >
      <AutoSizer>
        {({ height, width }) => (
          <FixedSizeList
            className={classes.scroll}
            innerElementType={innerElementType}
            height={height}
            width={width}
            itemCount={items.length}
            itemSize={70}
          >
            {Row}
          </FixedSizeList>
        )}
      </AutoSizer>
      {/* {items.map((item) => (
        <ListItem
          key={item.sku}
          id={item.sku}
          title={item.name}
          subtitle={item.sku}
          selected={item.sku === id}
          page="product"
        />
      ))} */}
    </List>
  );
}

export default ProductList;
