import React, { useEffect } from "react";
import { push } from "connected-react-router";
import { useSelector, useDispatch } from "react-redux";

import List from "../../../components/GenericList";
import ListItem from "../../../components/GenericList/Item";

function UserList({ id }) {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.users.list.loading);
  const users = useSelector((state) => state.users.list.users);
  const searchKeyword = useSelector((state) => state.users.list.searchKeyword);
  const searchResult = useSelector((state) => state.users.list.searchResult);
  const items = searchKeyword.length ? searchResult : users;

  useEffect(() => {
    dispatch({ type: "GET_USERS" });
  }, []);
  return (
    <List
      onNewClicked={() => dispatch(push("/user/new"))}
      onSearch={(keyword) =>
        dispatch({ type: "SEARCH_USERS", payload: { keyword } })
      }
      emptyText={
        searchKeyword.length
          ? "No user matching your search criteria found."
          : "To create a new user, use the the + button below."
      }
      loading={loading}
    >
      {items.map((item) => (
        <ListItem
          key={item.id}
          id={item.id}
          title={item.name}
          subtitle={item.email}
          selected={item.id === id}
          page="user"
        />
      ))}
    </List>
  );
}

export default UserList;
