const update = require("immutability-helper");

const initialState = {
  fatalError: null,
  loading: null,
};

function dialog(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case "FATAL_ERROR": {
      const { message } = payload;
      return update(initialState, {
        fatalError: {
          $set: message,
        },
      });
    }
    case "SHOW_LOADING": {
      const { message } = payload;
      return update(initialState, {
        loading: {
          $set: message,
        },
      });
    }
    case "HIDE_LOADING": {
      return update(initialState, {
        loading: {
          $set: null,
        },
      });
    }
    default:
      return state;
  }
}

export default dialog;
