import { push } from "connected-react-router";
import { eventChannel } from "redux-saga";
import { takeEvery, take, put, select } from "redux-saga/effects";
import { fnUpload } from "../../../lib/fn";

function* handleImportProduct({ payload }) {
  const chan = eventChannel((emit) => {
    fnUpload(
      "import-products",
      payload,
      (progress) => emit({ type: "PROGRESS", progress }),
      (data) => emit({ type: "DONE", data }),
      (error) => emit({ type: "ERROR", error })
    );
    return () => {};
  });
  try {
    while (true) {
      const event = yield take(chan);
      if (event.type === "PROGRESS") {
        yield put({ type: "IMPORT_PRODUCT_PROGRESS", payload: event.progress });
      } else if (event.type === "DONE") {
        const { success, inserted, updated, deleted } = event.data;

        if (!success) {
          yield put({
            type: "IMPORT_PRODUCT_ERROR",
            payload:
              "Fail to import products. Please try again later. If this error doesn't disappear, please contact support.",
          });
          return;
        }

        yield put({
          type: "GET_SKUS",
        });
        yield put(push("/products"));
        yield put({
          type: "IMPORT_PRODUCT_SUCCESS",
          payload: { inserted, updated, deleted },
        });
        return;
      } else {
        throw event.error;
      }
    }
  } catch (e) {
    yield put({
      type: "IMPORT_PRODUCT_ERROR",
      payload: e.message || "Unknown Error",
    });
  } finally {
    chan.close();
  }
}

function* productsImporterSaga() {
  yield takeEvery("IMPORT_PRODUCT", handleImportProduct);
}

export { productsImporterSaga };
