import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import useStyles from "./styles";

const LayoutContext = React.createContext({ desktop: false, mobile: true });

function LayoutSwitcher({ sidebar, main, isActive }) {
  const { desktop } = useContext(LayoutContext);
  const classes = useStyles();

  if (desktop)
    return (
      <div className={classes.root}>
        <div className={classes.left}>{sidebar}</div>
        <div className={classes.right}>{main}</div>
      </div>
    );

  if (isActive) return <div className={classes.root}>{main}</div>;
  return <div className={classes.root}>{sidebar}</div>;
}

function LayoutProvider({ children }) {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <LayoutContext.Provider value={{ desktop, mobile }}>
      {children}
    </LayoutContext.Provider>
  );
}

function useLayout() {
  return useContext(LayoutContext);
}

export { LayoutSwitcher, LayoutProvider, useLayout };
